import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

//This are the ranges shown at th bottom of the range component
export const predefinedBottomRanges = [
    // {
    //   label: 'Today',
    //   value: [new Date(), new Date()]
    // },
    // {
    //   label: 'Yesterday',
    //   value: [addDays(new Date(), -1), addDays(new Date(), -1)]
    // },
    // {
    //   label: 'This week',
    //   value: [startOfWeek(new Date()), endOfWeek(new Date())]
    // },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()]
    },
    {
      label: 'Last 60 days',
      value: [subDays(new Date(), 59), new Date()]
    },
    // {
    //   label: 'This month',
    //   value: [startOfMonth(new Date()), new Date()]
    // },
    // {
    //   label: 'Last month',
    //   value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    // },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
    },
    // {
    //   label: 'Last year',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)]
    // },
    // {
    //   label: 'All time',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
    // }
  ];

//This are the types of subcribers/organization we onboard
export const organizationTypes = [
    {
        id: 1,
        label: "Crossroads",
        value: "crossroads"
    },
    {
        id: 2,
        label: "Church",
        value: "church"
    },
    {
        id: 3,
        label: "Charity",
        value: "charity",
    },
    {
        id: 4,
        label: "Education",
        value: "education",
    },
    {
        id: 5,
        label: "Business",
        value: "business",
    },

]
export const organizationDenominations = [
    {
        id: 1,
        label: "Pentecostal Assoc. of Cda",
        value: "PAOC"
    },
    {
        id: 2,
        label: "Pentecostal",
        value: "pentecostal"
    },
    {
        id: 3,
        label: "Catholic",
        value: "catholic"
    },
    {
        id: 4,
        label: "Anglican",
        value: "anglican"
    },
    {
        id: 5,
        label: "Orthodox",
        value: "orthodox"
    },
    {
        id: 6,
        label: "Baptists",
        value: "baptists"
    },
    {
        id: 7,
        label: "Congregational Christian Churches of Canada",
        value: "CCCC"
    },
    {
        id: 8,
        label: "United Church",
        value: "UC"
    },
    {
        id: 9,
        label: "Assemblies of God",
        value: "AG"
    },
    {
        id: 10,
        label: "Baptist",
        value: "Bpt"
    },
    {
        id: 11,
        label: "Christian Reformed Church",
        value: "CRC"
    },
    {
        id: 12,
        label: "Non-Denominational",
        value: "NonD"
    },
    {
        id: 13,
        label: "Associated Gospel",
        value: "AsG"
    },
    {
        id: 14,
        label: "Alliance",
        value: "Allnc"
    },
    {
        id: 15,
        label: "Mennonite",
        value: "Menn"
    },
    {
        id: 16,
        label: "Mennonite Brethern",
        value: "MB"
    },
    {
        id: 17,
        label: "United Mennonite",
        value: "U-Men"
    },
    {
        id: 18,
        label: "Methodists",
        value: "Meth"
    },
    {
        id: 19,
        label: "Presbyterians",
        value: "Pres"
    },
    {
        id: 20,
        label: "Lutheran",
        value: "Luth"
    },
    {
        id: 21,
        label: "Canadian Assemblies of God",
        value: "CAOG"
    }
];


//This are the different organization tiers
export const organizationTiers = [
    {
        id: 1,
        label: "Growing",
        value: "growing"
    },
    {
        id: 2,
        label: "Growing-Moderate",
        value: "growing-moderate"
    },
    {
        id: 3,
        label: "Moderate",
        value: "moderate",
    },
    {
        id: 4,
        label: "Large",
        value: "large",
    },
    {
        id: 5,
        label: "Enterprise",
        value: "enterprise",
    },

]
export const digitalDelivery = [
  {
      id: 1,
      text: "Email",
      value: "email"
  },
  {
      id: 2,
      text: "SMS (text message)",
      value: "sms"
  },
  {
    id: 3,
    text: "Printed Brochures",
    value: "print"
},
]
export const mentalStatus = [
  {
      id: 1,
      status: "Immature"
  },
  {
      id: 2,
      status: "Apathy"
  },
  {
      id: 3,
      status: "Sleepy and Difficulty Eating"
  },
  {
      id: 4,
      status: "Confusion"
  },
  {
      id: 5,
      status: "Depression"
  },
  {
      id: 6,
      status: "Hopelessness"
  },
  {
      id: 7,
      status: "Withdrawal"
  }
]

export const resourceDelivery = [
  {
      id: 1,
      text: "Email",
      value: "email"
  },
  {
      id: 2,
      text: "SMS (text message)",
      value: "sms"
  },
  {
      id: 3,
      text: "Mail (physical)",
      value: "mail",
  }
]
export const followUpTypes = [
  {
      id: 1,
      type: "Salvation",
      key: 1
  },
  {
      id: 2,
      type: "Rededication",
      key: 2
  },
  {
      id: 3,
      type: "Literature/Christian Growth Materials",
      key: 3
  },
  {
      id: 4,
      type:"Walk of Faith/Email Mentoring",
      key: 4
  },
  {
      id: 5,
      type: "Church Referrals",
      key: 5
  },
  {
      id: 6,
      type: "Suicide Call",
      key: 6
  },
  {
    id: 7,
    type: "Prayer Call",
    key: 7
},
]
export const dispositions = [
  {
      id: 1,
      disposition: "Prayer call"
  },
  {
      id: 2,
      disposition: "Pastoral Call - FOLLOW UP"
  },
  {
      id: 3,
      disposition: "Non Ministry"
  },
  {
      id: 4,
      disposition: "Destructive call / FLAG"
  },
]
export const risksLevels = [
  {
      id: 1,
      level: "1"
  },
  {
      id: 2,
      level: "2"
  },
  {
      id: 3,
      level: "3"
  },
  {
      id: 4,
      level: "4"
  },
  {
      id: 5,
      level: "5"
  },
  {
      id: 6,
      level: "6"
  },
  {
      id: 7,
      level: "7"
  },
  {
      id: 8,
      level: "8"
  },
  {
      id: 9,
      level: "9"
  },
  {
      id: 10,
      level: "10"
  }
]
export const financialStatus = [
  {
      id: 1,
      status: "Employed"
  },
  {
      id: 2,
      status: "Unemployed"
  },
  {
      id: 3,
      status: "Secure"
  },
  {
      id: 4,
      status: "Retired"
  },
  {
      id: 5,
      status: "No Money"
  }
]
export const maritialStatus = [
  {
      id: 1,
      status: "Married"
  },
  {
      id: 2,
      status: "Single"
  },
  {
      id: 3,
      status: "Divorced"
  },
  {
      id: 4,
      status: "Widowed"
  },
  {
      id: 5,
      status: "Seperated"
  }
]
// Define an async function to fetch data from an API (or your backend)
async function fetchResources() {
    var options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_API_AUTHORIZATION,
        },
      };
    const response = await fetch(process.env.REACT_APP_API_URL +'/inventory?all=true',options); // URL to your backend endpoint
    const data = await response.json();
    
    return data.data.map(item => ({
      id: item.inventoryID,
      type: item.type,
      resource: item.title,
      url: item.url || null, // Handling null values
      distributor:item.distributor,
    }));
  }
 export const resources = await fetchResources();
// //[
//   {
//       id: 1,
//       type: "digital",
//       resource: "Who I am in Christ",
//       url: "https://crossroads.ca/prayer-cloud-resources/who-i-am-in-christ/",
//   },
//   {
//       id: 2,
//       type: "digital",
//       resource: "The Importance of the Local Church",
//       url: "https://crossroads.ca/prayer-cloud-resources/the-importance-of-the-local-church/"
//   },
//   {
//       id: 3,
//       type: "digital",
//       resource: "Spiritual Warfare",
//       url: "https://crossroads.ca/prayer-cloud-resources/spiritual-warfare-prayer/"
//   },
//   {
//       id: 4,
//       type: "digital",
//       resource: "Romans 8: Life in the Spirit is Victory",
//       url: "https://crossroads.ca/prayer-cloud-resources/life-in-the-spirit-is-the-victory/"
//   },
//   {
//       id: 5,
//       type: "digital",
//       resource: "Peace",
//       url: "https://crossroads.ca/prayer-cloud-resources/pray-for-peace/"
//   },
//   {
//       id: 6,
//       type: "digital",
//       resource: "Prayer & Bible Reading",
//       url: "https://crossroads.ca/prayer-cloud-resources/prayer-bible-reading/"
//   },
//   {
//       id: 7,
//       type: "digital",
//       resource: "Hope",
//       url: "https://crossroads.ca/prayer-cloud-resources/pray-for-hope/"
//   }, 
//   {
//       id: 8,
//       type: "digital",
//       resource: "Healing Body Soul & Spirit",
//       url: "https://crossroads.ca/prayer-cloud-resources/healing-body-soul-and-spirit/"
//   },
//   {
//       id: 9,
//       type: "digital",
//       resource: "Discover Jesus...The Plan of Salvation",
//       url: "https://crossroads.ca/prayer-cloud-resources/jesus-the-plan-of-salvation/"
//   },
//   {
//       id: 10,
//       type: "digital",
//       resource: "Discovering Our True Identity As Children of God: A Theological Journey",
//       url: "https://crossroads.ca/prayer-cloud-resources/discovering-our-true-identity/"
//   },
//   {
//       id: 11,
//       type: "digital",
//       resource: "Guidance",
//       url: "https://crossroads.ca/prayer-cloud-resources/guidance/"
//   },
//   {
//       id: 12,
//       type: "digital",
//       resource: "God's Presence to Comfort",
//       url:"https://crossroads.ca/prayer-cloud-resources/gods-presence-to-comfort/"
//   }, 
//   {
//       id: 13,
//       type: "digital",
//       resource: "The Will of God",
//       url: "https://crossroads.ca/prayer-cloud-resources/praying-the-will-of-god/"
//   },
//   {
//       id: 14,
//       type: "digital",
//       resource: "Breaking Free From Addiction",
//       url: "https://crossroads.ca/prayer-cloud-resources/addictions/"
//   },
//   {
//       id: 15,
//       type: "digital",
//       resource: "Emotional Needs and Finding Comfort In God's Word",
//       url: "https://crossroads.ca/prayer-cloud-resources/emotional-needs/"
//   }, 
//   {
//       id: 16,
//       type: "digital",
//       resource: "Finding Strength in God's Promises",
//       url: "https://crossroads.ca/prayer-cloud-resources/pray-for-faith/"
//   },
//   {
//       id: 17,
//       type: "digital",
//       resource: "Navigating Financial Challenges with Wisdom and Faith",
//       url: "https://crossroads.ca/prayer-cloud-resources/finances"
//   },
//   {
//       id: 18,
//       type: "digital",
//       resource: "Finding Freedom Through Forgiveness",
//       url: "https://crossroads.ca/prayer-cloud-resources/forgiveness/"
//   }, 
//   {
//       id: 19,
//       type: "physical",
//       resource: " New Testament"
//   },
//   {
//       id: 20,
//       type: "physical",
//       resource: "Steps to Freedom in Christ – Neil A"
//   },
//   {
//       id: 21,
//       type: "physical",
//       resource: "Questions & Answers: Starting Out"
//   },
//   {
//       id: 22,
//       type: "physical",
//       resource: "What if God Wrote Your To-Do List?"
//   },
//   {
//       id: 23,
//       type: "physical",
//       resource: "Hope in the Midst of Life's Pain"
//   },
//   {
//       id: 24,
//       type: "physical",
//       resource: "Hope in the Midst of Grief"
//   },
//   {
//       id: 25,
//       type: "physical",
//       resource: "Joy of Prayer"
//   },
//   {
//       id: 26,
//       type: "physical",
//       resource: "John 3:16 – Classic Leather Journal"
//   },
//   {
//       id: 27,
//       type: "physical",
//       resource: "180 Devotional"
//   },
//   {
//       id: 28,
//       type: "physical",
//       resource: "Gospel of John (NLT)"
//   },
//   {
//       id: 29,
//       type: "physical",
//       resource: "The Holy Spirit in God's People"
//   },
// ]
export const callTypes = [
    {
        id: 1,
        type: "Abuse",
        key: 1
    },
    {
        id: 2,
        type: "Addiction",
        key: 2
    },
    {
        id: 3,
        type: "Christian Growth",
        key: 3
    },
    {
        id: 4,
        type: "Current Affairs",
        key: 4
    },
    {
        id: 5,
        type: "Emotional Needs",
        children: ['Navigating Loneliness', 'Mental Health Resources', 'Depression and Sadness', 'Abandonment', 'Emotional Abuse'],
        key: 5
    },
    
    {
        id: 6,
        type: "Family Concerns",
        children: ['Marriage Issues', 'Family Finances', 'Family Abuse', 'Family Substance Abuse', 'Family Relationship Issues'],
        key: 6
    },
    {
        id: 7,
        type: "Financial Needs",
        children: ['Employment Issues', 'Financial Difficulty', 'Debt', 'Gambling/Financial Abuse'],
        key: 7
    },
    {
        id: 8,
        type: "Healing",
        children: ['Physical Healing/Pain', 'Emotional Healing', 
            'Surgical Procedures', 'Healing for a Family Member/Friend', 'Hospice/Palliative Care'
        ],
        key: 8
    },
    {
        id: 9,
        type: "Non Ministry",
        key: 9
    },
    {
        id: 10,
        type: "Salvation/Rededication",
        key: 10
    },
  
    {
        id: 11,
        type: "Spiritual Warfare",
        key: 11
    },
    // this type is selected when the "Potential Suicide Switch is pressed"
    //{
    //     id: 12,
    //     type: "Suicide",
    //     key: 12
    // },
    {
        id: 13,
        type: "Thanksgiving",
        key: 13
    },
    {
        id: 14,
        type: "Other Request",
        key: 14
    },
];
export const ageRanges = [
  {
      id: 1,
      range: "Child"
  },
  {
      id: 2,
      range: "Teenager"
  },
  {
      id: 3,
      range: "Young Adult"
  },
  {
      id: 4,
      range: "Adult"
  },
  {
      id: 5,
      range: "Middle Aged"
  },
  {
      id: 6,
      range: "Seniors"
  }
]
export const followUpStatus = [
  {
      id: "1",
      status: "new"
  },
  {
      id: "2",
      status: "completed"
  },
  {
      id: "3",
      status: "ongoing"
  }
]

//This are the monthly price per call  id in stripe that relates the different types of tiers and types
export const monthlyPrices = {
    enterprise: {
      church: "price_1PsSDnKVHaYmI9HnDrlc81Ql",
      education: "price_1PsVxBKVHaYmI9HnLB30vDFs",
      charity: "price_1PsSI1KVHaYmI9HnjGEVzSrx",
      business: "price_1PsW2bKVHaYmI9HnQy2NmR4d",
    },
    large: {
      church: "price_1PsVzFKVHaYmI9HnAY3CLhxx",
      education: "price_1PsW0WKVHaYmI9HnQsVWkQJF",
      charity: "price_1PsVpmKVHaYmI9HnfWWgYXfi",
      business: "price_1PsW1hKVHaYmI9HnSV8H78dG",
    },
    moderate: {
      church: "price_1PsW4VKVHaYmI9HnZmZ2Mxa9",
      education: "price_1PsW7ZKVHaYmI9HnwvW7BZ6S",
      charity: "price_1PsW5tKVHaYmI9HnTvzoxPJO",
      business: "price_1PsW8xKVHaYmI9Hn8SwZMfQC",
    },
    "growing-moderate": {
      church: "price_1PsWBqKVHaYmI9HnUXEI2NCj",
      education: "price_1PsWE0KVHaYmI9Hn78zxJflJ",
      charity: "price_1PsWF2KVHaYmI9Hngrl27QcK",
      business: "price_1PsWCsKVHaYmI9HnbC8oFaUB",
    },
    growing: {
      church: "price_1PsWIDKVHaYmI9HnZrn59VOW",
      education: "price_1PsWKSKVHaYmI9HnKJkQVyGo",
      charity: "price_1PsWLiKVHaYmI9HnYb56xzoD",
      business: "price_1PsWJ8KVHaYmI9HndA2NO53B",
    },
  };

  export const monthlyPricesTest = {
    enterprise: {
      church: "price_1PGl06KVHaYmI9HnaeWSnbcX",
      education: "price_1PGl2HKVHaYmI9HnopElJYId",
      charity: "price_1PGl1pKVHaYmI9HnQZr0qv6r",
      business: "price_1PGl1JKVHaYmI9Hn73wH2yT4",
    },
    moderate: {
      church: "price_1PJItIKVHaYmI9HnYBzyDYB4",
      education: "price_1PJIxYKVHaYmI9Hnj63fa9om",
      charity: "price_1PJIx5KVHaYmI9HnBXEMHwWo",
      business: "price_1PJIw5KVHaYmI9HnASDbuts3",
    },
  };

export const blockReasons = [
  {
    id: 2,
    delay: 24,
    reason: "Repeated callers (1 day)"
  },
  {
    id: 3,
    delay: 48,
    reason: "Pranks, Jokes, Time waster, Harmless (2 days)"
  },
  {
    id: 4,
    delay: 168,
    reason: "Sexual, Abusive, Destructive (7 days)"
  },
  {
    id: 5,
    delay: 720,
    reason: "Overly repeated callers, Harassment (30 days)"
  },
  {
    id: 6,
    delay: 864000,
    reason: "Permanent Block"
  }
]
export const paymentTerms = [
  {
    id: 1,
    label: "Monthly",
    value: "monthly"
  },
  {
    id: 2,
    label: "Yearly",
    value: "yearly"
  }
]

export const countries = [{id: 1, label: "Canada", value: "Canada"}, {id: 2, label: "USA", value: "USA"}];

export const provinces = {
  "Canada": [{  id: 1, label: "Alberta", value: "AB"}, {"id": 2, label: "British Columbia", value: "BC"},
    {"id": 3, label: "Manitoba", value: "MB"}, {"id": 4, label: "New Brunswick", value: "NB"}, {"id": 5, label: "Newfoundland and Labrador", value: "NL"}, 
    {"id": 6, label: "Nova Scotia", value: "NS"}, {"id": 7, label: "Northwest Territories", value: "NT"}, {"id": 8, label: "Nunavut", value: "NU"},
    {"id": 9, label: "Ontario", value: "ON"}, {"id": 10, label: "Prince Edward Island", value: "PE"}, {"id": 11, label: "Quebec", value: "QC"}, {"id": 12, label: "Saskatchewan", value: "SK"}, {"id": 13, label: "Yukon", value: "YT"}],
  "USA":[

    { id: 1, label: "Alabama", value: "AL" },
    { id: 2, label: "Alaska", value: "AK" },
    { id: 3, label: "Arizona", value: "AZ" },
    { id: 4, label: "Arkansas", value: "AR" },
    { id: 5, label: "California", value: "CA" },
    { id: 6, label: "Colorado", value: "CO" },
    { id: 7, label: "Connecticut", value: "CT" },
    { id: 8, label: "Delaware", value: "DE" },
    { id: 9, label: "Florida", value: "FL" },
    { id: 10, label: "Georgia", value: "GA" },
    { id: 11, label: "Hawaii", value: "HI" },
    { id: 12, label: "Idaho", value: "ID" },
    { id: 13, label: "Illinois", value: "IL" },
    { id: 14, label: "Indiana", value: "IN" },
    { id: 15, label: "Iowa", value: "IA" },
    { id: 16, label: "Kansas", value: "KS" },
    { id: 17, label: "Kentucky", value: "KY" },
    { id: 18, label: "Louisiana", value: "LA" },
    { id: 19, label: "Maine", value: "ME" },
    { id: 20, label: "Maryland", value: "MD" },
    { id: 21, label: "Massachusetts", value: "MA" },
    { id: 22, label: "Michigan", value: "MI" },
    { id: 23, label: "Minnesota", value: "MN" },
    { id: 24, label: "Mississippi", value: "MS" },
    { id: 25, label: "Missouri", value: "MO" },
    { id: 26, label: "Montana", value: "MT" },
    { id: 27, label: "Nebraska", value: "NE" },
    { id: 28, label: "Nevada", value: "NV" },
    { id: 29, label: "New Hampshire", value: "NH" },
    { id: 30, label: "New Jersey", value: "NJ" },
    { id: 31, label: "New Mexico", value: "NM" },
    { id: 32, label: "New York", value: "NY" },
    { id: 33, label: "North Carolina", value: "NC" },
    { id: 34, label: "North Dakota", value: "ND" },
    { id: 35, label: "Ohio", value: "OH" },
    { id: 36, label: "Oklahoma", value: "OK" },
    { id: 37, label: "Oregon", value: "OR" },
    { id: 38, label: "Pennsylvania", value: "PA" },
    { id: 39, label: "Rhode Island", value: "RI" },
    { id: 40, label: "South Carolina", value: "SC" },
    { id: 41, label: "South Dakota", value: "SD" },
    { id: 42, label: "Tennessee", value: "TN" },
    { id: 43, label: "Texas", value: "TX" },
    { id: 44, label: "Utah", value: "UT" },
    { id: 45, label: "Vermont", value: "VT" },
    { id: 46, label: "Virginia", value: "VA" },
    { id: 47, label: "Washington", value: "WA" },
    { id: 48, label: "West Virginia", value: "WV" },
    { id: 49, label: "Wisconsin", value: "WI" },
    { id: 50, label: "Wyoming", value: "WY" }
  ]}
