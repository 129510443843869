import React, { useContext} from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import { AdminPage } from "./admin-page";
import { NotFoundPage } from "./not-found-page";
import { SubscriberPage } from "./subscriber-page";
import { SuperAdminPage } from "./super-admin-page";
import { PrayerPartnerPage } from "./prayer-partner-page";
import PastoralHome from "../components/pastoral-care/pages/home";
import { NavBar } from "../components/navigation/nav-bar";
import { PageFooter } from "../components/page-footer";
import MailingHome from "../components/pastoral-care/pages/fullfillment/home";
export const ProfilePage = () => {

  const { worker } = useContext(ProfileContext)
  const renderSwitch= (param)=> {
    switch(param) {
      case 'admin':
        return <AdminPage/>;
      case 'prayer_centre_lead':
      case 'prayer_centre_admin':
        return <PrayerPartnerPage /> ;
      case 'super_admin':
        return <SuperAdminPage/>  ;

        case 'subscriber':
          return <SubscriberPage />   ;

        case 'pastoral_care':
          return <PastoralHome />   ; 

        case 'fullfillment':
          return <MailingHome/>;
      
      default:
        return <NotFoundPage errorMessage="No Page found for current user" />;
    }
  };
  return (
    //!worker ? <LoadingSpinner/> : worker.role === "pastoral_care" ? <PastoralCare /> :
    <>
      {/* <NavBar/>  */}
      { renderSwitch(worker.role) }
      {/* <PageFooter/> */}
    </>
  );
};