import React from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Register the components to use
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const Charts = (props) => {


  // Configuration options for the chart
  const options = {
    parsing: {
      xAxisKey: 'month',
      yAxisKey: 'caller_count'
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: props.title,
        color:'#EC3310'
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.raw;
          }
        }
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Months'
        },
        grid: {
          display: false
        }
      },
      y: {
        display: false,
        beginAtZero: true,
        title: {
          display: false,
          text: 'Value'
        },
        grid: {
          display: false
        }
      }
    },
    datalabels: {
      color: 'white',
      display: function(context) {
        return context.dataset.data[context.dataIndex] > 15;
      },
      font: {
        weight: 'bold'
      },
      formatter: Math.round
    }
  };

  const allMonths = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Create default data structure if props.data is empty
  const chartData = props.data || allMonths.reduce((acc, month) => {
    acc[month] = { Salvation: '0', Rededication: '0' };
    return acc;
  }, {});

  const data = {
    labels: allMonths,
    datasets: [
      {
        label: 'Salvations',
        data: allMonths.map(month => {
          const monthData = chartData[month];
          return monthData ? parseInt(monthData.Salvation, 10) : 0;
        }),
        backgroundColor: '#FF7234'
      },
      {
        label: 'Re-dedication',
        data: allMonths.map(month => {
          const monthData = chartData[month];
          return monthData ? parseInt(monthData.Rededication, 10) : 0;
        }),
        backgroundColor: '#4BC0C0'
      },
      {
        label: 'Prayer Request',
        data: allMonths.map(month => {
          const monthData = chartData[month];
          return monthData ? parseInt(monthData.Prayers, 10) : 0;
        }),
        backgroundColor: '#CF3748'
      }
    ]
  };

  return (
    <div className="max-h-80 flex bg-white rounded-3xl m-5 justify-center">
      <Bar data={data} options={options} />
    </div>
  );
};

export default Charts;


// import React from "react";
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// // Register the components to use
// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

// const Charts = (props) => {
//            // Configuration options for the chart
//            const options = {
//             parsing: {
//                 xAxisKey: 'month',
//                 yAxisKey: 'caller_count'
//               },
//             responsive: true,
//             plugins: {
//                 legend: {
//                     display: true,
//                 },
//                 title: {
//                     display: true,
//           text: props.title,
//           color:'#EC3310'
//                 },
//                 tooltip: {
//                     callbacks: {
//                         label: function(tooltipItem) {
//                             return tooltipItem.dataset.label + ': ' + tooltipItem.raw.caller_count;
//                         }
//                     }
//                 }
//             },
//             scales: {
//                 x: {
                    
//                     beginAtZero: true,
//                     title: {
//                         display: true,
//                         text: 'Months'
//                     },
//                     grid: {
//                         display:false
//                     }
//                 },
//                 y: {
//                     display: false,
//                     beginAtZero: true,
//                     title: {
//                         display: false,
//                         text: 'Value'
//                     },
//                     grid: {
//                         display:false
//                     }
//                 }
//             },
           
//                 datalabels: {
//                   color: 'white',
//                   display: function(context) {
//                     return context.dataset.data[context.dataIndex] > 15;
//                   },
//                   font: {
//                     weight: 'bold'
//                   },
//                   formatter: Math.round
//                 }
             
//         };
//        const allMonths = {
//         1:'January', 2:'February', 3:'March', 4:'April', 5:'May', 6:'June', 
//         7:'July', 8:'August', 9:'September', 10:'October', 11:'November', 12:'December'
//        };
       
//         const data = {
//             labels:Object.values(allMonths),
//             datasets: [{
//                 label: 'Salvations',
//                 data:Object.keys(allMonths).map(month =>   props.data.Salvation?.find(x=>x.month.toString()=== month) ?? {year:'', month: parseInt(month), caller_count: 0}), 
//                 backgroundColor: '#FF7234'
//             },
//             {
//                 label: 'Re-dedication',
//                 data: Object.keys(allMonths).map(month =>   props.data.Rededication?.find(x=>x.month.toString()=== month) ?? {year:'', month: parseInt(month), caller_count: 0}), 
//                 backgroundColor: '#CF6537'
//             },
//             {
//                 label: 'Prayer Request',
//                 data: Object.keys(allMonths).map(month =>   props.data.Prayers?.find(x=>x.month.toString()=== month) ?? {year:'', month: parseInt(month), caller_count: 0}), 
//                 backgroundColor: '#CF3748'
//             },
            
//         ]
//         }; 
//         return (
//             <div className="max-h-80 flex  bg-white rounded-3xl m-5 justify-center">
//                 <Bar data={data} options={options} />
//             </div>
//         );    
// };
// export default Charts;