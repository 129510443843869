import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { MailingContext } from "../../../../contexts/MailingContext";

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const CustomBarChart = () => {
  const { getMonthlyResourceCount } = useContext(MailingContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getMonthlyResourceCount(); // Assuming this is an API call
        setData(result); // Update state with API response
      } catch (error) {
        console.error("Error fetching monthly resource count:", error);
      }
    };

    fetchData();
  }, [getMonthlyResourceCount]); // Depend on context function

  // Define all months to ensure consistent ordering
  const allMonths = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Ensure each month has default values
  const formattedData = allMonths.map((month) => {
    const monthData = data.find((item) => item.month === month);
    return {
      month,
      physical: monthData ? parseInt(monthData.physical, 10) : 0,
      digital: monthData ? parseInt(monthData.digital, 10) : 0,
    };
  });

  const chartData = {
    labels: allMonths,
    datasets: [
      {
        label: "Physical",
        data: formattedData.map((entry) => entry.physical),
        backgroundColor: "#FF7733",
      },
      {
        label: "Digital",
        data: formattedData.map((entry) => entry.digital),
        backgroundColor: "#C06030",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: "Monthly Resource Distribution",
        color: "#EC3310",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
        },
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="max-h-80 flex bg-white rounded-3xl m-5 justify-center">
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default CustomBarChart;

