import React, { useState, createContext, useEffect } from "react";
import { showErrorMessage, showSuccessMessage } from "../helpers/useToast";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore"; 
import {ref, getStorage, uploadBytesResumable} from 'firebase/storage';
import { db } from "../js/firebase";
import { Fetch } from "../hooks/use-fetch-hook";
import { formatDate } from "../helpers/utils";
import { organizationTypes, organizationTiers, organizationDenominations, countries, provinces } from "../assets/data";

export const OrganizationContext = createContext(null);

// Define initial state for organization data
const initialOrganizationState = {
    name: "",
    type: "",
    tier: "",
    stripeId: "",
    denomination: "",
    paymentTerm: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    website: "",
    organizationPhoneNumber: "",
    carePersonName: "",
    carePersonEmail: "",
    carePersonPhone: "",
    assignedNumber: "",
    prayerPartnersCount: "",
    serviceStartTimes: "",
    churchPrograms: "",
    image: null
};

// Define initial state for the context
const initialState = {
    organization: { ...initialOrganizationState },
    filterByArray: [],
    filterValueArray: [],
    monthlyOrganizationNames: ["Lakepoint Family Church", "Temiskaming Church", "Agora Network Ministries"],
    monthlyOrganization: [],
    organizations: undefined,
    organizationNumbers: {},
    showAllOrganizations: false,
    nameClicked: "",
    currentPage: 1,
    itemsPerPage: 10,
    totalOrganizations: 0
};

export const OrganizationProvider = ({children}) => {
    // Use a single state object instead of multiple useState calls
    const [state, setState] = useState(initialState);
    
    // Helper function to update state
    const updateState = (updates) => {
        setState(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    // Helper function to update organization fields
    const updateOrganizationField = (field, value) => {
        setState(prevState => ({
            ...prevState,
            organization: {
                ...prevState.organization,
                [field]: value
            }
        }));
    };
    
    // Helper function to reset organization
    const resetOrganization = () => {
        setState(prevState => ({
            ...prevState,
            organization: { ...initialOrganizationState }
        }));
    };
    
    // Run the get monthly organizations once
    // useEffect(() => {
    //     getMonthlyOrganization();
    // }, []);
    
    // Run every time a new filter option is selected
    useEffect(() => {
        filterOrganizations(state.filterByArray, state.filterValueArray);
    }, [state.filterByArray]);
    
    // Organizations where payment term is monthly 
    const getMonthlyOrganization = async() => {
        const monthlyOrgs = [];
        const orgRef = collection(db, "organizations");
        const q = query(orgRef, where("name", "in", state.monthlyOrganizationNames));
        const querySnapshot = await getDocs(q);
        
        querySnapshot.forEach((doc) => {
            monthlyOrgs.push(doc.data());
        });
        
        updateState({ monthlyOrganization: monthlyOrgs });
    };
    
    // Filter the organizations based on the filter conditions
    const filterOrganizations = async(filterBy, filterValue) => {
        var collections = [];
        const conditions = [];
        var organizationNumbers = {};
        let nameSearchValue = null;
        let carePersonNameSearchValue = null;
        let createdAtRange = null;
        
        // Extract name search if it exists
        const nameIndex = filterBy.indexOf("name");
        if (nameIndex !== -1) {
            nameSearchValue = filterValue[nameIndex];
            // Remove name from filterBy and filterValue arrays
            filterBy = [...filterBy];
            filterValue = [...filterValue];
            filterBy.splice(nameIndex, 1);
            filterValue.splice(nameIndex, 1);
        }
        
        // Extract carePersonName search if it exists
        const carePersonNameIndex = filterBy.indexOf("carePersonName");
        if (carePersonNameIndex !== -1) {
            carePersonNameSearchValue = filterValue[carePersonNameIndex];
            // Remove carePersonName from filterBy and filterValue arrays
            filterBy = [...filterBy];
            filterValue = [...filterValue];
            filterBy.splice(carePersonNameIndex, 1);
            filterValue.splice(carePersonNameIndex, 1);
        }
        
        // Extract createdAt date range if it exists
        const createdAtIndex = filterBy.indexOf("createdAt");
        if (createdAtIndex !== -1) {
            createdAtRange = filterValue[createdAtIndex];
            // Remove createdAt from filterBy and filterValue arrays
            filterBy = [...filterBy];
            filterValue = [...filterValue];
            filterBy.splice(createdAtIndex, 1);
            filterValue.splice(createdAtIndex, 1);
        }
        
        // Build conditions for other filters
        filterBy.forEach((filter, index) => {
            if(Array.isArray(filterValue[index])){
                conditions.push(where(filter, "in", filterValue[index]));
            } else {
                conditions.push(where(filter, "==", filterValue[index]));
            }
        });
        
        // Add date range conditions if present
        if (createdAtRange && createdAtRange.start && createdAtRange.end) {
            // Convert to JavaScript Date objects if they aren't already
            const startDate = createdAtRange.start instanceof Date ? 
                createdAtRange.start : new Date(createdAtRange.start);
            
            const endDate = createdAtRange.end instanceof Date ? 
                createdAtRange.end : new Date(createdAtRange.end);
            
            // Set end date to end of day
            endDate.setHours(23, 59, 59, 999);
            
            // Add date range conditions
            conditions.push(where("createdAt", ">=", startDate));
            conditions.push(where("createdAt", "<=", endDate));
        }
        
        // Fetch organizations with filters
        const orgRef = collection(db, "organizations");
        const q = conditions.length > 0 ? query(orgRef, ...conditions) : query(orgRef);
        const querySnapshot = await getDocs(q);
        
        // Process results and apply client-side filters
        querySnapshot.forEach((doc) => {
            const orgData = doc.data();
            let includeOrg = true;
            
            // Apply name filter if present
            if (nameSearchValue) {
                const orgName = orgData.name?.toLowerCase() || "";
                const searchTerm = nameSearchValue.toLowerCase();
                
                if (!orgName.includes(searchTerm)) {
                    includeOrg = false;
                }
            }
            
            // Apply care person name filter if present
            if (includeOrg && carePersonNameSearchValue) {
                const carePersonName = orgData.carePersonName?.toLowerCase() || "";
                const searchTerm = carePersonNameSearchValue.toLowerCase();
                
                if (!carePersonName.includes(searchTerm)) {
                    includeOrg = false;
                }
            }
            
            // Include organization if it passed all filters
            if (includeOrg) {
                collections.push(orgData);
                organizationNumbers[orgData.name] = orgData.assignedNumber;
            }
        });
        
        // Update state with filtered results
        updateState({
            totalOrganizations: collections.length,
            organizationNumbers: organizationNumbers,
            organizations: collections,
            currentPage: 1 // Reset to first page when filter changes
        });
    };
    
    // Add organization to the table if all conditions are passed
    const addOrganization = async(setShow) => {
        const org = state.organization;
        
        if(state.organizations && state.organizations.find(organization => organization.name === org.name)){
            showErrorMessage(`Organization with name '${org.name}' already exists`);
            return;
        }
        
        if(!org.name || !org.type || !org.address || !org.city || !org.province || !org.postalCode || !org.country 
            || !org.website || !org.organizationPhoneNumber || !org.carePersonName || !org.carePersonEmail 
            || !org.carePersonPhone || !org.tier || !org.paymentTerm || !org.stripeId){
            showErrorMessage("Some fields are required to complete Sign Up");
            return;
        }
        
        if(!org.image){
            showErrorMessage("Organization Logo is required");
            return;
        }
        
        const storage = getStorage();
        const storageRef = ref(storage, `/logos/${org.name}.png`);
        uploadBytesResumable(storageRef, org.image);
        
        await addDoc(collection(db, "organizations"), {
            name: org.name,
            type: org.type,
            tier: org.tier,
            stripeId: org.stripeId,
            denomination: org.denomination || "",
            paymentTerm: org.paymentTerm,
            address: org.address,
            city: org.city,
            province: org.province,
            country: org.country,
            postalCode: org.postalCode,
            website: org.website,
            organizationPhoneNumber: org.organizationPhoneNumber,
            carePersonName: org.carePersonName,
            carePersonEmail: org.carePersonEmail,
            carePersonPhone: org.carePersonPhone,
            assignedNumber: org.assignedNumber,
            prayerPartnersCount: org.prayerPartnersCount,
            serviceStartTimes: org.serviceStartTimes,
            churchPrograms: org.churchPrograms,
            createdAt: new Date()
        });
        
        showSuccessMessage("New Organization Added Successfully");
        setShow(false);
        resetOrganization();
    };
    
    // Get total monthly calls for all monthly organizations
    const getTotalMonthlyCalls = async() => {
        if(state.monthlyOrganization){
            var firstDay = new Date(); // current date
            firstDay.setDate(1);
            firstDay.setDate(0); // set to last day of previous month
            let firstDayOfPreviousMonth = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1);
            
            var lastDayOfPreviousMonth = new Date(); 
            lastDayOfPreviousMonth.setDate(1); // going to 1st of the month
            lastDayOfPreviousMonth.setHours(-1); // removing on hour goes to previous month
            lastDayOfPreviousMonth.setMinutes(59);
            lastDayOfPreviousMonth.setSeconds(59);
            
            var monthlyOrgs = [];
            
            await Promise.all(state.monthlyOrganization.map(async(organization) => {
                const url = `${process.env.REACT_APP_API_URL}/org_totals?twilio_number=
                ${encodeURIComponent(organization.assignedNumber)}&start_date=${formatDate(firstDayOfPreviousMonth)}&end_date=${formatDate(lastDayOfPreviousMonth)}`;
                var options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                        "Accept": 'application/json',
                    },
                };
                const result = await Fetch(url, options);
                monthlyOrgs.push({
                    stripeId: organization.stripeId, 
                    tier: organization.tier, 
                    type: organization.type, 
                    totalMonthlyCalls: result.data.total_calls
                });
            }));
            return monthlyOrgs;
        }
    };
    
    // On logo change while creating the organization
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            updateOrganizationField('image', event.target.files[0]);
        }
    };
    
    return (
        <OrganizationContext.Provider 
            value={{
                // Organization state and actions
                organization: state.organization,
                updateOrganizationField,
                resetOrganization,
                addOrganization,
                onImageChange,
                
                // Filter state and actions
                filterByArray: state.filterByArray,
                setFilterByArray: (value) => updateState({ filterByArray: value }),
                filterValueArray: state.filterValueArray,
                setFilterValueArray: (value) => updateState({ filterValueArray: value }),
                filterOrganizations,
                
                // Organization data
                organizations: state.organizations,
                organizationNumbers: state.organizationNumbers,
                
                // Monthly organization data
                monthlyOrganization: state.monthlyOrganization,
                getTotalMonthlyCalls,
                
                // UI state
                showAllOrganizations: state.showAllOrganizations,
                setShowAllOrganizations: (value) => updateState({ showAllOrganizations: value }),
                nameClicked: state.nameClicked,
                setNameClicked: (value) => updateState({ nameClicked: value }),
                
                // Pagination
                currentPage: state.currentPage,
                setCurrentPage: (value) => updateState({ currentPage: value }),
                itemsPerPage: state.itemsPerPage,
                totalOrganizations: state.totalOrganizations,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
};
