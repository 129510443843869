import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronDown } from "react-icons/fa";

const ExportButton = ({ menuOptions = [] }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = () => {
    setOpenMenu((prev) => !prev);
  };

  const handleMenuItemClick = (onClick) => {
    setOpenMenu(false); // Close menu after selection
    if (onClick) onClick(); // Execute the provided function
  };

  return (
    <MenuContainer>
      <CustomedButton onClick={handleClick} className="px-5 py-2">
        Export Options
        <FaChevronDown className={`h-3.5 w-3.5 transition-transform ${openMenu ? "rotate-180" : ""}`} />
      </CustomedButton>
      <DropdownMenu show={openMenu}>
        {menuOptions.map((item, index) => (
          <MenuItemm key={index} onClick={() => handleMenuItemClick(item.onClick)}>
            {item.label}
          </MenuItemm>
        ))}
      </DropdownMenu>
    </MenuContainer>
  );
};

export default ExportButton;

// Styled Components
const CustomedButton = styled.button`
  display: flex;
  flex-flow: row;
  border-radius: 8px;
  justify-content: center;
  border: 1px #2db3c0 solid;
  color: #2db3c0;
  text-align: center;
  align-items: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2.1px;
  cursor: pointer;
  padding: 10px 15px;
  background: white;
  &:hover {
    background: #f0f0f0;
  }
`;

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 1000;
`;

const MenuItemm = styled.div`
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px;
  border-bottom: 1px solid #444;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #555;
  }
`;
