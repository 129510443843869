import React, { useContext,  useState,useEffect } from 'react'
import styled from 'styled-components';
import PastoralPagination from '../../components/pagination';
import FollowUpFilter from '../../components/followUpFilter';
import { MailingContext } from '../../../../contexts/MailingContext';
import { useNavigate } from 'react-router-dom';
import {downloadCSV} from "../../../../helpers/utils";
import { splitFullName } from "../../../../helpers/useFunctions";
import { resources } from "./../../../../assets/data";
import { showErrorMessage, showSuccessMessage } from '../../../../helpers/useToast';


export const FulfilledList = (props) => { 
    const status = props.status?props.status:'';
    const title = props.listTitle;
    const { getResources,searchValue,sortOrder, updateResource, updateInventory,getExternalResource,updateExtenalResource} = useContext(MailingContext);  
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1); 
    const [filter, setFilter] = useState(""); 
    const [resourceRequests,  setResourceRequests] =useState([]);
    const navigate = useNavigate();
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    const handleNav = (id) => {
      const path = `/resources/${id}`;
        navigate(path);
    };

    const handleButtonClick = (event,id) => {
      event.stopPropagation(); // Prevent the row click event
      const path = `/resources/returned/${id}`;
      navigate(path);
    };
    const handlePrintExternalCSV = async () => {
      console.log("Print PDF clicked");
      const errors = [];
      const header = ["Firstname", "Lastname", "Address", "City", "Province", "Postal Code", "Country"];
       const extenalResources = resources.filter((res) => res.distributor=== 'Anchor' ).map((res) => res.resource);;
       const mergedHeader = [...new Set([...header, ...extenalResources])];
       
      const data = [];
    
      try {
        const results = await getExternalResource();
        if (results.error) {
          errors.push(results.message);
        } else {
          for (const resourceRequest of results.data) {
            const rowHead = {}; 
            const rowTail = {}; 
          
            // Extract first and last name
            const { firstName, lastName } = splitFullName(resourceRequest.userName || " ");
          
            // Process address parts
            const addressParts = resourceRequest.address ? resourceRequest.address.split(", ") : [];
            const [street = "", city = "", code = "", state = "", country = ""] = addressParts;
          
            // Assign values to rowHead (modifying object instead of reassigning)
            Object.assign(rowHead, {
              Firstname: firstName,
              Lastname: lastName,
              Address: street,
              City: city,
              Province: state,
              Postal_Code: code,
              Country: country,
            });
          
            // Properly filter external resources
            const resourceTitles = resourceRequest.titles ? resourceRequest.titles.split(',').map(title => title.trim()) : [];
            const resourceMapping = Object.fromEntries(
              extenalResources.map((x) => [x, resourceTitles.includes(x)?1:0])
            );
          
            console.log("Resource Mapping:", resourceMapping);
          
            // Assign values to rowTail
            Object.assign(rowTail, resourceMapping);
          
            // Merge and push
            const mergedObject = { ...rowHead, ...rowTail };
            console.log("Merged Object:", mergedObject);
          updateExtenalResource(resourceRequest.external_id,{status:2})
            data.push(mergedObject);
          }
          
      downloadCSV(mergedHeader, data);
      
        }
    } catch (error) {
      errors.push(`Unexpected error: ${error.message}`);
    }
  
    // Show errors if any exist
    if (errors.length > 0) {
      errors.forEach((message) => showErrorMessage(message));
    } else {
      showSuccessMessage("All External Resources Request Have been Exported to The CSV File.");
   
    }
      
    };

    const handleExportMailingCSV = async () => {
      const errors = [];
      let data = [];
    
      try {
        const results = await getResources("sent", "", "");
    
        if (results.error) {
          errors.push(results.message);
        } else {
          const resourceSet = new Set(); // Store unique resource names for headers
          const resourceMap = new Map(); // Track inventory updates
          const processedData = [];
    
          // First pass: Collect resource names, process addresses, and track inventory
          for (const resourceRequest of results.data) {
            const { firstName, lastName } = splitFullName(resourceRequest.userName || " ");
            const addressParts = resourceRequest.address ? resourceRequest.address.split(", ") : [];
            const [street = "", city = "", code = "", state = "", country = ""] = addressParts;
            
            const row = {
              Firstname: firstName,
              Lastname: lastName,
              Address: street,
              City: city,
              Province: state,
              Postal_Code: code,
              Country: country,
            };
    
            // Process resources
            const titlesArray = resourceRequest.title ? resourceRequest.title.split(",").map((title) => title.trim()) : [];
            const brochureArray = resourceRequest.brochures ? resourceRequest.brochures.split(",").map((brochures) => brochures.trim()) : [];
            console.log(brochureArray);
            const matchingResources = resources.filter((res) => res.distributor !== 'Anchor' && (titlesArray.includes(res.resource.trim()) || brochureArray.includes(res.resource.trim())));
    
            matchingResources.forEach((res) => {
              resourceSet.add(res.resource); // Track resource names for headers
              row[res.resource] = 1; // Mark resource presence
    
              // Track inventory updates
              if (!resourceMap.has(res.id)) {
                resourceMap.set(res.id, { quantityAvailable: -1, quantityOnHold: -1 });
              } else {
                let existing = resourceMap.get(res.id);
                resourceMap.set(res.id, {
                  quantityAvailable: existing.quantityAvailable - 1,
                  quantityOnHold: existing.quantityOnHold - 1,
                });
              }
            });
    
            processedData.push(row);
          }
    
          // Build dynamic CSV headers
          const header = ["Firstname", "Lastname", "Address", "City", "Province", "Postal Code", "Country", ...Array.from(resourceSet)];
          
          // Normalize data: Ensure each row has all headers
          data = processedData.map((row) => {
            const fullRow = { ...row };
            resourceSet.forEach((resource) => {
              if (!fullRow[resource]) fullRow[resource] = ""; // Fill missing resources with empty values
            });
            return fullRow;
          });
    
          // Perform resource status updates
          const updatePromises = results.data.map(({ id }) =>
            updateResource(id, { status: "completed" }).catch((err) => {
              errors.push(`Error updating resource ${id}: ${err.message}`);
            })
          );
          
          await Promise.all(updatePromises);
    
          // Perform inventory updates
          const inventoryUpdatePromises = Array.from(resourceMap.entries()).map(([id, values]) =>
            updateInventory(id, {
              quantityAvailable: values.quantityAvailable,
              quantityOnHold: values.quantityOnHold,
            }).catch((err) => {
              errors.push(`Error updating inventory ${id}: ${err.message}`);
            })
          );
    
          await Promise.all(inventoryUpdatePromises);
    
          // Download CSV
          await downloadCSV(header, data);
        }
      } catch (error) {
        errors.push(`Unexpected error: ${error.message}`);
      }
    
      // Show errors or success message
      if (errors.length > 0) {
        errors.forEach((message) => showErrorMessage(message));
      } else {
        showSuccessMessage("All Ready for Shipping Request Have been Successfully Completed.");
        navigate(`/resources/completed`);
      }
    };
    
  
    // const handleExportMailingCSV = async () => {
    //   const errors = [];
    //   const header = ["Firstname", "Lastname", "Address", "City", "Province", "Postal Code", "Country"];
    //   const data = [];
    
    //   try {
    //     const results = await getResources("sent", "", "");
    
    //     if (results.error) {
    //       errors.push(results.message);
    //     } else {
    //       const resourceMap = new Map();
    //       const updatePromises = [];
    
    //       for (const resourceRequest of results.data) {
    //         const { firstName, lastName } = splitFullName(resourceRequest.userName || " ");
    //         const addressParts = resourceRequest.address ? resourceRequest.address.split(", ") : [];
    //         const [street = "", city = "", code = "", state = "", country = ""] = addressParts;
    
    //         data.push({
    //           Firstname: firstName,
    //           Lastname: lastName,
    //           Address: street,
    //           City: city,
    //           Province: state,
    //           Postal_Code: code,
    //           Country: country,
    //         });
    
    //         if (resourceRequest.is_restock === 0) {
    //           const titlesArray = resourceRequest.title ? resourceRequest.title.split(",").map((title) => title.trim()) : [];
    //           const matchingResources = resources.filter((res) => titlesArray.includes(res.resource.trim()));
    
    //           matchingResources.forEach((res) => {
    //             if (!resourceMap.has(res.id)) {
    //               resourceMap.set(res.id, { quantityAvailable: -1, quantityOnHold: -1 });
    //             } else {
    //               let existing = resourceMap.get(res.id);
    //               resourceMap.set(res.id, {
    //                 quantityAvailable: existing.quantityAvailable - 1,
    //                 quantityOnHold: existing.quantityOnHold - 1,
    //               });
    //             }
    //           });
    //         }
    
    //         // Collect updateResource promises
    //         updatePromises.push(updateResource(resourceRequest.id, { status: "completed" }).catch((err) => {
    //           errors.push(`Error updating resource ${resourceRequest.id}: ${err.message}`);
    //         }));
    //       }
    
    //       // Wait for all resource updates to complete
    //       await Promise.all(updatePromises);
    
    //       // Update inventory in batch
    //       const inventoryUpdatePromises = Array.from(resourceMap.entries()).map(([id, values]) =>
    //         updateInventory(id, { quantityAvailable: values.quantityAvailable, quantityOnHold: values.quantityOnHold }).catch((err) => {
    //           errors.push(`Error updating inventory ${id}: ${err.message}`);
    //         })
    //       );
    
    //       await Promise.all(inventoryUpdatePromises);
    
    //       // Ensure CSV download completes before navigation
    //       await downloadCSV(header, data);
    //     }
    //   } catch (error) {
    //     errors.push(`Unexpected error: ${error.message}`);
    //   }
    
    //   // Show errors if any exist
    //   if (errors.length > 0) {
    //     errors.forEach((message) => showErrorMessage(message));
    //   } else {
    //     showSuccessMessage("All Ready for Shipping Request Have been Successfully Completed.");
    //     navigate(`/completed`);
    //   }
    // };
    const exportOptions = [
      { label: "Export External CSV File", onClick: handlePrintExternalCSV },
      { label: "Export Mailing address CSV File", onClick: handleExportMailingCSV }
    ];
    useEffect(()=>{

        getResources(status,currentPage,'').then((results)=>{
          setResourceRequests(results);
        })
        
    },[currentPage,searchValue,sortOrder])
    return(
        <><div className="justify-end">
        <FollowUpFilter context={MailingContext} menuOptions={exportOptions } exportButton = {props.exportButton} showCallerUserNmber = {true} showCallerName={true} showAgentName ={true} />
        </div>
        <Wrapper className=' m-5 px-3'>
        
        <Header className='px-5 p-3 mt-4 justify-center'> 
            <TableHeading> {title}</TableHeading>

        </Header>
        {resourceRequests.data ?
        <> 
            <Table>
                <thead>
                    <TableHeaderRow >                    
                        <th className='text-center'>{status !== 'sent'? 'Completion ':'Processing '} Date</th>
                        <th className='text-center'> Caller Name</th>
                        <th className='text-center'> Phone Number</th>
                        <th className='text-center'> Mailing Address</th>
                        <th className='text-center'>Resources</th>
                       {status !== 'sent' && <th className='text-center'>Return Action</th>      }            
                    </TableHeaderRow>
                </thead>
                <tbody>
                {
                    resourceRequests.data.map((resource) => {
                        return (     
                        <TableRow key={resource.id}onClick={() => handleNav(resource.id)}>
                            <td className='p-2'>{new Date(status !== 'sent'?resource.updated_at:resource.fullfillment_date).toLocaleDateString("en-US")}</td> 
                            <td className='p-2 text-center'>{resource.userName ? resource.userName : ""} </td>
                            <td className='p-2 text-center'>{resource.user_number? resource.user_number : ""}</td>
                            <td className='p-2 text-center'>{resource.address} </td>
                            <td >
                            <div className='p-2 flex justify-center flex-col'>
                            {resource.title?.split(",").map((title) => {
                     
                              return (
                                <Physical key={title} className= "status p-1 m-1">
                                  {title}
                                </Physical>
                              );
                              })
                            }
                            </div>
                            </td>
                            {status !== 'sent' &&    <td > 
                             { resource.is_restock? "Restocked":
                             resource.status === 'completed'?
                              <button className='border-1 border-solid px-2 border-[#DFE2E9] text-[#00323B] rounded-lg' onClick={(event) =>handleButtonClick(event,resource.id)}>Returned Mail</button>:
                              "No Action"   
                             }
                              </td>}
                        </TableRow>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
       : <div className="d-flex justify-content-center" >
            <div className="spinner-border" role="status" style={{width: "6em", height: "6em"}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        }
    </Wrapper>
      {resourceRequests && (
          <PastoralPagination
          currentPage ={currentPage}
          itemsPerPage ={itemsPerPage}
          total_count ={ resourceRequests.total_count}
         handlePageChange ={handlePageChange}
         />
      )}
      </>
    )
}
export default FulfilledList;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TableHeaderRow=
styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
 
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  
  &:hover{
  background-color:#F1F1F1;
  cursor: pointer;
  }
`;
const Table = styled.table`
  width: 100%;
   font-family: Space Grotesk;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;

`;
const Physical = styled.span`
  border: 1px solid #00323B;
  background: #EEFDFF;
color:#00323B;
`;
const Digital = styled.span`
  border: 1px solid #FF7234;
  background: #FFEAE4;
color:#FF7234;
`;