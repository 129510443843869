import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MailingContext } from "./../../../../contexts/MailingContext";
import { Spinner } from "react-activity";
import { ToastContainer } from "react-toastify";
import { PiLessThan } from "react-icons/pi";
import styled from "styled-components";
import "react-activity/dist/library.css";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@mui/material";
import { resources } from "./../../../../assets/data";
import ResourcePicker from "../modals/resourcePicker";
import { makeCall } from "../../../../helpers/makeCall";
import { ProfileContext } from "./../../../../contexts/ProfileContext";
import { PastoralContext } from "./../../../../contexts/PastoralContext";
import { toggleElementInArray } from "../../../../helpers/useFunctions";


export const ViewReturnedResource = (props) => {
  const { worker } = useContext(ProfileContext);
  const { setIsOnCall, setCallSettings } = useContext(PastoralContext);
  var params = useParams();
  const navigate = useNavigate();
  const [resource, setResource] = useState();
  const [address, setAddress] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [code, setCode] = useState();
  const [country, setCountry] = useState();
  const [literature, setLiterature] = useState(false);
  const [cover, setCover] = useState(false);
  const [salvation, setSalvation] = useState(false);
  const [showResourcePicker, setShowResourcePicker] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedBrochures, setSelectedBrochures] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const id = params.resourceRequestID;
  const updatedObj = {};
  const [reason,setReason ] = useState([]);
  const [text, setText] = useState("");


  const { getResource, updateResource, updateInventory } =
    useContext(MailingContext);
  const changeContent = () => {
    navigate('/resources/returned');
  };

  const handleBlur = () => {
    console.log("Textarea lost focus. Updating resource...");
    updateResource(id, { fulfillment_notes: text }); // Perform the update
  };
  
  const submitAddress=()=>{
    const mergedAddress = `${street}, ${city}, ${state}, ${code}, ${country}`;
    updateResource(id, { address: mergedAddress}); // Perform the update
  }

  const handleCheckboxChange = (event) => {   
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected reason
      setSelectedReasons((prev) => [...prev, value]);
    } else {
      // Remove the unselected reason
      setSelectedReasons((prev) => prev.filter((reason) => reason !== value));
    }
    updateResource(id, {
        return_reason: selectedReasons.map((obj) => obj).join(","),
      });
  };

  useEffect(() => {
    //setResources(fetchResources());
    getResource(id).then((result) => {
      if (result.error) {
      } else {
        setResource(result["resource_request"]);

        const titlesArray = result["resource_request"].title
          .split(",")
          .map((title) => title.trim());
        const matchingResources = resources.filter((res) =>
          titlesArray.includes(res.resource.trim())
        );
        setSelectedResources(matchingResources);
        setSelectedBrochures(result["resource_request"].brochures?result["resource_request"].brochures.split(",").map((title) => title.trim()):[]);
        const [street, city, code, state, country] =
          result.resource_request.address.split(", ");
        setStreet(street);
        setCity(city);
        setState(state);
        setCode(code);
        setCountry(country);
        setText(result["resource_request"].fulfillment_notes??"");

        setPdfs(
          result["resource_request"]["printouts"]
            ? result["resource_request"]["printouts"].split(",")
            : []
        );
        setLiterature(
          result["resource_request"]["printouts"]
            ? result["resource_request"]["printouts"].includes(
                "Literature Letter"
              )
            : false
        );
        setCover(
          result["resource_request"]["printouts"]
            ? result["resource_request"]["printouts"].includes("Cover Letter")
            : false
        );
        setSalvation(
          result["resource_request"]["printouts"]
            ? result["resource_request"]["printouts"].includes(
                "Salvation / Rededication"
              )
            : false
        );
      }
    });
  }, [id]);

  const anchorResources = selectedResources.filter((r) => r.distributor !== "internal");
  const internalResources = selectedResources.filter((r) => r.distributor === "internal");

  const handleMakeCall = async (resourceDetails) => {
    makeCall(
      resourceDetails.user_number,
      setIsOnCall,
      resourceDetails,
      worker
    ).then((res) => {
      setCallSettings(res);
      // setCall(res.call);
      // setDevice(res.device);
    });
  };

  const handleResourceUpdate = async (resource, status) => {
    const updatedFeilds = { filled_by: worker.fullName, status: status, is_return:'1' };
    
      updatedFeilds.return_date = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
if (status === "restock")
{
  updatedFeilds.is_restock ='1';
  updatedFeilds.status ='completed';
  internalResources.map((resource) => {
  updateInventory(resource.id,{ quantityAvailable:'1'});
  });
}

    if(status === 'completed')
      {
        internalResources.map((resource) => {
         updateInventory(resource.id,{ quantityAvailable:'1'});
        })
      }
    
    updateResource(resource.id, updatedFeilds);
  };

  const handleNav = (request) => {
    const path = request.status === 'Returned' ? `/resources/returned/${request.id}` : `/resources/${request.id}`;
    navigate(path);
  };

  return (
    <div>
      <ResourcePicker
        id={id}
        setShow={setShowResourcePicker}
        show={showResourcePicker}
        selectedResource={selectedResources}
        updateSelectedResources={setSelectedResources}
        onHide={(e) => {
          setShowResourcePicker(false);
        }}
      />
      {resource && !resource.error ? (
        <div className="grid gap-4 grid-cols-1">
          {/* NAVIGATION BACK TO THE LIST PAGE */}
          <BackLinkContainer className="p-3 bg-transparent flex align-middle">
            <button
              onClick={() => changeContent()}
              style={{ background: "none", border: "0" }}
            >
              <PiLessThan size={25} />

              <TableHeading className="text-metallicSeaweed2 flex align-self-center pl-3">
                BACK TO RETURNED MAIL LIST
              </TableHeading>
            </button>
          </BackLinkContainer>

          <ToastContainer />

          {/* RESOURCE REQUEST FORM */}
          <Form id="callForm" autoComplete="off">
            <PastorCareWrapper>
              <TableHeading className="bg-azureish flex justify-center w-full text-marina p-2">
                RETURN INFORMATION
              </TableHeading>
              <CallTypeWrapper className="gap-4 bg-white px-4 flex-row">
                <div className="w-50">
                  <SendViaWrapper className="rounded  my-4 p-3">
                    <Labelspan>PERSONAL INFORMATION</Labelspan>

                    <div className="col">
                      <label htmlFor="fullname">
                        <h6>FULL NAME</h6>
                      </label>
                      <input
                        type="fullname"
                        className="form-control"
                        id="fullname"
                        onChange={(e) => {}}
                        value={resource.userName}
                      />
                    </div>
                  </SendViaWrapper>
                  <div className="flex-col flex">
                    <div> <input type="checkbox" value="non-exist" checked={selectedReasons.includes("non-exist")} onChange={(e)=>handleCheckboxChange(e)} />The address does not exist</div>
                    <div><input type="checkbox" value="insufficient" checked={selectedReasons.includes("insufficient")} onChange={(e)=>handleCheckboxChange(e)} />The item does not bear a sufficient address</div>
                    <div><input type="checkbox" value="moved" checked={selectedReasons.includes("moved")} onChange={(e)=>handleCheckboxChange(e)}/>The addressee has moved without providing a forwarding address</div>
                    <div><input type="checkbox" value="refused" checked={selectedReasons.includes("refused")} onChange={(e)=>handleCheckboxChange(e)}/>The item is refused by the addressee</div>
                    <div><input type="checkbox" value="postage" checked={selectedReasons.includes("postage")} onChange={(e)=>handleCheckboxChange(e)}/>The item contains insufficient postage</div>
                                      
                  </div>
                </div>
                <SendViaWrapper className="rounded p-3 w-50 my-4">
                  <h6 className="mb-3">RETURNING MAIL</h6>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="mailingAddress">
                        <h6> MAILING ADDRESS</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        onChange={(e) => {setStreet(e.target.value)}}
                        value={street}
                      />
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="city">
                        <h6>CITY</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        onChange={(e) => {setCity(e.target.value)}}
                        value={city}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="province">
                        <h6>PROVINCE / STATE</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="province"
                        onChange={(e) => {setState(e.target.value)}}
                        value={state}
                      />
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="postalCode">
                        <h6>POSTAL / ZIP CODE</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postalCode"
                        onChange={(e) => {setCode(e.target.value) }}
                        value={code}
                      />
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="country">
                        <h6>COUNTRY</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        onChange={(e) => {setCountry(e.target.value)}}
                        value={country}
                      />
                    </div>
                    <div className="col content-end">
                      <button className="p-2 text-white bg-ikura rounded " onClick={()=>submitAddress()}>
                      UPDATE ADDRESS
                      </button>
                    </div>
                  </div>
                </SendViaWrapper>
              </CallTypeWrapper>
            </PastorCareWrapper>
            <CallerInfoWrapper className="border-r-2 border-r-[#ADCFD4]">
              <TableHeading className="flex justify-center bg-cream border-0 text-ikura p-2">
                <span className="justify-self-center">FULFILLMENT DETAILS</span>
              </TableHeading>
              <CallTypeWrapper className="gap-4 flex-row bg-white px-4">
                <CallTypes className="w-50 my-4">
                  <Labelspan className="w-100 flex align-items-center mb-2">
                    INCLUDED RESOURCES
                  </Labelspan>
                  <span className="row">PUBLICATIONS :</span>
                  <SpanContainer style={{ width: "35%" }}>
                    {selectedResources.map((resource) => {
                      return (
                        <Physical key={resource.id} className="status p-1 m-1">
                          {resource.resource}
                        </Physical>
                      );
                    })}
                  </SpanContainer>
                  <Icon onClick={(e) => setShowResourcePicker(true)}>
                    <img
                      alt="edit"
                      src={require("./../../../../assets/images/edit.png")}
                    />
                  </Icon>
                  <span className="row">BROCHURES :</span>
                  <SpanContainer style={{ width: "35%" }}>
                    {selectedBrochures.map((brochure) => {
                      return (
                        <Physical key={brochure} className="status p-1 m-1">
                          {brochure}
                        </Physical>
                      );
                    })}
                  </SpanContainer>
                  
                </CallTypes>

                <div className="w-50">
                  <div className="border-solid border-marina border-1 justify-center text-center rounded flex my-4 p-3 flex-col">
                    <label className="row">
                      <Labelspan>WHAT WAS INCLUDED:</Labelspan>
                    </label>
                    <SpanContainer className="gap-3 flex justify-center my-2 w-100">
                      <ApprovalButton
                        className={
                          literature
                            ? " text-richBlack border-richBlack border-solid border-2 rounded"
                            : "text-white bg-richBlack rounded"
                        }
                        onClick={(e) => {
                          setLiterature(!literature);
                          setPdfs(
                            toggleElementInArray(pdfs, "Literature Letter")
                          );
                          updateResource(id, {
                            printouts: pdfs.map((obj) => obj).join(","),
                          });
                        }}
                      >
                        Literature Letter
                      </ApprovalButton>
                      <ApprovalButton
                        className={
                          cover
                            ? " text-richBlack border-richBlack border-solid border-2 rounded"
                            : "text-white bg-richBlack rounded"
                        }
                        onClick={(e) => {
                          setCover(!cover);
                          setPdfs(toggleElementInArray(pdfs, "Cover Letter"));
                          updateResource(id, {
                            printouts: pdfs.map((obj) => obj).join(","),
                          });
                        }}
                      >
                        Cover Letter
                      </ApprovalButton>
                      <ApprovalButton
                        className={
                          salvation
                            ? " text-richBlack border-richBlack border-solid border-2 rounded"
                            : "text-white bg-richBlack rounded"
                        }
                        onClick={(e) => {
                          setSalvation(!salvation);
                          setPdfs(
                            toggleElementInArray(
                              pdfs,
                              "Salvation / Rededication"
                            )
                          );
                          updateResource(id, {
                            printouts: pdfs.map((obj) => obj).join(","),
                          });
                        }}
                      >
                        Salvation / Rededication
                      </ApprovalButton>
                    </SpanContainer>
                  </div>
                </div>
              </CallTypeWrapper>
            </CallerInfoWrapper>

            <div className="m-5">
              <label className="row">
                <Labelspan> ADDITIONAL NOTES</Labelspan>
              </label>
              <textarea
              value={text}
              onChange={(e) => setText(e.target.value)} // Update local state
              onBlur={handleBlur} // Trigger update on blur
              className="border rounded p-2 w-full"
              placeholder="Enter your notes text here"/>
            </div>
            <ButtonWrapper className="mb-5">
              <CallButton
                onClick={(e) => {
                  handleMakeCall(resource);
                }}
                className="p-2 m-2"
              >
                CALL BACK NOW
              </CallButton>

              <UpdateButton
                onClick={(e) => {
                  handleResourceUpdate(resource, "sent");
                }}
                className="p-2 m-2"
              >
                SEND TO SHIPPING
              </UpdateButton>
               <DuplicateButton
                onClick={(e) => {
                  handleResourceUpdate(resource, "restock");
                }}
                className="p-2 m-2"
              >
                RETURN TO STOCK
              </DuplicateButton>

              {/*<CompleteButton onClick={(e) => {}} className="p-2 m-2">
                CANCEL RETURN
              </CompleteButton> */}
            </ButtonWrapper>
          </Form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default ViewReturnedResource;

const PastorCareWrapper = styled.div`
  width: 100%;
`;
const Labelspan = styled.span`
  color: #000;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const Form = styled.div``;
const BackLinkContainer = styled.div`
  button {
    color: #178b96;
    font-weight: 700;
    display: flex;
    font-family: 14px;
    letter-spacing: 0.2em;
    font-size: 0.9em;
    text-decoration: none;
  }
  svg {
    vertical-align: middle;
  }
`;
const CallerInfoWrapper = styled.div`
  width: 100%;
`;
const SpanContainer = styled.div`
  span {
    background-color: #115057;
    color: white;
    border-radius: 8px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .disabled {
    background-color: grey;
  }
`;
const Button = styled.button`
  border-radius: 8px;
  display: block;
  border: 0;
  width: 70%;
  color: white;
`;

const CallButton = styled(Button)`
  background-color: #40d13d;
`;

const UpdateButton = styled(Button)`
  background-color: #2db3c0;
`;
const DuplicateButton = styled(Button)`
  background-color: #ff7234;
`;
const CompleteButton = styled(Button)`
  background-color: #ec3310;
`;

const CallTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f9fa;
`;

const CallTypes = styled.div``;

const Redish = styled.span`
  color: #ec3310 !important;
`;

const SendViaWrapper = styled.div`
  border: 1px solid #2db3c0;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
`;
const Table = styled.table`
  width: 100%;
  font-family: Space Grotesk;
`;
const Th = styled.th`
  text-align: center;
`;
const Physical = styled.span`
  border: 1px solid #2db3c0;
  background: #2db3c0 !important;
`;
const Digital = styled.span`
  border: 1px solid #ff7234;
  background: #ff7234 !important;
`;
const ApprovalButton = styled.button`
  padding: 7px;
`;
const ItemContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Item = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
  padding: 9px 19px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;

  font-family: "Futura PT";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 3.5px;
  &.check-in {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #cf3748;
    background: #fff;
    color: #cf3748;
    &:before {
      background-color: #cf3748;
    }
  }
  &.odd {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #2db3c0;
    background: #fff;
    color: #2db3c0;
    &:before {
      background-color: #2db3c0;
    }
  }
  &.even {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #ff7234;
    background: #fff;
    color: #ff7234;
    &:before {
      background-color: #ff7234;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    width: 12px;
    height: 24px;
    transform: translateY(-50%);
  }
`;
