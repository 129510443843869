import React, {  useEffect, useState } from "react";
import { PiLessThan } from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
export const FullfillmentCompletion = (props) => {


    return(<>
    <BackLinkContainer className="p-3 bg-transparent flex align-middle">
            <button
              onClick={() => {  }}
              style={{ background: "none", border: "0" }}
            >
              <PiLessThan size={25} />
            
            <TableHeading className="text-metallicSeaweed2 flex pl-3 align-self-center">
            
              BACK TO OPTIONS
            </TableHeading>
            </button>
          </BackLinkContainer>
    </>)

}
export default FullfillmentCompletion;

const BackLinkContainer = styled.div`
  button {
    color: #178b96;
    font-weight: 700;
    display: flex;
    font-family: 14px;
    letter-spacing: 0.2em;
    font-size: 0.9em;
    text-decoration: none;
  }
  svg {
    vertical-align: middle;
  }
`;
const TableHeading = styled.span`
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;