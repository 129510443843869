import React, { useContext } from 'react';
import styled from 'styled-components';

export const FilterButton = (props) => {
  const { context } = props; // Context passed as a prop

  // Dynamically choose the context based on the prop
  const { setFilter, filter, setSearchValue } = useContext(context);

  return (
    <div className="flex">
      <Button
        name="filter"
        value={filter}
        className="px-5 py-2 border-1 border-s-[#2DB3C0] rounded-lg text-[#2DB3C0]"
        onChange={(e) => {
          setFilter(e.target.value);
          setSearchValue(""); // Clear search value on filter change
        }}
      >
        <option value="">Filters</option>
        {props.showCallerName && <option value="caller_name">Caller Name</option>}
        {props.showCallerUserNmber && <option value="user_number">Phone Number</option>}
        {props.showAgentName && <option value="agent_name">Prayer Partner</option>}
        {props.showFollowupType && <option value="follow_up_type">Follow up Type</option>}
        {props.showDateRange && <option value="date_range">Date Range</option>}
      </Button>
    </div>
  );
};

export default FilterButton;

const Button = styled.select`
display:flex;
flex-flow:row;
flex-shrink: 0;
border-radius: 8px;
justify-content: center;
border: 1px #2DB3C0 solid;
color: #2DB3C0;
text-align: center;
align-items: center;
font-family: "Space Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
letter-spacing: 2.1px;
flex-shrink: 0;
&:focus
{
box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
color:#2DB3C0;
}
&:focus-visible
{
box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
color:#2DB3C0;
}
`;