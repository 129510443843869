export const splitFullName = (fullName) =>{
    const nameParts = fullName.trim().split(/\s+/); // Split by one or more spaces
  
    let firstName = "";
    let lastName = "";
  
    if (nameParts.length > 1) {
      firstName = nameParts.slice(0, -1).join(" ");
      lastName = nameParts[nameParts.length - 1];
    } else if (nameParts.length === 1) {
      firstName = nameParts[0];
    }
  
    return { firstName, lastName };
}

export const toggleElementInArray=(array, element) =>{
  const index = array.indexOf(element); // Check if the element exists in the array
  if (index === -1) {
    // If it doesn't exist, add it
    array.push(element);
  } else {
    // If it exists, remove it
    array.splice(index, 1);
  }
  return array; // Return the updated array (optional)
}
  
  