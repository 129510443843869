import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MailingContext } from "./../../../../contexts/MailingContext";
import { Spinner } from "react-activity";
import { ToastContainer } from "react-toastify";
import { PiLessThan } from "react-icons/pi";
import styled from "styled-components";
import "react-activity/dist/library.css";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@mui/material";
import { resources } from "./../../../../assets/data";
import ResourcePicker from "../modals/resourcePicker";
import { makeCall } from "../../../../helpers/makeCall";
import { ProfileContext } from "./../../../../contexts/ProfileContext";
import { PastoralContext } from "./../../../../contexts/PastoralContext";
import { toggleElementInArray } from "../../../../helpers/useFunctions";
import Fulfillment from "../modals/fulfillmentDialog";
import { showSuccessMessage } from "../../../../helpers/useToast";


export const ViewResourceRequest = (props) => {
  const { worker } = useContext(ProfileContext);
  const {setIsOnCall, setCallSettings } = useContext(PastoralContext);
  var params = useParams();
  const navigate = useNavigate();
  const [resource,setResource] = useState();
  const [prevResource,setPrevResourcetResource] = useState();
  const [userName, setUserName] = useState();
  const [street,setStreet ] = useState();
  const [city,setCity ] = useState();
  const [state,setState ] = useState();
  const [code,setCode ] = useState();
  const [country,setCountry ] = useState();
  const [literature,setLiterature ] = useState(false);
  const [reason,setReason ] = useState(false);
  const [cover,setCover ] = useState(false);
  const [salvation,setSalvation ] = useState(false);
  const [showResourcePicker,setShowResourcePicker ] = useState(false);
  const [showFilfullmentDialog,setShowFilfullmentDialog]= useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [id, setId] = useState(params.resourceRequestID);
  const [brochures, setBrochures]= useState([]);
  const [text, setText] = useState("");

  const { getResource,updateResource,updateInventory } = useContext(MailingContext);
  const changeContent = () => {
    navigate('/resources');
  };
  const handleBlur = () => {
    console.log("Textarea lost focus. Updating resource...");
    updateResource(id, { fulfillment_notes: text }); // Perform the update
  };

  const submitAddress= async()=>{
    try {
    const mergedAddress = `${street}, ${city}, ${state}, ${code}, ${country}`;
    const response = await updateResource(id, { address: mergedAddress}); // Perform the update
    showSuccessMessage( "Contact Address Updated Successfully")
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  useEffect(() => {
    getResource(id).then((result) => {
        if (result.error) {
        } else {
            setResource(result['resource_request']);
            
            const titlesArray = result['resource_request']['title']?result['resource_request'].title.split(",").map((title) => title.trim()):[];
            const matchingResources = resources.filter((res) =>
              titlesArray.includes(res.resource.trim())
            );
            setSelectedResources(matchingResources)
            const [street,city,code,state,country ] = result.resource_request.address.split(", ");
           setStreet (street);
            setCity (city);
            setState (state);
            setCode (code);
            setCountry (country);

            setPrevResourcetResource(result['previous_titles'].split(",").map((title) => title.trim()))
            setBrochures(result['resource_request']['brochures']?result['resource_request']['brochures'].split(","):[]);
            setReason(result['resource_request']['return_reason']?result['resource_request']['return_reason'].split(","):[]);
            setPdfs(result['resource_request']['printouts']?result['resource_request']['printouts'].split(","):[]);
            setLiterature(result['resource_request']['printouts']?result['resource_request']['printouts'].includes('Literature Letter'):false);
            setCover(result['resource_request']['printouts']?result['resource_request']['printouts'].includes('Cover Letter'):false);
            setSalvation(result['resource_request']['printouts']?result['resource_request']['printouts'].includes('Salvation / Rededication'):false);
            setText(result["resource_request"].fulfillment_notes??"");
            setUserName(result['resource_request']['userName']);
        }
      });
  }, [id]);
  const anchorResources = selectedResources.filter((r) => r.distributor !== "internal");
  const internalResources = selectedResources.filter((r) => r.distributor === "internal");

   const handleMakeCall = async (resourceDetails) => {
      makeCall(
        resourceDetails.user_number,
        setIsOnCall,
        resourceDetails,
        worker
      ).then((res) => {
        setCallSettings(res);
        // setCall(res.call);
        // setDevice(res.device);
      });
    };

    const handleResourceUpdate = async(resource, status)=>{
      try {
      if(status === 'sent')
        {
          internalResources.map((resource) => {
           updateInventory(resource.id,{quantityOnHold:'1'});
          })
        }
        if(status === 'completed' && resource.status === 'sent')
          {
            internalResources.map((resource) => {
             updateInventory(resource.id,{quantityOnHold:'-1'});
            })
          }

          const fulfill_date  = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");

      const response = await updateResource(resource.id,{filled_by:worker.fullName,status:status,fullfillment_date:fulfill_date})
      setId( response?.original?.resource.id || id);

      showSuccessMessage(response?.original?.message || "No message available")
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    };

  return (
    <div>
      <ResourcePicker
      id={id}
        setShow={setShowResourcePicker}
        show={showResourcePicker}
        selectedResource={selectedResources}
        updateSelectedResources={setSelectedResources}
        onHide={(e) => {
          setShowResourcePicker(false);
        }}
      />
      <Fulfillment 
      show={showFilfullmentDialog} 
      resource={resource} 
      selectedResource={selectedResources} 
      changeContent={changeContent}
      onHide={(e) => {
          setShowFilfullmentDialog(false); 
         
          //setId(resource.id||id);
        }}
        pdfs={pdfs}/>
      {resource && !resource.error ? (
        <div className="grid gap-4 grid-cols-1">
          {/* NAVIGATION BACK TO THE LIST PAGE */}
          <BackLinkContainer className="p-3 bg-transparent flex align-middle">
            <button
              onClick={() => changeContent()}
              style={{ background: "none", border: "0" }}
            >
              <PiLessThan size={25} />

              <TableHeading className="text-metallicSeaweed2 flex align-self-center pl-3">
                BACK TO RESOURCES
              </TableHeading>
            </button>
          </BackLinkContainer>

          <ToastContainer />
          {/* TOP TABLE SECTION WITH TABULATED CALL DETAILS */}
          <Wrapper className=" mx-5 ">
            <Table>
              <thead>
                <TableRow>
                  <Th className="text-richBlack p-2">Date</Th>
                  <Th className="text-richBlack"> Caller Name</Th>
                  <Th className="text-richBlack"> Phone Number</Th>
                  <Th className="text-richBlack"> Prayer Partner </Th>
                  <Th className="text-richBlack"> Print Resource </Th>
                  {resource.digitalResources &&     <Th className="text-richBlack"> Digital Resource</Th>}
                </TableRow>
              </thead>
              <tbody>
                <TableRow key={resource.id} className="bg-azureish text-center">
                  <td className="p-3">
                    {new Date(resource.created_at).toLocaleDateString("en-US")}
                  </td>
                  <td>{userName} </td>
                  <td>{resource.user_number}</td>
                  <td>{resource.sent_by} </td>
                  <td >
                  <div className="gap-2 flex flex-wrap justify-center">
                    {selectedResources.map((resource) => {
                      return (
                        <span className="!border-[#00323B] border-2 rounded bg-[#EEFDFF] text-[#00323B]">
                          {resource.resource}
                        </span>
                      );
                    })}
                    </div>
                  </td >
                  {resource.digitalResources &&  
                  <td >
                    <div className="gap-2 flex flex-wrap justify-center">{resource.digitalResources.split(",").map((resource) => {
                      return (
                        <span className=" border-[#FF7234] border-2 rounded bg-[#FFEAE4] text-[#FF7234]">
                          {resource}
                        </span>
                      );
                    })
                    }</div>
                      </td>
                    }
                </TableRow>
              </tbody>
            </Table>
          </Wrapper>

          {/* RESOURCE REQUEST FORM */}
          <Form id="callForm" autoComplete="off">
            <div className="flex flex-row border-b-2 border-b-[#ADCFD4] justify-items-start mx-5">
              <CallerInfoWrapper className="border-r-2 border-r-[#ADCFD4]">
                <TableHeading className="flex justify-center bg-cream border-0 text-ikura p-2">
                  <span className="justify-self-center">
                    RESOURCE FULFILLMENT
                  </span>
                </TableHeading>
                <CallTypeWrapper className="gap-4 bg-white px-4">
                  <CallTypes>
                    <Labelspan className="w-100 flex align-items-center my-4">
                      REQUESTED RESOURCES{" "}
                    </Labelspan>
                    <label className="row"> <span >PUBLICATIONS :</span></label>
                    <SpanContainer style={{ width: "35%" }}>
                      {selectedResources.map((resource) => {
                        return (
                          <Physical
                            key={resource.id}
                            className="status p-1 m-1"
                          >
                            {resource.resource}
                          </Physical>
                        );
                      })}
                    </SpanContainer>
                    { (resource.status !== 'completed' && resource.status !== 'Invalid') && <Icon onClick={(e) => setShowResourcePicker(true)}>
                      <img
                        alt="edit"
                        src={require("./../../../../assets/images/edit.png")}
                      />
                    </Icon>
}
                  </CallTypes>

                 {resource.digitalResources && 
                 <div>
                    <label className="row">
                      <span>DIGITAL SENT BY PRAYER PARTNER :</span>
                    </label>
                    <SpanContainer style={{ width: "35%" }}>
                      {resource.digitalResources?.split(",").map((title) => {
                        return (
                          <Digital key={title} className="status p-1 m-1">
                            {title}
                          </Digital>
                        );
                      })}
                    </SpanContainer>
                  </div>
                  }
               { brochures &&  <div>
                    <label className="row">
                      <span>PRINT OUTS :</span>
                    </label>
                    <SpanContainer style={{ width: "35%" }}>
                      {brochures?.map((title) => {
                        return (
                          <Digital key={title} className="status p-1 m-1">
                            {title}
                          </Digital>
                        );
                      })}
                    </SpanContainer>
                  </div>
      }
      <div>
                    <label className="row">
                      <Labelspan>PREVIOUS RESOURCES SENT</Labelspan>
                    </label>
                    
                    <SpanContainer style={{ width: "35%" }}>
                    {prevResource.map((resource) => {
                        return (
                          <Physical
                            key={resource}
                            className="status p-1 m-1"
                          >
                            {resource}
                          </Physical>
                        );
                      })}
                    </SpanContainer>
                  </div>
                  <div className="border-solid border-marina border-1  rounded  p-3 flex-col">
                    <label className="row">
                      <Labelspan className="!flex justify-center my-2">LETTERS TO BE INCLUDED:</Labelspan>
                    </label>
                    <SpanContainer className="gap-3 !flex justify-center w-100">
                      <ApprovalButton
                        className={
                          literature
                            ?"text-white bg-richBlack rounded" :  " text-richBlack border-richBlack border-solid border-2 rounded"
                           
                        }
                        onClick={(e) => {
                          setLiterature(!literature);
                         setPdfs(toggleElementInArray(pdfs,'Literature Letter'));
                         updateResource(id,{printouts:pdfs.map(obj => obj).join(",")})
                        }}
                        disabled = { resource.status === 'completed'|| resource.status === 'Invalid' }
                      >
                        Literature Letter
                      </ApprovalButton>
                      <ApprovalButton
                        className={
                          cover
                            ? "text-white bg-richBlack rounded": " text-richBlack border-richBlack border-solid border-2 rounded"
                            
                        }
                        onClick={(e) => {
                          setCover(!cover);
                          setPdfs(toggleElementInArray(pdfs,'Cover Letter'));
                          updateResource(id,{printouts:pdfs.map(obj => obj).join(",")})
                        }}
                        disabled = { resource.status === 'completed' || resource.status === 'Invalid' }
                      >
                        Cover Letter
                      </ApprovalButton>
                      <ApprovalButton
                        className={
                          salvation
                            ?  "text-white bg-richBlack rounded" : " text-richBlack border-richBlack border-solid border-2 rounded"
                          
                        }
                        onClick={(e) => {
                          setSalvation(!salvation);
                          setPdfs(toggleElementInArray(pdfs,'Salvation / Rededication'));
                          updateResource(id,{printouts:pdfs.map(obj => obj).join(",")})
                        }}
                        disabled = { resource.status === 'completed' || resource.status === 'Invalid' }
                      >
                        Salvation / Rededication
                      </ApprovalButton>
                    </SpanContainer>
                  </div>
                  
                  
                </CallTypeWrapper>
              </CallerInfoWrapper>
              <PastorCareWrapper>
                <TableHeading className="bg-azureish flex justify-center w-full text-marina p-2">
                  CALLER INFO CONFIRMATION
                </TableHeading>
                <CallTypeWrapper className="gap-4 bg-white px-4">
                  <SendViaWrapper className="rounded  my-4 p-3">
                    <Labelspan>CONTACT EMAIL AND NUMBER</Labelspan>

                    <div className="col">
                      <label htmlFor="emailAddress">
                        <h6>EMAIL</h6>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailAddress"
                        readOnly
                        value={resource.caller_email}
                        disabled = { resource.status === 'completed' || resource.status === 'Invalid' }
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        Format: name@domain.something
                      </small>
                    </div>

                    <div className="col">
                      <label htmlFor="phoneNumber">
                        <h6> PHONE NUMBER</h6>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        readOnly
                        value={resource.user_number}
                        disabled = { resource.status === 'completed' || resource.status === 'Invalid'}
                      />
                    </div>
                  </SendViaWrapper>

                  <SendViaWrapper className="rounded p-3 mb-4">
                    <Labelspan className="mb-3">
                      SHIPPING ADDRESS<Redish>*</Redish>
                    </Labelspan>
                    <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="fullname">
                        <h6>FULL NAME</h6>
                      </label>
                      <input
                        type="fullname"
                        className="form-control"
                        id="fullname"
                        onChange={(e) => { setUserName( e.target.value)}}
                        onBlur={() => { updateResource(id, { userName: userName })}}
                        value={userName}
                        disabled = { resource.status === 'completed' || resource.status === 'Invalid' }
                      />
                    </div>
                    </div>
                    <div className="row form-group mb-2">
                      <div className="col">
                        <label htmlFor="mailingAddress">
                          <h6> MAILING ADDRESS</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          onChange={(e) => {setStreet(e.target.value)}}
                          value={street}
                          disabled = { resource.status === 'completed' || resource.status === 'Invalid' }
                        />
                      </div>
                    </div>
                    <div className="row form-group mb-2">
                      <div className="col">
                        <label htmlFor="city">
                          <h6>CITY</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="city"
                          onChange={(e) => {setCity(e.target.value)}}
                          value={city}
                          disabled = { resource.status === 'completed' || resource.status === 'Invalid'}
                        />
                      </div>
                      <div className="col">
                        <label htmlFor="province">
                          <h6>PROVINCE / STATE</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="province"
                          onChange={(e) => {setState(e.target.value)}}
                          value={state}
                          disabled = { resource.status === 'completed' || resource.status === 'Invalid'}
                        />
                      </div>
                    </div>
                    <div className="row form-group mb-2">
                      <div className="col">
                        <label htmlFor="postalCode">
                          <h6>POSTAL / ZIP CODE</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="postalCode"
                          onChange={(e) => {setCode(e.target.value) }}
                          value={code}
                          disabled = { resource.status === 'completed' || resource.status === 'Invalid'}
                        />
                      </div>
                    </div>
                    <div className="row form-group mb-2">
                      <div className="col">
                        <label htmlFor="country">
                          <h6>COUNTRY</h6>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          onChange={(e) => {setCountry(e.target.value)}}
                          value={country}
                          disabled = { resource.status === 'completed' || resource.status === 'Invalid'}
                        />
                      </div>
                     { (resource.status !== 'completed' && resource.status !== 'Invalid') &&
                      <div className="col content-end">
                        
                        <button className="p-2 text-white bg-ikura rounded" onClick={()=>submitAddress()}>
                       UPDATE ADDRESS
                        </button>
                      </div>
}
                    </div>
                  </SendViaWrapper>
                </CallTypeWrapper>
              </PastorCareWrapper>
            </div>
            <div className="m-5">
              <label className="row">
                <Labelspan> RESOURCES NOTES</Labelspan>
              </label>
              <textarea 
              value={text}
              onChange={(e) => setText(e.target.value)} // Update local state
              onBlur={handleBlur} // Trigger update on blur
              className="border rounded p-2 w-full"
              placeholder="Enter your notes text here"
               disabled = {resource.status === 'completed' || resource.status === 'Invalid' }
               /> 
             
            </div>

   
            {(resource.status !== 'completed' && resource.status !== 'Invalid') &&  <ButtonWrapper className="mb-5">
              <CallButton
                onClick={(e) => {
                  handleMakeCall(resource);
                }}
                className="p-2 m-2"
              >
                CALL BACK NOW
              </CallButton>

             {resource.status !== 'sent' &&  <UpdateButton onClick={(e) => setShowFilfullmentDialog(true)} className="p-2 m-2">
               SEND FOR SHIPPING
              </UpdateButton>
              }
              

             <CompleteButton onClick={(e) => {handleResourceUpdate(resource,'Invalid')}} className="p-2 m-2">
                NO FURTHER ACTION
              </CompleteButton>
            </ButtonWrapper>
          }
          </Form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default ViewResourceRequest;


const PastorCareWrapper = styled.div`
  width: 50%;
`;
const Labelspan = styled.span`
  color: #000;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const Form = styled.div``;
const BackLinkContainer = styled.div`
  button {
    color: #178b96;
    font-weight: 700;
    display: flex;
    font-family: 14px;
    letter-spacing: 0.2em;
    font-size: 0.9em;
    text-decoration: none;
  }
  svg {
    vertical-align: middle;
  }
`;
const CallerInfoWrapper = styled.div`
  width: 50%;
`;
const SpanContainer = styled.div`
  display: inline;
  span {
    background-color: #115057;
    color: white;
    border-radius: 8px;
    display: inline-block;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .disabled {
    background-color: grey;
  }
`;
const Button = styled.button`
  border-radius: 8px;
  display: block;
  border: 0;
  width: 70%;
  color: white;
`;

const CallButton = styled(Button)`
  background-color: #40d13d;
`;

const UpdateButton = styled(Button)`
  background-color: #2DB3C0  ;
`;
const DuplicateButton = styled(Button)`
  background-color: #ff7234;
`;
const CompleteButton = styled(Button)`
  background-color: #EC3310;
`;

const CallTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f9fa;
`;

const CallTypes = styled.div``;

const Redish = styled.span`
  color: #ec3310 !important;
`;

const SendViaWrapper = styled.div`
  border: 1px solid #2db3c0;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;


const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
`;
const Table = styled.table`
  width: 100%;
  font-family: Space Grotesk;
`;
const Th = styled.th`
  text-align: center;
`;
const Physical = styled.span`
  border: 1px solid #2DB3C0;
  background: #2DB3C0 !important;
`;
const Digital = styled.span`
  border: 1px solid #FF7234;
  background: #FF7234 !important;

`;
const ApprovalButton =styled.button`
padding: 7px;  

`;
const ItemContainer = styled.div`
  position: relative;
  width:100%;
`;
const Item = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
  padding: 9px 19px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor:pointer;

  font-family: "Futura PT";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 3.5px;
  &.check-in {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #CF3748;
    background: #fff;
    color: #CF3748;
    &:before {
      background-color: #CF3748;
    }
  }
  &.odd {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #2db3c0;
    background: #fff;
    color: #2db3c0;
    &:before {
      background-color: #2db3c0;
    }
  }
  &.even {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #ff7234;
    background: #fff;
    color: #ff7234;
    &:before {
      background-color: #ff7234;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    width: 12px;
    height: 24px;
    transform: translateY(-50%);
  }
`;
