import React, { useState, createContext } from "react";
import { Fetch } from "../hooks/use-fetch-hook";

export const MailingContext = createContext(null);

export const MailingProvider = ({ children }) => {
  const [activeTopBar, setActiveTopBar] = useState(null);
  const [activeSideBar, setActiveSideBar] = useState(1);
  const [searchValue, setSearchValue] = useState();
  const [sortBy, setSortBy] =useState();
  const [sortOrder, setSortOrder] =useState();
  const [filter, setFilter] = useState("");

  const setSort =(field)=>
  {
     
      switch(field)
      {
        case "newDate":
          setSortBy('created_at');
          break;
        
        default:
                setSortBy('created_at');     
      }   
      sortOrder ==='desc'?
          setSortOrder('asc'):setSortOrder('desc');
  }

  const getResource = async (id) => {
    var url =
      process.env.REACT_APP_API_URL + `/resource/physicals?id=${id}`;
    
    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };
    const result = await fetch(url, options);
    return await result.json();
  };

// Define an async function to fetch data from an API (or your backend)
async function fetchResources() {
  const response = await fetch(process.env.REACT_APP_API_URL +'/inventory'); // URL to your backend endpoint
  const data = await response.json();
  
  return data.map(item => ({
    id: item.id,
    type: item.type,
    resource: item.title,
    url: item.url || null, // Handling null values
  }));
}
  const getInventory = async (status, page,source) => {
    var url =
      process.env.REACT_APP_API_URL + `/inventory`;
      var paramCount = 0;
      const appendSign = ()=>{
       var result= paramCount === 0 ? '?':'&';
        paramCount++;
        return result;
      }
      if (status.length > 0) 
        {
          url += appendSign() +"status="+status;
        }
        url += appendSign() +"type=physical&distributor=internal";

      if (page) url += appendSign() +"page=" + page;
      if (source) url += appendSign() +"source=" + source;
      
        //searching
    if (filter && searchValue) 
      {
        var filterParam="";
        switch(filter)
        {
          case 'agent_name':
          case 'caller_name':
          case 'user_number':
            filterParam = appendSign()  + filter +'='+encodeURIComponent(searchValue);
            break;
        
          default:
            filterParam = appendSign()  + filter +'='+searchValue;
        }
        url +=filterParam ;

      }

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };

    const result = await fetch(url, options);
    return await result.json();
  };

  const updateInventory= async (id,fieldsToUpdate) => {
    const url = process.env.REACT_APP_API_URL + "/inventory/"+id;

    var options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
      body: JSON.stringify({ id,...fieldsToUpdate }),
    };
    const result = await fetch(url, options);
    return await result.json();
  };

  const getResources = async (status, page,source) => {
    var url =
      process.env.REACT_APP_API_URL + `/resource/physical`;
      var paramCount = 0;
      const appendSign = ()=>{
       var result= paramCount === 0 ? '?':'&';
        paramCount++;
        return result;
      }
      if (status.length > 0) 
        {
          url += appendSign() +"status="+status;
        }

      if (page) url += appendSign() +"page=" + page;
      if (typeof source === 'string') url += appendSign() +"source=" + source;
      if (typeof source === 'object') url +='&'+ new URLSearchParams({ ...source, sign: appendSign() }).toString();;
      
        //searching
    if (filter && searchValue) 
      {
        var filterParam="";
        switch(filter)
        {
          case 'agent_name':
          case 'caller_name':
          case 'user_number':
            filterParam = appendSign()  + filter +'='+encodeURIComponent(searchValue);
            break;
        
          default:
            filterParam = appendSign()  + filter +'='+searchValue;
        }
        url +=filterParam ;

      }

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };

    const result = await fetch(url, options);
    return await result.json();
  };

  const updateResource = async (id,fieldsToUpdate) => {
    const url = process.env.REACT_APP_API_URL + "/resource/physical";

    var options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
      body: JSON.stringify({ id,...fieldsToUpdate }),
    };
    const result = await fetch(url, options);
    return await result.json();
  };

  const getExternalResource = async () => {
    var url =
      process.env.REACT_APP_API_URL + `/resource/external?status=1`;
    
    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
    };
    const result = await fetch(url, options);
    return await result.json();
  };
  
  const updateExtenalResource = async (id,fieldsToUpdate) => {
    const url = process.env.REACT_APP_API_URL + "/resource/external/"+id;

    var options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
      body: JSON.stringify({ ...fieldsToUpdate }),
    };
    const result = await fetch(url, options);
    return await result.json();
  };

  const getMonthlyResourceCount = async () => {
    const url = process.env.REACT_APP_API_URL + "/resources/monthly-count";

    var options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_API_AUTHORIZATION,
      },
      
    };
    const result = await fetch(url, options);
    return await result.json();
  };

  return (
    <MailingContext.Provider
      value={{
        updateResource,
        fetchResources,
        getResources,
        getResource,
        getInventory,
        updateInventory,
        getExternalResource,
        updateExtenalResource,
        searchValue,
        setSearchValue,  
        activeTopBar,
        setActiveTopBar,
        activeSideBar,
        setActiveSideBar,
        filter, 
        setFilter,
        setSort,
        sortOrder,
        getMonthlyResourceCount
      }}
    >
      {children}
    </MailingContext.Provider>
  );
};
