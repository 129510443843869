import React, { useState, createContext } from "react";
import usePersistedState from "../hooks/use-persisted.hook";
import { showErrorMessage, showSuccessMessage } from "../helpers/useToast";
import { resources } from "../assets/data";
import { splitFullName } from "../helpers/useFunctions";
import { useNavigate } from "react-router-dom";

export const CallFormContext = createContext(null);

export const CallFormProvider = ({ children }) => {
  const [fullName, setFullName] = usePersistedState("full-name", "");
  const [emailAddress, setEmailAddress] = usePersistedState(
    "email-address",
    ""
  );
  const [address, setAddress] = usePersistedState("address", "");
  const [subscribeCaller, setSubscribeCaller] = usePersistedState(
    "subscribe-caller",
    false
  );
  const [selectedFollowUpTypes, setSelectedFollowUpTypes] = usePersistedState(
    "selected-follow-up-types",
    ""
  );
  const [selectedCallTypes, setSelectedCallTypes] = usePersistedState(
    "selected-call-types",
    ""
  );
  const [callNotes, setCallNotes] = usePersistedState("call-notes", "");
  const [pastoralNotes, setPastoralNotes] = usePersistedState(
    "Pastoal-notes",
    ""
  );
  const [selectedDispositon, setSelectedDisposition] = usePersistedState(
    "selected-disposition",
    ""
  );
  const [selectedAgeRange, setSelectedAgeRange] = usePersistedState(
    "selected-age-range",
    ""
  );
  const [selectedGender, setSelectedGender] = usePersistedState(
    "selected-gender",
    ""
  );

  const [canUseTestimony, setCanUseTestimony] = usePersistedState(
    "can-use-testimony",
    false
  );
  const [sendResource, setSendResource] = usePersistedState(
    "selected-resource-option",
    false
  );

  const [selectedDigitalDelivery, setSelectedDigitalDelivery] =
    usePersistedState("selected-resources-deleivery", "");
  const [selectedDigitalResources, setSelectedDigitalResources] =
    usePersistedState("selected-Digital-resources", "");

  const [selectedPhysicalResources, setSelectedPhysicalResources] =
    usePersistedState("selected-pyhsical-resources", "");

  const [selectedBlockReason, setSelectedBlockReason] = usePersistedState(
    "selected-block-reason",
    ""
  );
  const [temporarilyBlockCaller, setTemporarilyBlockCaller] = usePersistedState(
    "temporarily-block-caller",
    false
  );
  const [blockDelay, setBlockDelay] = usePersistedState("block-delay", "0");
  const [userNumber, setUserNumber] = usePersistedState("user-number", "");
  const [sentBy, setSentBy] = usePersistedState("sentBy", "");
  const [userCountry, setUserCountry] = usePersistedState("user-country", "");
  const [taskSID, setTaskSID] = usePersistedState(
    "task-sid",
    crypto.randomUUID()
  );
  const [twilioNumber, setTwilioNumber] = usePersistedState(
    "twilio-number",
    ""
  );
  const [userZipPostal, setUserZipPostal] = usePersistedState(
    "user-zip-postal",
    ""
  );
  const [userStateProv, setUserStateProv] = usePersistedState(
    "user-state-prov",
    ""
  );
  const [userCity, setUserCity] = usePersistedState("user-city", "");
  const [isHouse, setIsHouse] = usePersistedState("is-house", true);
  const [callerHistory, setCallerHistory] = usePersistedState(
    "caller-history",
    []
  );
  const [showCallHistory, setShowCallHistory] = usePersistedState("show-call-history",false);
  const [agentName, setAgentName] = usePersistedState("agent-name","");
  const [followUpDate, setFollowUpDate] = usePersistedState(
    "follow-up-date",
    new Date()
  );

//Suicide call fiellds
const [isSuicideCall, setIsSuicideCall] = usePersistedState("is-suicide-call", false);
const [harmInflicted, setHarmInflicted] = usePersistedState("harm-inflicted", false);
const [harmInflictedDesc, setHarmInflictedDesc] = usePersistedState("harm-inflicted-desc", "");
const [drugsTaken, setDrugsTaken] = usePersistedState("drugs-taken", false);
const [drugsTakenDesc, setDrugsTakenDesc] = usePersistedState("drugs-taken-desc", "");
const [prevSuicideAttempt, setPrevSuicideAttempt] = usePersistedState("prev-suicide-attempt", false);
const [suicidePlan, setSuicidePlan] = usePersistedState("suicide-plan", "");
const [maritalStatus, setMaritalStatus] = usePersistedState("maritial-status", "");
const [physicalHealth, setPhysicalHealth] = usePersistedState("physical-health", "");
const [psychiatricHistory, setPsychiatricHistory] = usePersistedState("psychiatric-history", false);
const [psychiatricDesc, setPsychiatricDesc] = usePersistedState("psychiatric-desc", "");
const [mentalStatus, setMentalStatus] = usePersistedState("mental-status", "");
const [hoodType, setHoodType] = usePersistedState("hood-type", "");
const [livingType, setLivingType] = usePersistedState("living-type", "");
const [riskLevel, setRiskLevel] = usePersistedState("risk-level", "0");
const [financialResource, setFinancialResource] = usePersistedState("financial-resource", "");
const [isAlcoholic, setIsAlcoholic] = usePersistedState("is-alcoholic", false);
const [isDrugAddict, setIsDrugAddict] = usePersistedState("is-drug-addict", false);
const [lostLovedOne, setLostLovedOne] = usePersistedState("lost-loved-one", false);
const [isAntisocial, setIsAntisocial] = usePersistedState("is-antisocial", false);
const navigate = useNavigate(); 

  const formObj = {
    isSuicideCall, setIsSuicideCall,
    harmInflicted, setHarmInflicted,
    harmInflictedDesc, setHarmInflictedDesc,
    drugsTaken, setDrugsTaken,
    drugsTakenDesc, setDrugsTakenDesc,
    prevSuicideAttempt, setPrevSuicideAttempt,
    suicidePlan, setSuicidePlan,
    maritalStatus, setMaritalStatus,
    physicalHealth, setPhysicalHealth,
    psychiatricHistory, setPsychiatricHistory,
    psychiatricDesc, setPsychiatricDesc,
    mentalStatus, setMentalStatus,
    hoodType, setHoodType,
    livingType, setLivingType,
    riskLevel, setRiskLevel,
    financialResource, setFinancialResource,
    isAlcoholic, setIsAlcoholic,
    isDrugAddict, setIsDrugAddict,
    lostLovedOne, setLostLovedOne,
    isAntisocial, setIsAntisocial,


    agentName,    setAgentName,
    fullName,    setFullName,
    emailAddress,    setEmailAddress,
    userNumber,    setUserNumber,
    address,    setAddress,
    userCity,    setUserCity,
    isHouse,    setIsHouse,
    userZipPostal,    setUserZipPostal,
    userStateProv,    setUserStateProv,
    userCountry,    setUserCountry,
    subscribeCaller,    setSubscribeCaller,
    selectedFollowUpTypes,    setSelectedFollowUpTypes,
    selectedCallTypes,    setSelectedCallTypes,
    callNotes,    setCallNotes,
    pastoralNotes,    setPastoralNotes,
    selectedDispositon,    setSelectedDisposition,
    selectedAgeRange,    setSelectedAgeRange,
    selectedGender,    setSelectedGender,
    sendResource,    setSendResource,
    canUseTestimony,    setCanUseTestimony,
    selectedDigitalDelivery,    setSelectedDigitalDelivery,
    selectedDigitalResources,    setSelectedDigitalResources,
    selectedPhysicalResources,    setSelectedPhysicalResources,
    selectedBlockReason,    setSelectedBlockReason,
    temporarilyBlockCaller,    setTemporarilyBlockCaller,
    blockDelay,    setBlockDelay,
    showCallHistory,    setShowCallHistory,
    twilioNumber,    setTwilioNumber,
    followUpDate,     setFollowUpDate,
    setTaskSID,
    setSentBy
    
  };
 
 const fetchOptions = (method) => {
   return {
     method: method,
     headers: {
       "Content-Type": "application/json",
       "Authorization": process.env.REACT_APP_API_AUTHORIZATION,
       "Accept": 'application/json',
     }
   };
 };
 
 

  const handleMessage = async (event) => {
    //&&
    if (
      event.data.type === "currentTaskInfo" &&
      event.data.data.direction === "inbound"
    ) {
      // get phone number from the postmessage data
      event.data.data.from && setUserNumber(event.data.data.from);
      event.data.data.from_state &&
        setUserStateProv(event.data.data.from_state);
      event.data.data.from_country &&
        setUserCountry(event.data.data.from_country);
      event.data.data.from_city && setUserCity(event.data.data.from_city);
      event.data.data.from_zip && setUserZipPostal(event.data.data.from_zip);
      //event.data.data.task_sid && setTaskSID(event.data.data.task_sid);
      event.data.data.to && setTwilioNumber(event.data.data.to);
      event.data.data.from &&
        setCallerHistory((await getHistory(event.data.data.from)).slice(0, 5));
      event.data.data.worker.full_name &&
        setSentBy(event.data.data.worker.full_name);

      // get agent data
      // var agentName = event.data.data.worker.full_name;
      // var agentSkills = event.data.data.worker.routing.skills;
    }
    window.removeEventListener("message", handleMessage);
  };
  window.addEventListener("message", handleMessage, true);

  const sendMessageToParent = (message) => {
    // window.parent.postMessage({callBackNow: true, destination: followUp.call_details.user_number}, '*')
    window.parent.postMessage(message, "*"); // Replace with the parent document's origin
  };

  //return object if the current caller has called before.
  const getHistory = async (user_number) => {
    const url = process.env.REACT_APP_API_URL + "/caller_history?user_number="+encodeURIComponent(user_number);
    const data = {
      user_number,
    };
    //options.params = JSON.stringify(data);
    const result = await fetch(url, fetchOptions('GET'));
    //console.log(url, result);
    return await result.json();
  };

  const sendReport = async (url, data) => {
    // A user number must be present before any api called is made
    if (userNumber) {
      var options = fetchOptions('POST');
      options.body = JSON.stringify(data);
      const result = await fetch(url,options);
      const response = await result.json();
      if (response.errors)
      {
        showErrorMessage('Resources : '+response.message)
      }
      else
      clearForm();
      return response;
    }
  };

  const clearForm = () => {
    sendMessageToParent({
      type: "formSubmitted",
      message: "Form has been submitted.",
    });
    window.sessionStorage.clear();
    setFullName("");
    setEmailAddress("");
    setAddress("");
    setSubscribeCaller(false);
    setSelectedFollowUpTypes("");
    setCallNotes("");
    setPastoralNotes("");
    setSelectedDisposition("");
    setSelectedAgeRange("");
    setSelectedGender("");
    setSendResource('false');
    setSelectedCallTypes("");
    setCanUseTestimony(false);
    setSelectedDigitalDelivery("");
    setSelectedDigitalResources("");
    setSelectedPhysicalResources("");
    setSelectedBlockReason("");
    setTemporarilyBlockCaller(false);
    setBlockDelay(0);
    setUserNumber("");
    setSentBy("");
    setUserCountry("");
    setTaskSID("");
    setTwilioNumber("");
    setUserZipPostal("");
    setIsHouse(true);
    setUserStateProv("");
    setUserCity("");
    setCallerHistory([]);
    setShowCallHistory(false);
    setFollowUpDate(new Date());
    setIsSuicideCall(false);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    const buttonValue = event.nativeEvent.submitter.value; // Get the value of the button that was clicked


    if (!twilioNumber) {
      showErrorMessage("No Church Number found");
      return;
    }

    if (!userNumber) {
      showErrorMessage("No Caller Number found");
      return;
    }
    if (userNumber.length < 12) {
      showErrorMessage("Caller Number should be at least 9 digits");
      return;
    }

    if (!selectedCallTypes) {
      showErrorMessage("Please select a Call Type.");
      return;
    }

    if (!selectedAgeRange) {
      showErrorMessage("Please select an Age Range.");
      return;
    }

    if (!selectedGender) {
      showErrorMessage("Please select a Gender.");
      return;
    }

    if (!selectedDispositon) {
      showErrorMessage("Please select a Call Disposition.");
      return;
    }

    if (
      selectedDispositon === "Pastoral Call - FOLLOW UP" &&
      selectedFollowUpTypes === ""
    ) {
      showErrorMessage("Select Follow Up Type");
      return;
    }
    if (sendResource === true && !selectedDigitalDelivery) {
      showErrorMessage("Select digital delivery method");
      return;
    }
    if (
      sendResource=== true &&
      !selectedDigitalResources &&
      !selectedPhysicalResources
    ) {
      showErrorMessage("Select resource to be sent");
      return;
    }
    if (
      sendResource === true &&
      !selectedDigitalDelivery &&
      address === "" &&
      userCity === "" &&
      userZipPostal
    ) {
      showErrorMessage(
        "Mailing Address is required to send Physical Resources"
      );
      return;
    }

    if (subscribeCaller && !emailAddress) {
      showErrorMessage(
        "Please add an email address for the newsletter subscription recipient"
      );
      return;
    }

    //check if resoucres should be sent
  
    if (sendResource) {
      //check if digital resoucres should be sent
      //check if deleviery method selected
      if (selectedDigitalDelivery) {
        if (selectedDigitalDelivery === "email" && !emailAddress) {
          showErrorMessage(
            "Digital resource send method via email was chosen, but no email address was provided."
          );
          return;
        } else if (selectedDigitalDelivery === "sms" && !userNumber) {
          showErrorMessage(
            "Digital resource send method via sms was chosen, but no phone number was provided."
          );
          return;
        } else {
          var digitalResources = selectedDigitalResources
            .split(", ")
            .map((digitalResource) => {
              return resources.find(
                (resource) => resource.resource === digitalResource
              );
            });
        
          var digiData = {
            method: selectedDigitalDelivery,
            email: emailAddress,
            user_number: userNumber,
            twilio_number: twilioNumber,
            resources: digitalResources,
            task_sid: taskSID,
            sent_by: sentBy ? sentBy : twilioNumber,
          };
          sendReport(
            process.env.REACT_APP_API_URL + "/resource/digital",
            digiData
          );
        }
      }
    //  console.log(selectedPhysicalResources)
      if (selectedPhysicalResources.length > 0) {
        if (
          address !== "" &&
          userCity !== "" &&
          userCity !== "" &&
          userZipPostal !== ""
        ) {
          var phyData = {
            task_sid: taskSID,
            user_number: userNumber,
            userName: fullName,
            address:
              address +
              " " +
              userCity +
              " " +
              userZipPostal +
              " " +
              userStateProv +
              " " +
              userCountry,
            twilio_number: twilioNumber,
            title: selectedPhysicalResources,
            sent_by: sentBy ? sentBy : twilioNumber,
          };
          sendReport(
            process.env.REACT_APP_API_URL + "/resource/physical",
            phyData
          );
        } else {
          showErrorMessage("Physical resources require an address to be sent.");
        }
      }
    } else {
      showErrorMessage("No resource selected.");
      return;
    }
    // check if physical resoucres should be sent
    var status = buttonValue;
     
    let { firstName, lastName } = splitFullName(fullName);

    var selectedResources = selectedDigitalResources.concat(
      selectedPhysicalResources
    );
  
    var callDetailsData = {

      task_sid: taskSID,
      user_number: userNumber,
      twilio_number: twilioNumber,
      call_type: selectedCallTypes,
      notes: callNotes,
      pastoral_notes: pastoralNotes,
      callresources: selectedResources,
      caller_first_name: firstName ? firstName : "",
      caller_last_name: lastName ? lastName : "",
      caller_email: emailAddress,
      address: address,
      address_line_2: "",
      age_range: selectedAgeRange,
      gender: selectedGender,
      city: userCity,
      province: userStateProv,
      country: userCountry,
      postal_zip: userZipPostal,
      disposition: selectedDispositon,
      follow_up_type: selectedFollowUpTypes,
      status: status,
      newsletter: subscribeCaller ? 1 : 0, 
      agent_name: agentName,
      is_suicide_call:isSuicideCall
    };

    if(status === "2")
      callDetailsData.follow_up_date=status === "2"? new Date().toISOString().slice(0, 19).replace("T", " "):'';

    sendReport(
      process.env.REACT_APP_API_URL + "/call_detail",
      callDetailsData
    ).then((result) => {
      if(result.errors)
      {
        showErrorMessage(result.message)
      }
      else
      showSuccessMessage("Call details successfully saved.");
      clearForm();
      var path="/followups/"+ (status==="2"?'completed':'new');
      navigate(path);
    });

  };

  const handleSendingResources = () => {
    //check if resoucres should be sent
   var isAddressSet=(address !== "" && userCity !== "" && userCity !== "" &&  userZipPostal !== ""  );

    if (sendResource && (isAddressSet || selectedDigitalDelivery)) {
      //check if digital resoucres should be sent
      //check if deleviery method selected
     // console.log('usernumber:',userNumber)
      if (selectedDigitalDelivery && selectedDigitalDelivery !=='print') {
        if (selectedDigitalDelivery === "email" && !emailAddress) {
          showErrorMessage(
            "Digital resource send method via email was chosen, but no email address was provided."
          );
          return -1;
        } else if (selectedDigitalDelivery === "sms" && !userNumber) {
          showErrorMessage(
            "Digital resource send method via sms was chosen, but no phone number was provided."
          );
          return -1;
        } else if ( selectedDigitalDelivery !== "print" ){
          var digitalResources = selectedDigitalResources
            .split(", ")
            .map((digitalResource) => {
              return resources.find(
                (resource) => resource.resource === digitalResource
              );
            });

          var digiData = {
            method: selectedDigitalDelivery,
            email: emailAddress,
            user_number: userNumber,
            twilio_number: twilioNumber,
            resources: digitalResources,
            task_sid: taskSID,
            sent_by: sentBy ? sentBy : twilioNumber,
            resource_src: "pastoral"
          };

          sendReport(
            process.env.REACT_APP_API_URL + "/resource/digital",
            digiData
          );
        }
      }
      
      if (selectedPhysicalResources.length > 0 ||selectedDigitalDelivery ==='print' )
        if (isAddressSet) {
          var phyData = {
            task_sid: taskSID,
            user_number: userNumber,
            userName: fullName,
            address:
              address +
              ", " +
              userCity +
              ", " +
              userZipPostal +
              ", " +
              userStateProv +
              ", " +
              userCountry,
            twilio_number: twilioNumber,
            title: selectedPhysicalResources,
            sent_by: sentBy ? sentBy : twilioNumber,
            resource_src: "pastoral"
          };
          if(selectedDigitalDelivery ==='print')
            {
              phyData.brochures = selectedDigitalResources;
            }
          sendReport(
            process.env.REACT_APP_API_URL + "/resource/physical",
            phyData
          );
        } else {
          showErrorMessage(
            "Physical resources require and address to be sent."
          );
          return -1;
        }
    } else {
      if (selectedPhysicalResources.length > 0)
        showErrorMessage( "Physical resources require an address to be sent." );
      else if( selectedDigitalResources.length > 0)
        showErrorMessage( "Digital resources require either an email address or mobile number to be sent." );
      else
      showErrorMessage("No resource selected.");
      return -1;
    }
  };

  const handleKeyEvent = (e, setter, currentState, option) => {
    if (!currentState.includes(option)) {
      if (currentState === "") {
        setter(option);
      } else {
        setter(currentState + ", " + option);
      }
    }
  };

  const handleResources = (e, deliveryMode) => {
    //remove all physical resources from the seletedResources when delivery mode is email or sms
    if (deliveryMode !== "physicalResources") {
      let physicalResourcesArray = resources
        .filter((resource) => resource.type === "physical")
        .map((obj) => obj.resource);
      let filteredDigitalArray = selectedPhysicalResources
        .split(",")
        .filter((x) => !physicalResourcesArray.some((a) => x.trim() === a));
      filteredDigitalArray = filteredDigitalArray.join(",").trim();
      //console.log(filteredDigitalArray);
      setSelectedPhysicalResources(filteredDigitalArray);
    } else {
      let digitalResourcesArray = resources
        .filter((resource) => resource.type !== "physical")
        .map((obj) => obj.resource);
      let filteredDigitalArray = selectedDigitalResources
        .split(",")
        .filter((x) => !digitalResourcesArray.some((a) => x.trim() === a));
      filteredDigitalArray = filteredDigitalArray.join(",").trim();
      setSelectedDigitalResources(filteredDigitalArray);
    }

    //setSelectedResourceOptions(deliveryMode);
  };

  const handleCheckboxChange = (e, setter, currentState, option) => {
    if (e.target.checked) {
      //add to the list
      if (currentState === "") {
        setter(option);
      } else {
        setter(currentState + ", " + option);
      }
    } else {
      let options = currentState.split(",");
      if (options[0] === option) {
        const index = options.indexOf(option);
        if (index > -1) {
          // only splice array when item is found
          options.splice(index, 1); // 2nd parameter means remove one item only
        }
        setter(options.join(",").trim());
      } else {
        setter(currentState.replace(", " + option, ""));
      }
    }
  };
  return (
    <CallFormContext.Provider
      value={{
        callerHistory,
        setCallerHistory,
        formObj,
        handleOnSubmit,
        handleCheckboxChange,
        clearForm,
        handleKeyEvent,
        handleResources,
        getHistory,
        handleSendingResources
      }}
    >
      {children}
    </CallFormContext.Provider>
  );
};
