import React, {useState, useContext, useEffect} from "react";
import styled from "styled-components";
import { DateRangePicker } from 'rsuite';
import { OrganizationContext } from "../contexts/OrganizationContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { organizationTiers, organizationTypes, organizationDenominations, paymentTerms, countries, provinces } from "../assets/data";
import { CheckboxSelect } from "./checkbox-select";
import { predefinedBottomRanges } from "../assets/data";
import { MdClear } from "react-icons/md";


//This componet handles filter functionality for the different roles in anyone pray dashboard
export const Filter = ({setFilterString, page} ) => {
    const [searchValue, setSearchValue] = useState([]);
    const [searchBy, setSearchBy] = useState("");
    const [selectedRange, setSelectedRange] = useState([]);
    const [searchParams, setSearchParams] = useState({});
    const {filterByArray, setFilterByArray, filterValueArray, setFilterValueArray } = useContext(OrganizationContext)
    const {setDateRange, dateRange, showReports} = useContext(ProfileContext);
    const [selectedCountry, setSelectedCountry] = useState(null);

    // Add a debounced search function for name searches
    const [nameSearchTimeout, setNameSearchTimeout] = useState(null);
    
    useEffect(()=>{
        let filterString = "";
        
        Object.keys(searchParams).map((key, index) =>{
            if(key === "date_range"){
                let newValue = searchParams["date_range"].map((date,index) => {
                    return new Date(date).toISOString().slice(0,10)
                })
                filterString += `start_date=${newValue[0]}&end_date=${newValue[1]}`
            }else{ 
                filterString += new URLSearchParams(searchParams).toString()
            }
        })
        setFilterString && setFilterString(filterString);
    },[searchParams])
    const handleSearch = () =>{
       // set the search parma value
        let trimmedValue = typeof searchValue === 'string' ? searchValue.trim() : searchValue
        setSearchParams({...searchParams, [searchBy]: trimmedValue}) 
    }

    const removeSearchParams = (key) => {
        setSearchParams(({ [key]: value, ...searchParams }) => searchParams);
      };
    

const removeValue = (value, arr, index) => {
    // If the value at the current array index matches the specified value (2)
    if (arr.includes(value)) {
    // Removes the value from the original array
        arr.splice(index, 1);
    }
    return arr;
}

// Pass the removeValue function into the filter function to return the specified value
    const handleRange = (selectedRange) => {
        setSelectedRange(selectedRange)
        setDateRange(selectedRange)
    }

    const handleOrganizationSearch = () => {
        if (filterByArray.indexOf(searchBy) === -1) {
            // Special handling for date ranges
            if (searchBy === "createdAt" && Array.isArray(searchValue) && searchValue.length === 2) {
                // Format dates for filtering
                const formattedValue = {
                    start: searchValue[0],
                    end: searchValue[1]
                };
                setFilterByArray([...filterByArray, searchBy]);
                setFilterValueArray([...filterValueArray, formattedValue]);
            } else {
                // Normal handling for other filter types
                setFilterByArray([...filterByArray, searchBy]);
                setFilterValueArray([...filterValueArray, searchValue]);
            }
            setSearchBy("");
            setSearchValue([]);
        }
    }

    // Add a debounced search function for name searches
    const handleNameSearch = (value, type) => {
        setSearchValue(value);
        
        // Clear any existing timeout
        if (nameSearchTimeout) {
            clearTimeout(nameSearchTimeout);
        }
        
        if (!['name', 'carePersonName'].includes(type)) {
            console.warn('Invalid type provided to handleNameSearch');
            return;
        }
        
        // Set a new timeout to delay the search
        const timeoutId = setTimeout(() => {
            if (value.trim().length > 0) {
                // Check if this filter type already exists
                const existingIndex = filterByArray.indexOf(type);
                
                if (existingIndex === -1) {
                    // Add new filter if it doesn't exist
                    setFilterByArray([...filterByArray, type]);
                    setFilterValueArray([...filterValueArray, value.trim()]);
                } else {
                    // Update existing filter value
                    const newFilterValues = [...filterValueArray];
                    newFilterValues[existingIndex] = value.trim();
                    setFilterValueArray(newFilterValues);
                    
                    // We need to manually trigger a filter update
                    // Since we're updating the array directly without changing its reference
                    const newFilterBy = [...filterByArray];
                    setFilterByArray([]);  // Clear the array
                    setTimeout(() => {     // Then set it back with the updated values
                        setFilterByArray(newFilterBy);
                    }, 0);
                }
            } else {
                // If search term is empty, remove the filter
                const existingIndex = filterByArray.indexOf(type);
                if (existingIndex !== -1) {
                    const newFilterBy = [...filterByArray];
                    const newFilterValue = [...filterValueArray];
                    newFilterBy.splice(existingIndex, 1);
                    newFilterValue.splice(existingIndex, 1);
                    
                    setFilterByArray(newFilterBy);
                    setFilterValueArray(newFilterValue);
                }
            }
        }, 500); // 500ms delay
        
        setNameSearchTimeout(timeoutId);
    };

  return (
    page && page === "callLogs"?
    <Container>
        <Wrapper className="p-4 mb-1">
            <div className="flex">
                <select name="searchBy" className="rounded-s" style={{border: "1px solid black", borderRight: "0", backgroundColor:"#F1F1F1"}} 
                onChange={(e)=>{
                    setSearchValue("");
                    setSearchBy(e.target.value);
                }}>
                    <option value="">Search By</option>
                    <option value="date_range">Date Range</option>
                    <option value="agent_name">Agent Name</option>
                    <option value="caller_number">Caller Number</option>
                    <option value="called_number">Organization Number</option>
                    <option value="date">Date</option>
                </select>
            </div>
        
            <FilterWrapper className="relative flex" data-twe-input-wrapper-init data-twe-input-group-ref>
                {searchBy === "date_range" ? <DateRange placeholder="Select Date Range"
                onChange={(e)=>{
                    e && setSearchValue(e)
                }
                }/>
                :
                <input
                    type="search"
                    className="peer block min-h-[auto] w-full bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary "
                    placeholder="Search Term"
                    aria-label="Search"
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                    style={{border: "1px solid black"}}
                    id="search-input"
                    aria-describedby="search-button" />
                }
                <button
                    className="relative z-[2] -ms-0.5 flex items-center rounded-e px-5  text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-[#FF7234] 
                    hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    type="button"
                    id="search-button"
                    onClick={handleSearch}
                    disabled={searchBy && searchValue? false: true}
                    style={{backgroundColor: searchBy && searchValue?  "#FF7234": "#FCD5C9"}}
                    data-twe-ripple-init
                    data-twe-ripple-color="light">
                    <span className="[&>svg]:h-5 [&>svg]:w-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor">
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    </span>
                </button>
            </FilterWrapper>
        </Wrapper>
        <SearchWrapper className="ml-5 mb-2">
        {Object.keys(searchParams).length > 0 && 
            Object.values(searchParams).map((param, index) =>{
                return(
                    <p className="p-1 m-1 rounded" key={index} >{Object.keys(searchParams)[index]}: 
                        {
                            //checking if the param is an array so if its the date range
                            Array.isArray(param) ? 
                                <span> `{param[0].toLocaleDateString("en-US")} - {param[1].toLocaleDateString("en-US")}`</span>
                                :
                            <span>`{param}` </span>
                        }
                        <MdClear onClick={()=>
                            {
                                removeSearchParams(Object.keys(searchParams)[index])
                                //setSearchParams(newParams)
                                // setFilterByArray([...removeValue(filterBy, filterByArray, index)])
                                // setFilterValueArray([...removeValue(filterValueArray[index], filterValueArray, index)])
                                //setSearchBy("");
                            
                            }
                        }/>
                    </p>
                    )
                })  
            }
        </SearchWrapper>
    </Container>
    : page && page === "superAdminDashboard" ? 
        <Container>
        <Wrapper className="p-4 mb-1" >
            {/* <label htmlFor="searchBy">Search By</label> */}
            <div className="flex">
                <select name="searchBy" value={searchBy} className="rounded-s" style={{border: "1px solid black", borderRight: "0", backgroundColor:"#F1F1F1"}} 
                onChange={(e)=>{
                    setSearchBy(e.target.value)
                    setSearchValue("");
                    // Reset selected country when changing search type
                    if (e.target.value !== "province") {
                        setSelectedCountry(null);
                    }
                }}>
                    <option value="">{ showReports? "Filter By":"Search By"}</option>
                     {!showReports &&
                     <>
                        <option value="type">Organization Type</option>
                        <option value="assignedNumber">Organization Toll Number</option>
                        <option value="tier">Organization Tier</option>
                        <option value="name">Organization Name</option>
                        <option value="carePersonName">Care Person Name</option>

                        <option value="denomination">Denomination</option>
                        <option value="country">Country</option>
                        <option value="province">Province/State</option>
                        <option value="paymentTerm">Payment Term</option>
                        <option value="createdAt">Date Created</option>
                    </>
                    }

                { showReports &&<option value="date_range">Date Range</option> }
                </select>
            </div>
        
            <FilterWrapper className="relative flex" data-twe-input-wrapper-init data-twe-input-group-ref>
                {searchBy === "date_range" ? <DateRange id="selectedRange" value={selectedRange} ranges={predefinedBottomRanges} placeholder="Select Date Range"
                onChange={(e)=>{
                    e && handleRange(e)
                }
                }/>
                : searchBy === "createdAt" ? <DateRange id="createdAtRange" placeholder="Select Date Range"
                onChange={(e)=>{
                    e && setSearchValue(e)
                }}/>
                : searchBy === "type" ? 
                <CheckboxSelect label={"Types"} optionArray={organizationTypes} selectedValue={searchValue} setSelectedValue={setSearchValue}/>
                : searchBy === "tier" ?
                <CheckboxSelect label={"Tiers"} optionArray={organizationTiers} selectedValue={searchValue} setSelectedValue={setSearchValue}/>
                : searchBy === "denomination" ?
                <CheckboxSelect label={"Denominations"} optionArray={organizationDenominations} selectedValue={searchValue} setSelectedValue={setSearchValue}/>
                : searchBy === "paymentTerm" ?
                <CheckboxSelect label={"Payment Terms"} optionArray={paymentTerms} selectedValue={searchValue} setSelectedValue={setSearchValue}/>
                : searchBy === "country" ?
                <CheckboxSelect label={"Countries"} optionArray={countries} selectedValue={searchValue} setSelectedValue={setSearchValue}/>
                : searchBy === "province" ?
                <div className="flex flex-row w-full gap-2">
                    <div className={`${selectedCountry ? 'w-1/3' : 'w-full'}`}>
                        <select 
                            className="w-full p-2 border border-gray-300 rounded"
                            onChange={(e) => {
                                // Find the country object by value
                                const country = countries.find(c => c.value === e.target.value);
                                setSelectedCountry(country);
                            }}
                        >
                            <option value="">Select a country</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.value}>
                                    {country.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    
                    {selectedCountry && (
                        <div className="w-2/3">
                            <CheckboxSelect 
                                label={`${selectedCountry.label} Provinces/States`}
                                optionArray={provinces[selectedCountry.value] || []} 
                                selectedValue={searchValue} 
                                setSelectedValue={setSearchValue}
                            />
                        </div>
                    )}
                </div>
                : searchBy === "name" ?
                <input
                    type="search"
                    className="peer block min-h-[auto] w-full bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary "
                    placeholder="Search organization name..."
                    aria-label="Search"
                    value={searchValue}
                    onChange={(e) => handleNameSearch(e.target.value, "name")}
                    style={{border: "1px solid black"}}
                    id="search-input"
                    aria-describedby="search-button" 
                />
                : searchBy === "carePersonName" ?
                <input
                    type="search"
                    className="peer block min-h-[auto] w-full bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary "
                    placeholder="Search care person name..."
                    aria-label="Search"
                    value={searchValue}
                    onChange={(e) => handleNameSearch(e.target.value, "carePersonName")}
                    style={{border: "1px solid black"}}
                    id="search-care-person-name"
                    aria-describedby="search-button" 
                />
                :
                <input
                    type="search"
                    className="peer block min-h-[auto] w-full bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary "
                    placeholder="Search Term"
                    aria-label="Search"
                    value={searchValue}
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                    style={{border: "1px solid black"}}
                    disabled={!searchBy || searchBy === "date_range" }
                    id="search-input"
                    aria-describedby="search-button" />
                }
                <button
                    className="relative z-[2] -ms-0.5 flex items-center rounded-e px-5  text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-[#FF7234] 
                    hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                    type="button"
                    id="search-button"
                    onClick={handleOrganizationSearch}
                    disabled={searchBy === "name" ? true : (searchBy && searchValue ? false : (searchBy === "province" && !selectedCountry ? true : false))}
                    style={{backgroundColor: searchBy === "name" ? "#FF7234" : (searchBy && searchValue ? "#FF7234" : "#FCD5C9")}}
                    data-twe-ripple-init
                    data-twe-ripple-color="light">
                    <span className="[&>svg]:h-5 [&>svg]:w-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor">
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                    </svg>
                    </span>
                </button>
            </FilterWrapper>
           
        </Wrapper>
        <SearchWrapper className="ml-5 mb-2">
            {filterByArray.length > 0 && 
                filterByArray.map((filterBy, index) => {
                    return(
                        <p className="p-1 m-1 rounded" key={index}>
                            {filterBy[0]?.toUpperCase() + filterBy.slice(1)}:
                            {filterBy === "createdAt" && typeof filterValueArray[index] === "object" ? 
                                <span>
                                    `{filterValueArray[index].start instanceof Date ? 
                                        filterValueArray[index].start.toLocaleDateString("en-US") : 
                                        new Date(filterValueArray[index].start).toLocaleDateString("en-US")} - 
                                    {filterValueArray[index].end instanceof Date ? 
                                        filterValueArray[index].end.toLocaleDateString("en-US") : 
                                        new Date(filterValueArray[index].end).toLocaleDateString("en-US")}` 
                                </span>
                            : Array.isArray(filterValueArray[index]) ? 
                                filterValueArray[index].map((value, id) => {
                                    return <span key={id}>`{String(value)}` </span>
                                })
                            : <span>`{String(filterValueArray[index])}` </span>} 
                            <MdClear onClick={() => {
                                setFilterByArray([...removeValue(filterBy, filterByArray, index)]);
                                setFilterValueArray([...removeValue(filterValueArray[index], filterValueArray, index)]);
                                setSearchBy("");
                            }}/>
                        </p>
                    )
                })  
            }
           {dateRange && dateRange.length === 2 && 
            <p className="p-1 m-1 rounded">Date Range: 
                `{dateRange[0] instanceof Date ? 
                    dateRange[0].toLocaleDateString("en-US") : 
                    new Date(dateRange[0]).toLocaleDateString("en-US")} - 
                {dateRange[1] instanceof Date ? 
                    dateRange[1].toLocaleDateString("en-US") : 
                    new Date(dateRange[1]).toLocaleDateString("en-US")}` 
                <MdClear onClick={()=>
                {
                    setDateRange("");
                    setSelectedRange([])
                    setSearchBy("")
                }}/>
            </p>
            }
        </SearchWrapper>
        </Container>
    : page && page === "subscriberDashboard" ? 
    <Container>
        <Wrapper className="p-4 mb-1" >
            <div className="flex">
                <select name="searchBy" className="rounded-s" style={{border: "1px solid black", borderRight: "0", backgroundColor:"#F1F1F1"}} 
                onChange={(e)=>{
                    setSearchBy(e.target.value)
                    setSearchValue("");
                }}>
                    <option value="date_range">Date Range</option> 
                </select>
            </div>
            <FilterWrapper className="relative flex" data-twe-input-wrapper-init data-twe-input-group-ref>
               <DateRange id="selectedRange" value={selectedRange} ranges={predefinedBottomRanges} placeholder="Select Date Range"
                onChange={(e)=>{
                e && handleRange(e)}}/>
                
            </FilterWrapper>
        </Wrapper>
        <SearchWrapper className="ml-5 mb-2">
            {dateRange && 
            <p className="p-1 m-1 rounded">Date Range: `{dateRange[0].toLocaleDateString("en-US")} - {dateRange[1].toLocaleDateString("en-US")}` 
                <MdClear onClick={()=>
                {
                    setDateRange("");
                    setSelectedRange([])
                    
                }}/>
            </p>
            }
        </SearchWrapper>
    </Container>
    :<></>
  );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: start;
    font-family: "Futura PT";
    font-size: 16px;
    height: 90px;
    button{
        background-color: #FFF;
        border: none;
       color:  #106271
    }

  div{
   height: 100%;
   
  }

`;

const FilterWrapper = styled.div`
   // border: 1px solid #106271;

`;
const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  flex-direction: column;
`
const SearchWrapper = styled.div`
    display flex;
    flex-direction: row;
  p{
    background-color: #86CCDA;
    color: white;
    opacity: 6;
    display: inline;
  }
  svg{
    display: inline;
    cursor: pointer;
  }
`;
const DateRange = styled(DateRangePicker)`
    border: 1px solid #106271;
`;