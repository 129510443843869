import React, { useContext,  useState,useEffect } from 'react'
import styled from 'styled-components';
import PastoralPagination from '../../components/pagination';
import FollowUpFilter from '../../components/followUpFilter';
import { MailingContext } from '../../../../contexts/MailingContext';
import { useNavigate } from 'react-router-dom';

export const MailingResources = (props) => { 

    const source = props.source?props.source:'';
    const status =props.status? props.status:'';
    const showPagination =props.showPagination  !== undefined? props.showPagination:true;
    const showFilter = props.showFilter !== undefined ? props.showFilter: true;
    const { getResources,searchValue,sortOrder} = useContext(MailingContext);  
    const per_page = props.itemsPerPage?props.itemsPerPage:10;
    const [currentPage, setCurrentPage] = useState(1); 
    const [resources,  setResources] =useState([]);
    const navigate = useNavigate();
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    const handleNav = (request) => {
      const path = request.status === 'Returned' 
        ? `/resources/returned/${request.id}` 
        : `/resources/${request.id}`;
      navigate(path);
    };


    useEffect(()=>{

        getResources(status,currentPage,{source,per_page}).then((results)=>{
            setResources(results);
        })
        
    },[currentPage,searchValue,sortOrder])
    return(
        <>
        {showFilter === true &&
          <div className="justify-end">
        <FollowUpFilter context={MailingContext}  showCallerUserNmber = {true} showCallerName={true} showAgentName ={true} />
        </div>}
        <Wrapper className=' m-5 px-3'>
        
        <Header className='px-5 p-3 mt-4 justify-center'> 
            <TableHeading> MAILING RESOURCES</TableHeading>

        </Header>
        {resources.data ?
        <> 
            <Table>
                <thead>
                    <TableHeaderRow >                    
                        <th className='text-center'>Date</th>
                        <th className='text-center'> Caller Name</th>
                        <th className='text-center'> Phone Number</th>
                        <th className='text-center'> Prayer Partner /Pastor</th>
                        <th className='text-center'>Printed Resources</th>
                        <th className='text-center'>Digital Resources</th>                  
                    </TableHeaderRow>
                </thead>
                <tbody>
                {
                    resources.data.map((resource) => {
                        return (     
                        <TableRow key={resource.id}onClick={() => handleNav(resource)}>
                            <td className='p-2'>{new Date(resource.created_at).toLocaleDateString(
                          "en-US"
                        )}</td> 
                            <td className='p-2 text-center'>{resource.userName ? resource.userName : ""} </td>
                            <td className='p-2 text-center'>{resource.user_number? resource.user_number : ""}</td>
                            <td className='p-2 text-center'>{resource.sent_by} </td>
                            <td >
                            <div className='p-2 flex justify-center flex-col'>
                            {resource.title?.split(",").map((title) => {
                     
                              return (
                                <Physical key={title} className= "status p-1 m-1">
                                  {title}
                                </Physical>
                              );
                              })
                            }
                            </div>
                            </td>
                            <td >
                              <div className='p-2 flex justify-center flex-col'>
                               {resource.digitalResources?.split(",").map((title) => {
                     
                              return (
                                <Digital key={title} className= "status p-1 m-1">
                                  {title}
                                </Digital>
                              );
                              })
                            }</div>
                            </td>
                        </TableRow>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
       : <div className="d-flex justify-content-center" >
            <div className="spinner-border" role="status" style={{width: "6em", height: "6em"}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        }
    </Wrapper>
      {(resources && showPagination === true) && (
          <PastoralPagination
          currentPage ={currentPage}
          itemsPerPage ={per_page}
          total_count ={ resources.total_count}
         handlePageChange ={handlePageChange}
         />
      )}
      </>
    )
}
export default MailingResources;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TableHeaderRow=
styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
 
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  
  &:hover{
  background-color:#F1F1F1;
  cursor:pointer;
  }
`;
const Table = styled.table`
  width: 100%;
   font-family: Space Grotesk;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;

`;
const Physical = styled.span`
  border: 1px solid #00323B;
  background: #EEFDFF;
color:#00323B;
`;
const Digital = styled.span`
  border: 1px solid #FF7234;
  background: #FFEAE4;
color:#FF7234;
`;






