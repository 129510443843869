import React, { useState } from "react";
import { Check, X } from "lucide-react";

const EditableInput = ({resource, onSubmit }) => {
    console.log('resource',resource)
  const [inputValue, setInputValue] = useState(resource.quantityAvailable);
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (isConfirmed) => {
    if (isConfirmed) {
      onSubmit(resource.inventoryID,inputValue); // Call API function with new value
    } else {
      setInputValue(resource.quantityAvailable); // Reset input if canceled
    }
    setIsFocused(false); // Hide tick and cross
  };

  return (
    <div className="relative flex items-center border p-2 rounded-lg">
      <input
        type="text"
        className="border-none outline-none flex-1"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => !isFocused && setInputValue(resource.quantityAvailable)} // Only reset if user did not confirm
      />
      {isFocused && (
        <div className="flex gap-2">
          <Check
            className="text-green-500 cursor-pointer"
            onClick={() => handleSubmit(true)}
          />
          <X
            className="text-red-500 cursor-pointer"
            onClick={() => handleSubmit(false)}
          />
        </div>
      )}
    </div>
  );
};

export default EditableInput;
