import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { resources } from "./../../../../assets/data";
import clsx from "clsx";
import { MailingContext } from "../../../../contexts/MailingContext";

export default function ResourcePicker({id, show, onHide, selectedResource, updateSelectedResources }) {
  const [selectedResources, setSelectedResources] = useState([]);
    const filteredResources = resources.filter((resource) => resource.type === "physical");
    const { getResource,updateResource } = useContext(MailingContext);

  // Initialize selected resources from the given resourceTitles
  useEffect(() => {
    console.log(selectedResource)
    if (Array.isArray(selectedResource)) {
        setSelectedResources(selectedResource);
      } else {
        console.warn("selectedResource is not an array");
        setSelectedResources([]); // Fallback to an empty array
      }
  }, [show]);

  // Handle span click
  const handleClick = (clickedResource) => {
    setSelectedResources((prev) =>
      prev.some((r) => r.id === clickedResource.id)
        ? prev.filter((r) => r.id !== clickedResource.id)
        : [...prev, clickedResource]
    );
  };

  const onConfirm = () => {
    updateSelectedResources(selectedResources);
    updateResource(id,{title:selectedResources.map(obj => obj.resource).join(",")});
    onHide(false)
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      centered
      dialogClassName="modal-90w"
    >
      <StyledModalHeader closeButton>
        <Modal.Title className="font-space">RESOURCE CONFIRMATION</Modal.Title>
      </StyledModalHeader>
      <Modal.Body className="flex justify-center bg-[#F8F3F2] p-4">
        <div className="gap-5 flex-auto">
          {filteredResources
            .map((resource) => (
              <div
                key={resource.id}
                className={clsx(
                  "border border-2 text-white", // Base class
                  selectedResources.some(resourceSel => resourceSel.id === resource.id)
                    ? " !border-orange-500 !bg-marina"
                    : "border-gray-400 bg-[#ABABAB]" 
                )}
                onClick={() => handleClick(resource)}
                style={{
                  wordBreak: "none",
                  margin: "2px 10px",
                  cursor: "pointer",
                  padding: "5px",
                }}
              >
                {resource.resource}
              </div>
            ))}
        </div>
      </Modal.Body>
      <StyledModalFooter className="gap-5 pb-10 flex-row">
        <button
          className="bg-marina text-white border-r-5 px-20 py-2 font-space rounded-lg"
          onClick={() => onConfirm()}
        >
          SAVE CHANGES
        </button>
        <button
          className="bg-ikura text-white border-r-5 px-20 py-2 font-space rounded-lg"
          onClick={() => onHide(false)}
        >
          CANCEL CHANGES
        </button>
      </StyledModalFooter>
    </Modal>
  );
}

// Styled components
const StyledModalHeader = styled(Modal.Header)`
  background-color: #00323b;
  color: white;
  border-bottom: 1px solid #00323b;
  font-family: Space Grotesk;
  font-size: 14px;

  &.btn-close {
    color: white; /* Style the close button icon if needed */
  }
`;

const StyledModalFooter = styled(Modal.Footer)`
  border: 0;
  justify-content: center;
  background-color: #f8f3f2;
  padding-bottom: 2.5rem;
`;
