import React, { useContext, useState } from "react";
import styled from "styled-components";
import { makeCall } from "../../../helpers/makeCall";
import { PastoralContext } from "../../../contexts/PastoralContext";
import { ProfileContext } from "./../../../contexts/ProfileContext";
import { convertSeconds } from "../../../helpers/utils";
import Dialer from './dialer';
export const RecordingPlayBack = (props) => {
  const { isOnCall, setIsOnCall, setCallSettings } =
    useContext(PastoralContext);
    const {worker } = useContext(ProfileContext);

  const playback =
    props.playback.length < 1 || props.playback === undefined
      ? null
      : props.playback.find(call => call.direction === 'inbound');

  const [playbackState, setPlayback] = useState({
    user_number: playback ? playback.user_number : ''
  });
  const [showDialer, setShowDialer] = useState(false);
  const [dialerNumber, setDialerNumber] = useState(playback ? playback.user_number : '');

  // MAKE AN OUTGOING CALL

  async function makeOutgoingCall() {
    setShowDialer(true);
   
  }
  return (
    playback &&
    playback.recordings && (
      <Wrapper className=" w-full m-5">
        <div className="flex flex-row m-4 mb-0">
          <span className="bg-marina rounded-full w-10 h-10 block">
            <img
              src={require("./../../../assets/images/PhoneTransfer.png")}
              alt="logo"
              width={"48"}
            />
          </span>
          <div className="justify-between w-full flex">
            <div className="flex flex-col pl-2">
              <Heading>CALL RECORDING</Heading>
              <DetailsSpan className="gap-3 flex">
                <span className="text-ikura py-2">   {props.username} </span>
               <span className="text-ikura py-2"> {playbackState.user_number} </span>
                <span className=" py-2">{convertSeconds(playback.duration).hours+':'+convertSeconds(playback.duration).minutes+':'+convertSeconds(playback.duration).seconds} </span>
              </DetailsSpan>
            </div>
          { props.call_details.status!== '2'&& <button disabled={isOnCall} onClick={(e) => makeOutgoingCall()}>
              {" "}
              CALL BACK NOW
            </button>}
          </div>
        </div>

        <Recording
          controls
          className="m-4 w-11/12 p-0"
          src={playback.recordings}
          type="audio/ogg"
        >
          Your browser does not support the audio element.
        </Recording>
        {showDialer && (
          <Dialer
            onClose={() => setShowDialer(false)}
            onCall={() => {
              props.call_details.user_number = dialerNumber;
              makeCall(dialerNumber, setIsOnCall, props.call_details,worker).then((res) => {
                setCallSettings(res);
               });
             
              setShowDialer(false);
            }}
            phoneNumber={dialerNumber}
            setPhoneNumber={setDialerNumber}
          />
        )}
      </Wrapper>
    )
  );
};

export default RecordingPlayBack;

const Wrapper = styled.div`
  max-width: 769px;
  height: 189px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #adcfd4;
`;
const Recording = styled.audio`
  flex-shrink: 0;
  border-radius: 20px;
`;
const Heading = styled.span`
  color: #00323b;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;
const DetailsSpan = styled.span`
  color: #fff;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;