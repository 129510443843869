import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PastoralContext } from "./../../../contexts/PastoralContext";
import { Spinner } from "react-activity";
import { ToastContainer } from "react-toastify";
import { PiLessThan } from "react-icons/pi";
import styled from "styled-components";
import "react-activity/dist/library.css";
import "react-toastify/dist/ReactToastify.css";
import { CallFormContext } from "./../../../contexts/CallFormContext";
import { camelCase } from "lodash";
import CallHistory from "./../pages/call-history";
import { RecordingPlayBack } from "./../components/recordingPlayback";
import { makeCall } from "../../../helpers/makeCall";
import { splitFullName } from "../../../helpers/useFunctions";
import {
  callTypes,
  ageRanges,
  followUpTypes,
  maritialStatus,
  risksLevels,
  financialStatus,
  mentalStatus,
} from "./../../../assets/data";
import FollowupScheduler from "../components/modals/followupScheduler";
import { ProfileContext } from "./../../../contexts/ProfileContext";
import { showErrorMessage } from "./../../../helpers/useToast";
import LocationSelector from "../components/locationSelector";
import PastoralCalls from "./pastoralCalls";
import ConfirmDialog from "./modals/ConfirmDialog";
import Dialer from "./dialer";

export const ViewSucideFollowUp = (props) => {
  const { worker, organization } = useContext(ProfileContext);
  const id = props.followUpID;
  const {
    handleCheckboxChange,
    formObj,
    handleSendingResources,
    handleOnSubmit,
    clearForm,
  } = useContext(CallFormContext);
  const {
    setFollowUp,
    followUp,
    updateFollowUp,
    setIsOnCall,
    setCallSettings,
    isOnCall,
  } = useContext(PastoralContext);
  const [updatedFirstName, setUpdatedFirstName] = useState("");
  const [updatedLastName, setUpdatedLastName] = useState("");
  const [updatedEmailAddress, setUpdatedEmailAddress] = useState("");
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");
  const [updatedMailingAddress, setUpdatedMailingAddress] = useState("");
  const [updatedPostalCode, setUpdatedPostalCode] = useState("");
  const [updatedCity, setUpdatedCity] = useState("");
  const [updatedProvince, setUpdatedProvince] = useState("");
  const [updatedCountry, setUpdatedCountry] = useState("");
  const [updatedGender, setUpdatedGender] = useState("");
  const [updatedCallTypes, setUpdatedCalltypes] = useState("");
  const [updatedFollowUpTypes, setUpdatedFollowUpTypes] = useState("");
  const [updatedAgeRange, setUpdatedAgeRange] = useState("");
  const [updatedNotes, setUpdatedNotes] = useState("");
  const [updatePastorNotes, setUpdatePastorNotes] = useState("");
  const [updateTaskSid, setUpdateTaskSid] = useState("");
  //suicide
  const [updatedMaritialStatus, setUpdatedMaritialStatus] = useState("");
  const [updatedRiskLevel, setUpdatedRiskLevel] = useState("");
  const [updatedHarmInflicted, setUpdatedHarmInflicted] = useState("");
  const [updatedHarmInflictedDesc, setUpdatedHarmInflictedDesc] = useState("");
  const [updatedDrugsTaken, setUpdatedDrugsTaken] = useState("");
  const [updatedDrugsTakenDesc, setUpdatedDrugsTakenDesc] = useState("");
  const [updatedPrevSuicideAttempt, setUpdatedPrevSuicideAttempt] =
    useState("");
  const [updatedPrevSuicideAttemptDesc, setUpdatedPrevSuicideAttemptDesc] =
    useState("");
  const [updatedPhysicalHealth, setUpdatedPhysicalHealth] = useState("");
  const [updatedMentalStatus, setUpdatedMentalStatus] = useState("");
  const [updatedFinancialStatus, setUpdatedFinancialStatus] = useState("");
  const [updatedLivingArrangement, setUpdatedLivingArrangement] = useState("");
  const [updatedisDrugAddict, setUpdatedisDrugAddict] = useState("");
  const [updatedIsAntisocial, setUpdatedIsAntisocial] = useState("");
  const [updatedisAlcoholAddict, setUpdatedisAlcoholAddict] = useState("");
  const [updatedLostLovedOne, setUpdatedLostLovedOne] = useState("");
  const [hasCheckin, setHasCheckin] = useState(false);
  const [updatedFollowUpDate, setUpdatedFollowUpDate] = useState(null);
  const [showScheduler, setShowScheduler] = useState(false);
  const [device, setDevice] = useState();
  const [call, setCall] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDialer, setShowDialer] = useState(false);
  const [dialerNumber, setDialerNumber] = useState(followUp.call_details.user_number);


  const [intialStatus, setIntialStatus] = useState("");

  const navigate = useNavigate();
  const changeContent = () => {
    navigate(-1);
  };

  const scheduleFollowUp = (e, status) => {
    e.preventDefault();
    setShowScheduler(true);
    // updateFollowUpStatus(e, 3)
  };

  const handleMakeCall = async (followup) => {
    makeCall(
      followup.call_details.user_number,
      setIsOnCall,
      followup.call_details,
      worker
    ).then((res) => {
      setCallSettings(res);
      setCall(res.call);
      setDevice(res.device);
    });
  };

  const updateFollowUpStatus = (e, status,checkin=false) => {
    if (!updatedGender) {
      showErrorMessage("Please select a Gender.");
      return;
    }
    if (!updatedFollowUpTypes) {
      showErrorMessage("Please select a least one follow up type.");
      return;
    }
    let { firstName, lastName } = splitFullName(formObj.fullName);

    const updatedObj = {
      id,
      status,
      user_number: updatedPhoneNumber,
      call_type: updatedCallTypes,
      notes: updatedNotes,
      caller_first_name: firstName,
      caller_last_name: lastName,
      caller_email: updatedEmailAddress,
      age_range: updatedAgeRange,
      gender: updatedGender,
      address: updatedMailingAddress,
      city: updatedCity,
      province: updatedProvince,
      postal_zip: updatedPostalCode,
      country: updatedCountry,
      pastoral_notes: updatePastorNotes,
      follow_up_type: updatedFollowUpTypes,
      risk_level: updatedRiskLevel,
      harm_Inflicted: updatedHarmInflicted,
      harm_inflicted_desc: updatedHarmInflictedDesc,
      drugs_taken: updatedDrugsTaken,
      drugs_taken_desc: updatedDrugsTakenDesc,
      prev_suicide_attempt: updatedPrevSuicideAttempt,
      prev_suicide_attempt_desc: updatedPrevSuicideAttemptDesc,
      physical_health: updatedPhysicalHealth,
      marital_status: updatedMaritialStatus,
      mental_status: updatedMentalStatus,
      financial_status: updatedFinancialStatus,
      living_arrangement: updatedLivingArrangement,
      is_drug_addict: updatedisDrugAddict,
      is_antisocial: updatedIsAntisocial,
      is_alcohol_addict: updatedisAlcoholAddict,
      lost_loved_one: updatedLostLovedOne,
      requires_checkin:  +checkin
    };
        if (
          updatedFollowUpDate &&
          !isNaN(new Date(updatedFollowUpDate).getTime())
        ) {
          updatedObj.follow_up_date = new Date(updatedFollowUpDate)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ");
        }
      
            if (status === 2)
              updatedObj.closing_date = new Date()
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");

    updateFollowUp(updatedObj).then(() => {
      var redirect = "";
      if (formObj.sendResource === "true") redirect = handleSendingResources();

      if (redirect !== -1) navigate(-1);
    });
  };
  const handleConfirmation= (e,result) => {
    setHasCheckin(result);
  
    if (result) {
      setIsDialogOpen(false);
      setShowScheduler(true);
    } else {
      updateFollowUpStatus(e, 2,result);
    }
  }

  const closeFollowUp = (e, status) => {
    e.preventDefault();
    setIsDialogOpen(true);
  };

  useEffect(() => {
    clearForm();
    const result = props.followUp;
    result.call_details.follow_up_date ??
      setUpdatedFollowUpDate(
        new Date(result.call_details.follow_up_date + " UTC")
      );
    setUpdatedFirstName(result.call_details.caller_first_name);
    setUpdatedLastName(result.call_details.caller_last_name || "");
    setUpdatedPhoneNumber(result.call_details.user_number || "");
    setUpdatedEmailAddress(result.call_details.caller_email || "");
    setUpdatedMailingAddress(result.call_details.address || "");
    setUpdatedCalltypes(result.call_details.call_type || "");
    setUpdatedFollowUpTypes(result.call_details.follow_up_type || "");
    setUpdatedPostalCode(result.call_details.postal_zip || "");
    setUpdatedProvince(result.call_details.province || "");
    setUpdatedCity(result.call_details.city || "");
    setUpdatedCountry(result.call_details.country || "");
    setUpdatedGender(result.call_details.gender || "");
    setUpdatedNotes(result.call_details.notes || "");
    setUpdatedAgeRange(result.call_details.age_range || "");
    setUpdatePastorNotes(result.call_details.pastoral_notes || "");
    setUpdateTaskSid(result.call_details.task_sid);
    setHasCheckin(!!result.call_details.requires_checkin);
    //suicide
    setUpdatedMaritialStatus(result.call_details.marital_status|| "");
    setUpdatedRiskLevel(result.call_details.risk_level|| "");
    setUpdatedHarmInflicted(result.call_details.harm_Inflicted|| "");
    setUpdatedHarmInflictedDesc(result.call_details.harm_inflicted_desc|| "");
    setUpdatedDrugsTaken(result.call_details.drugs_taken|| "");
    setUpdatedDrugsTakenDesc(result.call_details.drugs_taken_desc|| "");
    setUpdatedPrevSuicideAttempt(result.call_details.prev_suicide_attempt|| "");
    setUpdatedPrevSuicideAttemptDesc(
      result.call_details.prev_suicide_attempt_desc|| ""
    );
    setUpdatedPhysicalHealth(result.call_details.physical_health|| "");
    setUpdatedMentalStatus(result.call_details.mental_status|| "");
    setUpdatedFinancialStatus(result.call_details.financial_status|| "");
    setUpdatedLivingArrangement(result.call_details.living_arrangement|| "");
    setUpdatedisDrugAddict(result.call_details.is_drug_addict|| "");
    setUpdatedIsAntisocial(result.call_details.is_antisocial|| "");
    setUpdatedisAlcoholAddict(result.call_details.is_alcohol_addict|| "");
    setUpdatedLostLovedOne(result.call_details.lost_loved_one|| "");

    formObj.setFullName(
      result.call_details.caller_first_name +
        " " +
        result.call_details.caller_last_name
    );
    setFollowUp(result);
    formObj.setUserNumber(result.call_details.user_number);
    setUpdatedFollowUpDate(
      result.call_details.follow_up_date &&
        new Date(result.call_details.follow_up_date).toLocaleDateString("en-US")
    );
    formObj.setTaskSID(result.call_details.task_sid);
    formObj.setTwilioNumber(
      process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test" && organization
        ? organization.assignedNumber
        : process.env.REACT_APP_TWILIO_NUMBER
    );
  }, [id]);

  return (
    <div>
      <FollowupScheduler
        setShow={setShowScheduler}
        show={showScheduler}
        selectedDate={updatedFollowUpDate}
        setUpdatedFollowUpDate={setUpdatedFollowUpDate}
        updateRecord={(e) => {
          updateFollowUpStatus(e, hasCheckin?2:3,hasCheckin);
        }}
        onHide={(e) => {
          setShowScheduler(false);
        }}
      />
       <ConfirmDialog
      show={isDialogOpen}
        onHide={()=>setIsDialogOpen(false)}
        title="Schedule check-in"
        message="Do you want to schedule a Wellness check?"
        onConfirm={handleConfirmation}
        
      />
         {showDialer && (
          <Dialer
            onClose={() => setShowDialer(false)}
            onCall={() => {
              followUp.call_details.user_number = dialerNumber;
              makeCall(dialerNumber, setIsOnCall, followUp.call_details,worker).then((res) => {
                setCallSettings(res);
                
               });
             
              setShowDialer(false);
            }}
            phoneNumber={dialerNumber}
            setPhoneNumber={setDialerNumber}
          />
        )}
      {followUp && !followUp.error ? (
        <div className="grid gap-4 grid-cols-1">
          {/* NAVIGATION BACK TO THE LIST PAGE */}
          <BackLinkContainer className="p-3 bg-transparent flex align-middle">
            <button
              onClick={() => {
                changeContent(
                  followUp.call_details.status === "1"
                    ? "NewFollowUp"
                    : followUp.call_details.status === "2"
                    ? "CompletedFollowUp"
                    : "OngoingFollowUp"
                );
              }}
              style={{ background: "none", border: "0" }}
            >
              <PiLessThan size={25} />
            
            <TableHeading className="text-metallicSeaweed2 flex pl-3 align-self-center">
            
              BACK TO
              {followUp.call_details.status === "1"
                ? " NEW "
                : followUp.call_details.status === "2"
                ? " COMPLETED "
                : " ONGOING "}
              FOLLOW-UPS
            </TableHeading>
            </button>
          </BackLinkContainer>

          <ToastContainer />

          <Wrapper className="  mx-5">
            <Header className="p-3 ">{"SUICIDE FOLLOW-UP"}</Header>
            <Form
              onSubmit={handleOnSubmit}
              id="callForm"
              className="flex flex-column"
              autoComplete="off"
            >
              <div className="grid gap-4 grid-cols-1">
                <CallerInfoWrapper className="p-4">
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="fullName">
                        <Labelspan>NAME</Labelspan>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        onChange={(e) => formObj.setFullName(e.target.value)}
                        value={formObj.fullName}
                        disabled={followUp.call_details.status === "2"}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="maritalStatus">
                        <Labelspan>MARITIAL STATUS</Labelspan>
                      </label>
                      <br />
                      {maritialStatus.map((status) => {
                        return (
                          <div
                            className="form-check form-check-inline"
                            key={status.id}
                          >
                            <input
                              className="form-check-input"
                              name="maritalStatus"
                              type="radio"
                              value={status.status}
                              onChange={(e) =>
                                setUpdatedMaritialStatus(e.target.value)
                              }
                              checked={updatedMaritialStatus === status.status}
                              disabled={followUp.call_details.status === "2"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={status.status}
                            >
                              {status.status}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="emailAddress">
                        <Labelspan>EMAIL</Labelspan>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="emailAddress"
                        onChange={(e) => setUpdatedEmailAddress(e.target.value)}
                        value={updatedEmailAddress}
                        disabled={followUp.call_details.status === "2"}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="phoneNumber">
                        <Labelspan>
                          {" "}
                          PHONE NUMBER<Redish>*</Redish>
                        </Labelspan>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        onChange={(e) => setUpdatedPhoneNumber(e.target.value)}
                        value={updatedPhoneNumber}
                        disabled={followUp.call_details.status === "2"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col form-check-inline">
                      <label htmlFor="ageRange">
                        <Labelspan>
                          AGE RANGE<Redish>*</Redish>
                        </Labelspan>
                      </label>
                      <select
                        className="form-select mb-2"
                        aria-label="Age Range List"
                        id="ageRangeList"
                        value={updatedAgeRange}
                        onChange={(e) => setUpdatedAgeRange(e.target.value)}
                        disabled={followUp.call_details.status === "2"}
                      >
                        <option value="">Choose Age Range</option>
                        {ageRanges.map((ageRange) => {
                          return (
                            <option
                              key={ageRange.id + ageRange.range}
                              value={ageRange.range}
                            >
                              {ageRange.range}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col">
                      <label htmlFor="gender">
                        <Labelspan>
                          GENDER<Redish>*</Redish>
                        </Labelspan>
                      </label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="gender"
                          type="radio"
                          checked={updatedGender === "male"}
                          value="male"
                          onChange={(e) => setUpdatedGender(e.target.value)}
                          disabled={followUp.call_details.status === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="maleGender"
                        >
                          Male
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="gender"
                          type="radio"
                          checked={updatedGender === "female"}
                          value="female"
                          onChange={(e) => setUpdatedGender(e.target.value)}
                          disabled={followUp.call_details.status === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="femaleGender"
                        >
                          Female
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="gender"
                          type="radio"
                          checked={updatedGender === "prefer not to say"}
                          value="prefer not to say"
                          onChange={(e) => setUpdatedGender(e.target.value)}
                          disabled={followUp.call_details.status === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="notKnownGender"
                        >
                          Prefer not to say
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <div className="col">
                      <label htmlFor="address">
                        <Labelspan>STREET ADDRESS</Labelspan>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        onChange={(e) =>
                          setUpdatedMailingAddress(e.target.value)
                        }
                        value={updatedMailingAddress}
                        disabled={followUp.call_details.status === "2"}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="postalCode">
                        <Labelspan>
                          {" "}
                          POSTAL / ZIP CODE<Redish>*</Redish>
                        </Labelspan>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postalCode"
                        onChange={(e) => setUpdatedPostalCode(e.target.value)}
                        value={updatedPostalCode}
                        disabled={followUp.call_details.status === "2"}
                      />
                    </div>
                  </div>
                  <div className="row form-group mb-2">
                    <LocationSelector
                      showCity={true}
                      updatedCity={updatedCity}
                      setUpdatedCity={setUpdatedCity}
                      updatedProvince={updatedProvince}
                      setUpdatedProvince={setUpdatedProvince}
                      updatedCountry={updatedCountry}
                      setUpdatedCountry={setUpdatedCountry}
                      isDisabled={followUp.call_details.status === "2"}
                    ></LocationSelector>
                    <div className="col"></div>
                  </div>
                </CallerInfoWrapper>

                {/* CALL RECORDING SECTION */}
                <div className="flex justify-self-center">
                  {followUp && (
                    <RecordingPlayBack
                      username={
                        updatedFirstName || updatedLastName
                          ? updatedFirstName + " " + updatedLastName
                          : "Anonymous Caller"
                      }
                      playback={followUp.call_stats}
                      call_details={followUp.call_details}
                    />
                  )}
                </div>

                {/* CALL HISTORY SECTION  */}
                {updatedPhoneNumber && (
                  <CallHistory
                    userNumber={updatedPhoneNumber}
                    className="mx-0"
                  />
                )}
                {/* follow up calls  */}
                {updateTaskSid && <PastoralCalls task_sid={updateTaskSid} />}

                <CallTypeWrapper className="p-4 pt-5 gap-10 justify-evenly flex flex-row">
                  <CallTypes className=" w-auto">
                    <Labelspan>
                      CALL TYPES<Redish>*</Redish>
                    </Labelspan>
                    <div className="overflow-visible">
                      {callTypes.map((callType) => {
                        return (
                          <div
                            key={callType.id + callType.type}
                            className={"form-check mb-1"}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={callType.id + callType.type}
                              checked={updatedCallTypes
                                .split(", ")
                                .some((item) => {
                                  return item === callType.type;
                                })}
                              onChange={(e) => {
                                handleCheckboxChange(
                                  e,
                                  setUpdatedCalltypes,
                                  updatedCallTypes,
                                  callType.type
                                );
                              }}
                              disabled={followUp.call_details.status === "2"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={callType.id + callType.type}
                            >
                              {callType.type}
                              {callType.children && <Blueish> {"> "}</Blueish>}
                            </label>
                            {callType.children &&
                              callType.children.length > 0 &&
                              (updatedCallTypes
                                .split(",")
                                .includes(callType.type) ||
                                updatedCallTypes.includes(callType.type)) && (
                                <div
                                  style={{
                                    marginLeft: "20px",
                                    margin: "5px",
                                  }}
                                  className=""
                                >
                                  {callType.children.map(
                                    (child, childIndex) => (
                                      <div
                                        className="ml-3"
                                        key={`${callType.id}-${childIndex}`}
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          onChange={(e) => {
                                            handleCheckboxChange(
                                              e,
                                              setUpdatedCalltypes,
                                              updatedCallTypes,
                                              callType.type + " - " + child
                                            );
                                          }}
                                          disabled={followUp.call_details.status === "2"}
                                        />
                                        <label
                                          key={`${callType.id}-${childIndex}`}
                                          className="block"
                                        >
                                          {child}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                          </div>
                        );
                      })}
                    </div>
                  </CallTypes>

                  <CallNotes className="w-full ">
                    <label htmlFor="callNotes">
                      <Labelspan>CALL NOTES</Labelspan>
                    </label>
                    <textarea
                      className="form-control"
                      id="callNotes"
                      rows="6"
                      onChange={(e) => setUpdatedNotes(e.target.value)}
                      value={updatedNotes}
                      disabled={followUp.call_details.status === "2"}
                    ></textarea>
                  </CallNotes>

                  <CallNotes className=" w-full">
                    <label htmlFor="pastoralNotes">
                      <Labelspan>PASTORAL NOTES</Labelspan>
                    </label>
                    <textarea
                      className="form-control"
                      id="pastoralNotes"
                      rows="6"
                      onChange={(e) => setUpdatePastorNotes(e.target.value)}
                      value={updatePastorNotes}
                      disabled={followUp.call_details.status === "2" && !followUp.call_details.requires_checkin}
                    ></textarea>
                  </CallNotes>
                </CallTypeWrapper>

                <FolowUpTypeWrapper className=" mt-4 p-4 bg-[#FAF1EF]">
                  <FolowUpTypes className="row pl-4 ">
                    <Labelspan>
                      FOLLOW UP TYPE<Redish>*</Redish>
                    </Labelspan>
                    {followUpTypes.map((followUpType) => {
                      return (
                        <div
                          key={followUpType.id}
                          className={
                            "col-md-6 form-check mb-1 order-col-" +
                            followUpType.id
                          }
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={followUpType.id + followUpType.type}
                            checked={updatedFollowUpTypes
                              .split(", ")
                              .some((item) => {
                                return item === followUpType.type;
                              })}
                            onChange={(e) => {
                              handleCheckboxChange(
                                e,
                                setUpdatedFollowUpTypes,
                                updatedFollowUpTypes,
                                followUpType.type
                              );
                            }}
                            disabled={followUp.call_details.status === "2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={followUpType.id + followUpType.type}
                          >
                            {followUpType.type}
                          </label>
                        </div>
                      );
                    })}
                  </FolowUpTypes>
                </FolowUpTypeWrapper>

                <div>
                  <label className="row">
                    <Labelspan>PREVIOUS RESOURCES SENT</Labelspan>
                  </label>
                  <SpanContainer style={{ width: "35%" }}>
                    {followUp.call_resources.map((resource, index) => {
                      var name = resource.title;
                      return (
                        <span
                          key={index}
                          className={camelCase(name) + " p-1 m-2"}
                        >
                          {resource.title}
                        </span>
                      );
                    })}
                  </SpanContainer>
                </div>

                <SuicideDiv className="row mx-0">
                  <h4 className="pt-3 pb-3">
                    <Blueish> CIRCUMSTANCES: ONE </Blueish>
                    <Redish>
                      <u>"YES"</u>
                    </Redish>{" "}
                    <Blueish>ANSWER REQUIRED FOR THIS FORM:</Blueish>
                  </h4>
                  <div className="col form-check m-3 mb-0 mt-0">
                    <label className="form-check-label" htmlFor="harmInflicted">
                      Harm has been inflicted?
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="harmInflicted"
                      checked={updatedHarmInflicted === 1}
                      onChange={(e) =>
                        setUpdatedHarmInflicted(+e.target.checked)
                      }
                      disabled={followUp.call_details.status === "2"}
                    />
                  </div>
                  <div className="col form-check">
                    <label className="form-check-label" htmlFor="drugsTaken">
                      Has Taken Drugs?
                    </label>

                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="drugsTaken"
                      checked={updatedDrugsTaken === 1}
                      onChange={(e) => setUpdatedDrugsTaken(+e.target.checked)}
                      disabled={followUp.call_details.status === "2"}
                    />
                  </div>
                  <div className="row form-group mb-2 mt-2">
                    <div className="col">
                      <label htmlFor="harmInflictedDesc">
                        Harm Inflicted Description
                      </label>
                      <textarea
                        className="form-control"
                        id="harmInflictedDesc"
                        rows="3"
                        cols="2"
                        onChange={(e) =>
                          setUpdatedHarmInflictedDesc(e.target.value)
                        }
                        value={updatedHarmInflictedDesc}
                        disabled={followUp.call_details.status === "2"}
                      ></textarea>
                    </div>
                    <div className="col">
                      <label htmlFor="drugsTakenDesc">
                        Drugs Taken Description
                      </label>
                      <textarea
                        className="form-control"
                        id="drugsTakenDesc"
                        rows="3"
                        onChange={(e) =>
                          setUpdatedDrugsTakenDesc(e.target.value)
                        }
                        value={updatedDrugsTakenDesc}
                        disabled={followUp.call_details.status === "2"}
                      ></textarea>
                    </div>
                  </div>
                </SuicideDiv>

                <SuicideDiv className="row mx-0">
                  <h4 className="pt-3 pb-3">
                    <Blueish> SUICIDAL INDICATORS </Blueish>
                  </h4>
                  <div className="col form-check m-3 mb-0 mt-0">
                    <input
                      type="checkbox"
                      className="form-check-input "
                      id="prevSuicideAttempts"
                      checked={updatedPrevSuicideAttempt === 1}
                      onChange={(e) =>
                        setUpdatedPrevSuicideAttempt(+e.target.checked)
                      }
                      disabled={followUp.call_details.status === "2"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="prevSuicideAttempts"
                    >
                      Has previous suicide attempts?
                    </label>
                  </div>
                  <CallNotes className="form-group mt-4 p-2">
                    <label htmlFor="suicidePlan">
                      Suicide Plan: vague, general, practical, concrete,
                      available mean;
                      <br />
                      <small>
                        <Blueish>
                          {" "}
                          Method Used: Less lethal(wrist cutting, pills), Highly
                          lethal(firearms, poison, hanging gas, jumping)
                        </Blueish>
                      </small>{" "}
                    </label>
                    <textarea
                      className="form-control"
                      id="suicidePlan"
                      rows="3"
                      onChange={(e) =>
                        setUpdatedPrevSuicideAttemptDesc(e.target.value)
                      }
                      value={updatedPrevSuicideAttemptDesc}
                      disabled={followUp.call_details.status === "2"}
                    ></textarea>
                  </CallNotes>
                </SuicideDiv>

                <SuicideDiv className="p-3 row mx-0">
                  <h4 className="pt-3 pb-2">
                    <Blueish> MEDICAL HISTORY </Blueish>
                  </h4>
                  {followUp.call_details.physical_health && (
                    <div className="col form-check">
                      <label htmlFor="gender">Physical Health:</label>
                      <br />
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="goodHealth"
                          type="radio"
                          checked={updatedPhysicalHealth === "GOOD"}
                          value="GOOD"
                          onChange={(e) =>
                            setUpdatedPhysicalHealth(e.target.value)
                          }
                          disabled={followUp.call_details.status === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="goodHealth"
                        >
                          GOOD
                        </label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          name="poorHealth"
                          type="radio"
                          checked={updatedPhysicalHealth === "POOR"}
                          value="POOR"
                          onChange={(e) =>
                            setUpdatedPhysicalHealth(e.target.value)
                          }
                          disabled={followUp.call_details.status === "2"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="poorHealth"
                        >
                          POOR
                        </label>
                      </div>
                      <br />
                    </div>
                  )}

                  {followUp.call_details.mental_status && (
                    <div className="row mt-2 m-2 mb-0">
                      <div className="col-12">
                        <label htmlFor="mentalStatus">
                          Current Mental Status
                        </label>
                        <br />
                        {mentalStatus.map((ment) => {
                          return (
                            <div
                              className="form-check form-check-inline"
                              key={ment.id}
                            >
                              <input
                                className="form-check-input"
                                name="mentalStatus"
                                type="checkbox"
                                checked={updatedMentalStatus
                                  .split(", ")
                                  .some((item) => {
                                    return item === ment.status;
                                  })}
                                value={ment.status}
                                onChange={(e) => {
                                  handleCheckboxChange(
                                    e,
                                    setUpdatedMentalStatus,
                                    updatedMentalStatus,
                                    ment.status
                                  );
                                }}
                                disabled={followUp.call_details.status === "2"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={ment.status}
                              >
                                {ment.status}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </SuicideDiv>

                <SuicideDiv className="p-3 row mx-0">
                  <h4 className="pt-3 pb-2">
                    <Blueish> SOCIAL HISTORY </Blueish>
                  </h4>
                  <div className="col form-check">
                    <label htmlFor="gender">Type of Neighbourhood:</label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="goodHood"
                        type="radio"
                        checked={
                          updatedLivingArrangement &&
                          updatedLivingArrangement.includes("Good")
                        }
                        value="Good"
                        onChange={(e) => {
                          var values = updatedLivingArrangement.split(" ");
                          values[0] = e.target.value;
                          setUpdatedLivingArrangement(values.join(" "));
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <label className="form-check-label" htmlFor="goodHood">
                        Good
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="poorHood"
                        type="radio"
                        checked={
                          updatedLivingArrangement &&
                          updatedLivingArrangement.includes("Poor")
                        }
                        value="Poor"
                        onChange={(e) => {
                          var values = updatedLivingArrangement.split(" ");
                          values[0] = e.target.value;
                          setUpdatedLivingArrangement(values.join(" "));
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <label className="form-check-label" htmlFor="poorHood">
                        Poor
                      </label>
                    </div>
                  </div>
                  <div className="col form-check">
                    <label htmlFor="gender">Living Arrangements:</label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="aloneType"
                        type="radio"
                        checked={
                          updatedLivingArrangement &&
                          updatedLivingArrangement.includes("Alone")
                        }
                        value="Alone"
                        onChange={(e) => {
                          var values = updatedLivingArrangement.split(" ");
                          values[1] = e.target.value;
                          setUpdatedLivingArrangement(values.join(" "));
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <label className="form-check-label" htmlFor="aloneType">
                        Alone
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        name="othersType"
                        type="radio"
                        checked={
                          updatedLivingArrangement &&
                          updatedLivingArrangement.includes("With Others")
                        }
                        value="With Others"
                        onChange={(e) => {
                          var values = updatedLivingArrangement.split(" ");
                          values[1] = e.target.value;
                          setUpdatedLivingArrangement(values.join(" "));
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <label className="form-check-label" htmlFor="othersType">
                        With Others
                      </label>
                    </div>
                  </div>
                  <div className="col form-check">
                    <label
                      className="form-check-label pb-1"
                      htmlFor="antiSocial"
                    >
                      Antisocial Behaviour or History
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="antiSocial"
                      checked={updatedIsAntisocial === 1}
                      onChange={(e) =>
                        setUpdatedIsAntisocial(+e.target.checked)
                      }
                      disabled={followUp.call_details.status === "2"}
                    />
                  </div>

                  <div className="row mt-2 m-2 mb-0">
                    <div className="col form-check">
                      <label
                        className="form-check-label pb-1"
                        htmlFor="alcoholism"
                      >
                        Is Alcoholic
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="alcoholism"
                        checked={updatedisAlcoholAddict === 1}
                        onChange={(e) => {
                          setUpdatedisAlcoholAddict(+e.target.checked);
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <br />
                    </div>
                    <div className="col form-check">
                      <label
                        className="form-check-label pb-1"
                        htmlFor="hasDrugAddiction"
                      >
                        Has Drug Addiction
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="hasDrugAddiction"
                        checked={updatedisDrugAddict === 1}
                        onChange={(e) => {
                          setUpdatedisDrugAddict(+e.target.checked);
                        }}
                        disabled={followUp.call_details.status === "2"}
                      />
                      <br />
                    </div>

                    <div className="col form-check">
                      <label htmlFor="lossLovedOne">
                        Loss of Loved One (actual, threatened, or imagined)
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="lossLovedOne"
                        checked={updatedLostLovedOne === 1}
                        onChange={(e) =>
                          setUpdatedLostLovedOne(+e.target.checked)
                        }
                        disabled={followUp.call_details.status === "2"}
                      />
                      <br />
                    </div>
                  </div>
                  <div className=" mt-2 m-2 mb-0">
                    <label htmlFor="financialResource">
                      Financial Resource
                    </label>
                    <br />
                    {financialStatus.map((finance) => {
                      return (
                        <div
                          className="form-check form-check-inline"
                          key={finance.id}
                        >
                          <input
                            className="form-check-input"
                            name="financialStatus"
                            type="checkbox"
                            checked={
                              updatedFinancialStatus &&
                              updatedFinancialStatus
                                .split(", ")
                                .some((item) => {
                                  return item === finance.status;
                                })
                            }
                            onChange={(e) => {
                              handleCheckboxChange(
                                e,
                                setUpdatedFinancialStatus,
                                updatedFinancialStatus,
                                finance.status
                              );
                            }}
                            disabled={followUp.call_details.status === "2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={finance.status}
                          >
                            {finance.status}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </SuicideDiv>

                <SuicideDiv className="row p-3 mx-0 ">
                  <p className="text-center">
                    <Redish>
                      <strong>
                        PERCEIVED LEVEL OF RISK (FROM 1-10; 10 BEING THE
                        HIGHEST)
                      </strong>
                    </Redish>
                  </p>
                  <div className="row px-5 mx-3">
                    {risksLevels.map((level) => {
                      return (
                        <div
                          className=" col form-check form-check-inline"
                          key={level.id}
                        >
                          <input
                            className="form-check-input"
                            name="riskLevel"
                            type="radio"
                            checked={
                              updatedRiskLevel &&
                              updatedRiskLevel.toString() === level.level
                            }
                            value={level.level}
                            onChange={(e) =>
                              setUpdatedRiskLevel(e.target.value)
                            }
                            disabled={followUp.call_details.status === "2"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={level.level}
                          >
                            {level.level}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </SuicideDiv>
              </div>
            </Form>
          </Wrapper>

          {/* PASTORAL FORM */}
          <Form id="callForm" autoComplete="off">
          <ButtonWrapper className="mb-5">
             {(followUp.call_details.status !== "2" ||( followUp.call_details.status === "2" && hasCheckin)) && (
                  <CallButton
                    onClick={(e) => setShowDialer(true)}
                    className="p-2 m-2"
                    disabled={isOnCall}
                  >
                    CALL BACK NOW
                  </CallButton>
             )}
 {(followUp.call_details.status !== "2") && (
    <>
                  <UpdateButton
                    onClick={(e) => scheduleFollowUp(e, 2)}
                    className="p-2 m-2"
                  >
                    SCHEDULE FOLLOW UP
                  </UpdateButton>
    </>
              )}
{(followUp.call_details.status !== "2" ||( followUp.call_details.status === "2" && hasCheckin)) && (                 
   <>
   <UpdateButton
                    onClick={(e) =>
                      updateFollowUpStatus(e, followUp.call_details.status,hasCheckin)
                    }
                    className="p-2 m-2"
                  >
                    UPDATE FOLLOW UP
                  </UpdateButton>
              
                  
                  <CompleteButton
                    onClick={(e) => {
                      closeFollowUp(e, 2);
                      //navigate(-1);
                    }}
                    className="p-2 m-2"
                  >
                    CLOSE FOLLOW UP
                  </CompleteButton>
                  </>
                  )}
              
            </ButtonWrapper>
          </Form>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
export default ViewSucideFollowUp;
const DigitalResources = styled.div``;
const PhysicalResources = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PastorCareWrapper = styled.div``;
const Labelspan = styled.span`
  color: #000;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const Form = styled.div``;
const BackLinkContainer = styled.div`
  button {
    color: #178b96;
    font-weight: 700;
    display: flex;
    font-family: 14px;
    letter-spacing: 0.2em;
    font-size: 0.9em;
    text-decoration: none;
  }
  svg {
    vertical-align: middle;
  }
`;
const CallerInfoWrapper = styled.div``;
const SpanContainer = styled.div`
  display: inline;
  span {
    background-color: #115057;
    color: white;
    border-radius: 8px;
    display: inline-block;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .disabled {
    background-color: grey;
  }
`;
const Button = styled.button`
  border-radius: 8px;
  display: block;
  border: 0;
  width: 70%;
  color: white;
`;

const CallButton = styled(Button)`
  background-color: #ff7234;
`;

const UpdateButton = styled(Button)`
  background-color: #115057;
`;
const CompleteButton = styled(Button)`
  background-color: #40d13d;
`;

const CloseButton = styled(Button)`
  background-color: #e7f8fa;
  color: #2db3c0;
`;

const CallTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #f3f9fa;
`;

const CallTypes = styled.div``;

const CallNotes = styled.div``;
const Redish = styled.span`
  color: #ec3310 !important;
`;
const FolowUpTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FolowUpTypes = styled.div``;

const SendViaWrapper = styled.div`
  border: 1px solid #2db3c0;
`;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TypeCell = styled.td`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
`;
const Table = styled.table`
  width: 100%;
`;
const Th = styled.th`
  text-align: center;
`;
const Header = styled.span`
  background-color: #ff7234;
  color: white;
  display: block;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
export const Blueish = styled.span`
  color: #178b96 !important;
`;
const FollowUpWrapper = styled.div``;

const SuicideDiv = styled.div`
  h4 {
    text-align: left;
  }
  border-top: 1px solid #178b96;
  label {
    font-weight: 500;
    // color: #EC3310;
  }
  input[type="text"]:focus,
  input[type="text"] {
    // border: 1px solid #EC3310 !important;
  }
`;
