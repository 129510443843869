import React from 'react';
import SearchComponent from './searchBar';
import FilterButton from './filterButton';
import styled from 'styled-components';
import ExportButton from './exportButton'
export const FollowUpFilter = (props) => {
    return <FilterRow className="gap-2  mx-5">
    
        <SearchComponent context={props.context}/>
        <FilterButton
        context={props.context}
        showCallerName= {props.showCallerName }
        showCallerUserNmber =   {props.showCallerUserNmber }
        showAgentName =      { props.showAgentName }
        showFollowupType= {props.showFollowupType}
        showDateRange=   {props.showDateRange}
                  />
         {props.exportButton && <ExportButton menuOptions={props.menuOptions}/> }
    </FilterRow>
}
export default FollowUpFilter;

const FilterRow = styled.div`
display:flex;
flex-flow:row;
float:right;
`;