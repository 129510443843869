import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import CustomPDF from "../fullfillment/letters";
import { MailingContext } from "../../../../contexts/MailingContext";
import { showErrorMessage, showSuccessMessage } from "../../../../helpers/useToast";
import { Navigate } from "react-router-dom";

export default function Fulfillment({ show, onHide, selectedResource, resource, pdfs,changeContent }) {
  const [selectedResources, setSelectedResources] = useState([]);
  const [brochures, setBrochures] = useState([]);
  const { updateResource,updateInventory } = useContext(MailingContext);

  // Initialize selected resources from the given resourceTitles
  useEffect(() => {
    if (Array.isArray(selectedResource)) {
      setSelectedResources(selectedResource);
    } else {
      console.warn("selectedResource is not an array");
      setSelectedResources([]); // Fallback to an empty array
    }

    setBrochures(resource?.brochures ? resource.brochures.split(",") : []);
  }, [selectedResource, resource]);


  // Log updates in selectedResources
  useEffect(() => {
    console.log("Updated resources:", selectedResources);
  }, [selectedResources]);

  // Separate filtered lists
  const anchorResources = selectedResources.filter((r) => r.distributor !== "internal");
  const internalResources = selectedResources.filter((r) => r.distributor === "internal");

  // Confirm selection
  const onConfirm = async() => {
    try{
    const updateObj ={
      status: "sent",
      fullfillment_date:new Date().toISOString().slice(0, 19).replace("T", " ")
    };
    
    if (anchorResources.length > 0){
      updateObj.external_title= anchorResources.map((r) => r.resource).join(",");
      updateObj.external_record= "1";  
    }
    
        internalResources.map((resource) => {
         updateInventory(resource.id,{quantityOnHold:'1' });
        })
    
        const response = await updateResource(resource.id,updateObj );
                
              showSuccessMessage(response?.original?.message + 'And is ready for shipping.' || "No message available")
              setTimeout(() => {
                changeContent();
              }, 2000);
            } catch (error) {
              showErrorMessage("Error while updating")
              console.error("Error fetching data:", error);
            }
    onHide(false);
  };

  return (
    <Modal show={show} onHide={() => onHide(false)} centered dialogClassName="modal-90w">
      <StyledModalHeader closeButton>
        <Modal.Title className="font-space">SHIPPING CONFIRMATION</Modal.Title>
      </StyledModalHeader>
      <Modal.Body className="flex justify-center bg-[#F8F3F2] p-4 flex-row border-b-2 border-b-[#ADCFD4] justify-items-start mx-5">
        {/* Left Section */}
        <div className="w-1/2 flex flex-col px-5">
          {anchorResources.length > 0 && (
            <>
              <span>ANCHOR RESOURCES</span>
              <ItemContainer>
                {anchorResources.map((resource, index) => (
                  <Item key={resource.id || index} className={index % 2 === 0 ? "even" : "odd"}>
                    {resource.resource}
                  </Item>
                ))}
              </ItemContainer>
            </>
          )}

          {pdfs.length > 0 && (
            <>
              <span>LETTERS:</span>
              <ItemContainer>
                {pdfs.map((pdf, index) => (
                  <Item key={index} className={index % 2 === 0 ? "even" : "odd"}>
                    {pdf}
                  </Item>
                ))}
              </ItemContainer>
              
            </>
          )}
        </div>

        {/* Right Section */}
        <div className="w-1/2 flex flex-col px-5">
          <span>PUBLICATIONS:</span>
          <ItemContainer>
            {internalResources.map((resource, index) => (
              <Item key={resource.id || index} className={index % 2 === 0 ? "even" : "odd"}>
                {resource.resource}
              </Item>
            ))}
          </ItemContainer>
        {brochures.length > 0 && <>
          <span>PRINT OUTS:</span>
          <ItemContainer>
            {brochures.map((title, index) => (
              <Item key={index} className={index % 2 === 0 ? "even" : "odd"}>
                {title}
              </Item>
            ))}
          </ItemContainer>
          </>}
        </div>
      </Modal.Body>
      <StyledModalFooter className="gap-5 pb-10 flex-row">
      {pdfs.length > 0 && <CustomPDF   letters={pdfs} request={resource} />}
        <button className="bg-marina text-white border-r-5 px-20 py-2 font-space rounded-lg" onClick={onConfirm}>
          CONFIRM
        </button>
        <button className="bg-ikura text-white border-r-5 px-20 py-2 font-space rounded-lg" onClick={() => onHide(false)}>
          CANCEL
        </button>
      </StyledModalFooter>
    </Modal>
  );
}

// Styled components
const StyledModalHeader = styled(Modal.Header)`
  background-color: #00323b;
  color: white;
  border-bottom: 1px solid #00323b;
  font-family: Space Grotesk;
  font-size: 14px;
`;

const StyledModalFooter = styled(Modal.Footer)`
  border: 0;
  justify-content: center;
  background-color: #f8f3f2;
  padding-bottom: 2.5rem;
`;

const ItemContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Item = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  width: 100%;
  height: 24px;
  padding: 9px 19px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
 
  font-family: "Futura PT";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 3.5px;


  &.odd {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #2db3c0;
    background: #fff;
    color: #2db3c0;
    &:before {
      background-color: #2db3c0;
    }
  }
  &.even {
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #ff7234;
    background: #fff;
    color: #ff7234;
    &:before {
      background-color: #ff7234;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 50%;
    width: 12px;
    height: 24px;
    transform: translateY(-50%);
  }
`;
