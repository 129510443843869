import React, { useContext, useEffect, useState } from "react";
import { PastoralContext } from "../../../contexts/PastoralContext";
import styled from "styled-components";
import { Icon } from "@mui/material";
import { BiSortAlt2 } from "react-icons/bi";
import FollowUpFilter from "../components/followUpFilter";
import "./../styles/PastoralCare.css";
import { FiRepeat } from "react-icons/fi";
import PastoralPagination from "../components/pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";


export const FollowUpList = (props) => {

  const { getFollowUps,searchValue,setSort,sortOrder } = useContext(PastoralContext);
  const [ followUps,  setFollowUps ]= useState(null);
  const status = props.Status;
  const itemsPerPage = 10;
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(searchParams.get('page')) || 1;
  });
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchParams({ page: pageNumber });
  };

  const handleNav = (id) => {
    const currentUrl = window.location.href;
    const substrings =['new','ongoing','completed','check-in'];

    if (substrings.some(substring => currentUrl.includes(substring))) {
      navigate(`../${id}?returnPage=${currentPage}`, { relative: "path" });
    } else {
      navigate(`./${id}?returnPage=${currentPage}`, { relative: "path" });
    }
    
    
  };

  useEffect(() => {
    setIsLoading(true);

    getFollowUps(status, currentPage)
      .then((data) => {
          setFollowUps(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching follow-ups:", error);
        setIsLoading(false);
      });
  }, [currentPage, searchValue,sortOrder]);

  useEffect(() => {
    const returnPage = searchParams.get('returnPage');
    if (returnPage) {
      setCurrentPage(parseInt(returnPage));
      setSearchParams({ page: returnPage });
    }
  }, [searchParams]);

  return (
    <>
    <ToastContainer/>
      <FollowUpFilter 
      context={PastoralContext}
      showCallerName= {true }
      showCallerUserNmber =   {true }
      showAgentName =      {true }
      showFollowupType={true }
      showDateRange=  {true }
      />
      <Wrapper className=" m-5 px-3 ">
        <Header className="p-3 mt-4">
          <TableHeading>
            OVERVIEW OF{" "}
            {status === "new"
              ? "NEW "
              : status === "completed"
              ? "COMPLETED"
              : status === "checkin"
              ?"CHECK-IN"
              : "ONGOING"}{" "}
            FOLLOW-UPS
          </TableHeading>
          <span></span>
        </Header>
        { (!isLoading) ? (
          <>
    
            <Table>
              <thead>
                <TableHeaderRow>
                  <Th >
                    {status === "new"
                      ? "Creation"
                      : status === "completed"
                      ? "Closing"
                      : "Follow-Up"}
                    Date
                    <Icon >
                      <BiSortAlt2 onClick={()=>{setSort(status === "new"?'newDate':'followupDate');}} />
                    </Icon>
                  </Th>
                  <Th> Caller Name</Th>
                  <Th> Phone Number</Th>
                  <Th>
                    {" "}
                    Follow-Up Type{" "}
                    <Icon>
                      <BiSortAlt2  onClick={()=>{setSort('followupType');}}/>
                    </Icon>
                  </Th>
                  <Th>
                    {" "}
                    Prayer Partner{" "}
                    <Icon>
                      <BiSortAlt2  onClick={()=>setSort('partner')}/>
                    </Icon>
                  </Th>
                </TableHeaderRow>
              </thead>
              <tbody>
                {followUps.data.map((followUp) => {
                  return (
                    <TableRow
                      onClick={() => {
                        handleNav(followUp.id);
                      }}
                      key={followUp.id}
                    >
                      <td className="p-3 text-center">
                        {status === "new"
                          ? new Date(followUp.created_at).toLocaleDateString(
                              "en-US"
                            )
                          : (status === "completed" && followUp.closing_date)?
                          
                            new Date(
                              followUp.closing_date +' UTC'
                            ).toLocaleDateString("en-US")
                          
                          :followUp.follow_up_date &&
                            new Date(
                              followUp.follow_up_date +' UTC'
                            ).toLocaleDateString("en-US")}
                      </td>
                      <td className="text-center align-middle  gap-1 ">

                      <div className="flex items-center justify-center gap-1 capitalize ">
          <span> {(followUp.caller_first_name ? followUp.caller_first_name.toLowerCase():'') +
                          " " +
                          (followUp.caller_last_name?followUp.caller_last_name.toLowerCase():'')}
                          </span>
                         
                        {followUp.is_repeated === 1 && <FiRepeat className="text-[#2DB3C0] text-center" />}
                       </div></td>
                      <td className="text-center">{followUp.user_number}</td>

                      <TypeCell>
                        {followUp.follow_up_type?.split(",").map((callType) => {
                          var name = callType
                            .replace(/\s/g, "")
                            .toLowerCase()
                            .split("/", 1)[0];
                          return (
                            <span
                              key={name}
                              className={name + " status p-1 m-2"}
                            >
                              {callType}
                            </span>
                          );
                        })}
                      </TypeCell>
                      <td className="text-center">{followUp.agent_name}</td>
                    
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : (
          <div  className="d-flex justify-content-center" >
            <div
              className="spinner-border"
              role="status"
              style={{ width: "6em", height: "6em" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Wrapper>
      {(followUps )&& (
      <PastoralPagination
       currentPage ={currentPage}
       itemsPerPage ={itemsPerPage}
       total_count ={ followUps.total_count}
      handlePageChange ={handlePageChange}
      />
      )}
    </>
  );
};

export default FollowUpList;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TypeCell = styled.td`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
const TableHeaderRow=
styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
 
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  cursor:pointer;  
  min-height:57px;
  &:hover{
  background-color:#F1F1F1;
  }
`;
const Table = styled.table`
  width: 100%;
  font-family: Space Grotesk;
`;
const Th = styled.th`
  text-align: center;

`;
const Header = styled.header`
  display: flex;
  justify-content: center;

`;

