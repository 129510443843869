import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components'
import "../styles/AddOrganization.css"
import { OrganizationContext } from '../contexts/OrganizationContext';
import { organizationTypes, organizationTiers, organizationDenominations, countries, provinces } from '../assets/data';
//This Add organization modal controls the signing up of an organization in our organizations table
export const AddOrganization = (props) => {
    const { 
        organization,
        updateOrganizationField,
        addOrganization,
        onImageChange
    } = useContext(OrganizationContext);
    return (
        <>
          <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-90w">
            <Modal.Header closeButton>
              <Modal.Title> Add New Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ChurchImage className='mx-5 p-0'>
                    <input type="file" onChange={onImageChange} accept="image/png" id="png-upload" className="filetype" style={{display: "none"}} />
                    <label htmlFor="png-upload">
                        <img   
                            src={organization.image ? URL.createObjectURL(organization.image) : require("../assets/images/churchIcon.png")} 
                            alt="logo" width={'146'}
                        />
                        
                    </label>
                </ChurchImage>
    
                <ChurchInfo className='p-3'>
                    <ChurchInfoHeader className='p-2'>
                        Organization Info
                    </ChurchInfoHeader>
                   
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="organizationName"><h6>Organization Name<sup>*</sup> </h6></label>
                            <input type="text" className="form-control" id="organizationName" onChange={(e) => updateOrganizationField('name', e.target.value)} value={organization.name} />
                        </div>
        
                        <div className="col">
                            <label htmlFor="organizationType"><h6>Organization Type<sup>*</sup> </h6></label>
                            <select className="form-control"
                            value={organization.type}
                            onChange={(e)=> updateOrganizationField('type', e.target.value)}
                                >
                                <option default value="">Select Organization Type </option>
                                {organizationTypes.map(type => (
                                    <option key={type.id} value={type.value}>{type.label}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="organizationDenomination"><h6> Organization Denomination </h6></label>
                            <select className="form-control"
                            value={organization.denomination}
                            onChange={(e)=> updateOrganizationField('denomination', e.target.value)}
                                >
                                <option default value="">Select Denomination Type </option>
                                {organizationDenominations.map(denom => (
                                    <option key={denom.id} value={denom.value}>{denom.label}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col">
                            <label htmlFor="stripeId"><h6>Organization Stripe ID<sup>*</sup> </h6></label>
                            <input type="text" className="form-control" id="stripeId" onChange={(e) => updateOrganizationField('stripeId', e.target.value)} value={organization.stripeId} />
                        </div>
                    </div>

                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="organizationType"><h6>Organization Tier<sup>*</sup> </h6></label>
                            <select className="form-control"
                            value={organization.tier}
                            onChange={(e)=> updateOrganizationField('tier', e.target.value)}
                                >
                                <option default value="">Select Tier </option>
                                {organizationTiers.map(tier => (
                                    <option key={tier.id} value={tier.value}>{tier.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="organizationName"><h6>Organization Payment Term<sup>*</sup> </h6></label>
                            <select className="form-control"
                            value={organization.paymentTerm}
                            onChange={(e)=> updateOrganizationField('paymentTerm', e.target.value)}
                                >
                                <option default value="">Select Payment Term </option>
                                <option value="yearly">Yearly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
        
                        
                    </div>

                    <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="address"><h6>Address<sup>*</sup> </h6></label>
                        <input type="text" className="form-control" id="address" value={organization.address}
                            onChange={(e)=> updateOrganizationField('address', e.target.value)} />
                    </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="city"><h6>City<sup>*</sup> </h6> </label>
                        <input type="text" className="form-control" id="city" value={organization.city} 
                            onChange={(e)=> updateOrganizationField('city', e.target.value)}
                        />
                    </div>
                    <div className="col">
                        <label htmlFor="country"><h6>Country<sup>*</sup> </h6></label>
                        <select 
                            className="form-control"
                            value={organization.country}
                            onChange={(e) => {
                                updateOrganizationField('country', e.target.value);
                                updateOrganizationField('province', ''); // Reset province when country changes
                            }}
                        >
                            <option default value="">Select Country</option>
                            {countries.map(country => (
                                <option key={country.id} value={country.value}>{country.label}</option>
                            ))}
                        </select>
                    </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="province"><h6>Province / State<sup>*</sup> </h6></label>
                        <select 
                            className="form-control"
                            value={organization.province}
                            onChange={(e) => updateOrganizationField('province', e.target.value)}
                            disabled={!organization.country}
                        >
                            <option default value="">Select Province/State</option>
                            {organization.country && provinces[organization.country] && 
                                provinces[organization.country].map(province => (
                                    <option key={province.id} value={province.value}>
                                        {province.label}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col">
                        <label htmlFor="postalCode"><h6>ZIP / Postal Code<sup>*</sup> </h6></label>
                        <input type="text" className="form-control" id="postalCode" 
                            value={organization.postalCode} 
                            onChange={(e)=> updateOrganizationField('postalCode', e.target.value)}
                        />
                    </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="website"><h6>Organization Website<sup>*</sup></h6></label>
                        <input type="text" className="form-control" id="website" 
                            value={organization.website} 
                            onChange={(e)=> updateOrganizationField('website', e.target.value)}
                        />
                    </div>
    
                    <div className="col">
                        <label htmlFor="organizationPhoneNumber"><h6>Organization Phone Number<sup>*</sup></h6></label>
                        <input type="text" className="form-control" id="organizationPhoneNumber"  
                            value={organization.organizationPhoneNumber} 
                            onChange={(e)=> updateOrganizationField('organizationPhoneNumber', e.target.value)}
                        />
                    </div>
                    </div>
                </ChurchInfo>
    
                <PastorInfo className='p-3'>
                    <PastorInfoHeader className='p-2'>
                        Pastor Info
                    </PastorInfoHeader>              
                    {/* <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="leadPastorName"><h6>Manager Full Name<sup>*</sup></h6></label>
                        <input type="text" className="form-control" id="leadPastorName"  
                            value={leadPastorName} 
                            onChange={(e)=> setLeadPastorName(e.target.value)}
                        />
                    </div>
                    </div> */}
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonName"><h6>Care Person Full Name<sup>*</sup></h6></label>
                            <input type="text" className="form-control" id="carePersonName" 
                                value={organization.carePersonName} 
                                onChange={(e)=> updateOrganizationField('carePersonName', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonEmail"><h6>Care Person Email<sup>*</sup> </h6></label>
                            <input type="email" className="form-control" id="carePersonEmail"
                                value={organization.carePersonEmail} 
                                onChange={(e)=> updateOrganizationField('carePersonEmail', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonPhone"><h6>Care Person Phone<sup>*</sup></h6></label>
                            <input type="text" className="form-control" id="carePersonPhone" 
                                value={organization.carePersonPhone} 
                                onChange={(e)=> updateOrganizationField('carePersonPhone', e.target.value)}
                            />
                        </div>
                    </div>
                </PastorInfo>
    
                <MoreInfo className='p-3'>
                    <MoreInfoHeader className='p-2'>
                        More Info
                    </MoreInfoHeader>  
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="assignedNumber"><h6>Assigned Number</h6></label>
                            <input type="text" className="form-control" id="assignedNumber" 
                                value={organization.assignedNumber} 
                                onChange={(e)=> updateOrganizationField('assignedNumber', e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <label htmlFor="prayerPartnersCount"><h6>Prayer Partners Count</h6></label>
                            <input type="text" className="form-control" id="prayerPartnersCount" 
                                value={organization.prayerPartnersCount} 
                                onChange={(e)=> updateOrganizationField('prayerPartnersCount', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="serviceStartTimes"><h6>Sunday Service Start Times</h6></label>
                            <input type="text" className="form-control" id="serviceStartTimes" 
                                value={organization.serviceStartTimes} 
                                onChange={(e)=> updateOrganizationField('serviceStartTimes', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="churchPrograms"><h6>Church Programs</h6></label>
                            <input type="text" className="form-control" id="churchPrograms" 
                                value={organization.churchPrograms} 
                                onChange={(e)=> updateOrganizationField('churchPrograms', e.target.value)}
                            />
                        </div>
                    </div>
                </MoreInfo>
              
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.onHide}>
                Close
              </Button>
              <Button variant="primary" onClick={()=>addOrganization(props.setShow)}>
                Add Organization
              </Button>
            </Modal.Footer>
          </Modal>
        </>
    );
}


const ChurchImage = styled.div`
    border: 1px dotted black;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img{
        cursor: pointer;
    }
`;

const ChurchInfo = styled.div`

`;
const MoreInfo = styled.div`
    background-color: #FFEAE4;
    input{
        background-color: #FFEAE4;
    }
`;

const MoreInfoHeader = styled.h4`
    text-align: left;
    color: #EC3310;
    font-family: Recoleta;
    text-decoration: underline;
`;

const PastorInfo = styled.div`
    background-color: #f3f9fa;
    input{
        background-color: #f3f9fa;
    }
`;

const PastorInfoHeader = styled.h4`
    text-align: left;
    color: #2DB3C0;
    font-family: Recoleta;
    text-decoration: underline;
`;

const ChurchInfoHeader = styled.h4`
text-align: left;

    color: #FF7234;
    font-family: Recoleta;
    text-decoration: underline;
`;