import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BsImage } from "react-icons/bs";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const OrganizationDetailsModal = ({ organization, show, handleClose }) => {
    const [organizationImage, setOrganizationImage] = useState(null);
    const [isImageLoading, setIsImageLoading] = useState(false);
    
    // Effect to load the organization image when organization changes
    useEffect(() => {
        if (organization && organization.name) {
            const loadOrganizationImage = async () => {
                setIsImageLoading(true);
                try {
                    const storage = getStorage();
                    const imageRef = ref(storage, `/logos/${organization.name}.png`);
                    const url = await getDownloadURL(imageRef);
                    setOrganizationImage(url);
                } catch (error) {
                    console.error("Error loading organization image:", error);
                    setOrganizationImage(null);
                } finally {
                    setIsImageLoading(false);
                }
            };
            
            loadOrganizationImage();
        }
    }, [organization]);
    
    // Format createdAt date if it exists
    const formatDate = (dateValue) => {
        if (!dateValue) return "";
        
        try {
            // Handle Firestore timestamp
            if (dateValue.seconds) {
                return new Date(dateValue.seconds * 1000).toLocaleString();
            }
            // Handle regular Date object
            if (dateValue instanceof Date) {
                return dateValue.toLocaleString();
            }
            // Handle string date
            return new Date(dateValue).toLocaleString();
        } catch (error) {
            console.error("Error formatting date:", error);
            return "Invalid date";
        }
    };
    
    if (!organization) return null;
    
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>
                    Viewing: {organization.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="flex justify-center"> 
                    {isImageLoading ? (
                        <div className="flex items-center justify-center w-[146px] h-[146px]">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#2DB3C0]"></div>
                        </div>
                    ) : organizationImage ? (
                        <img src={organizationImage} alt="logo" width={146}/> 
                    ) : (
                        <BsImage size={146} className="text-gray-400"/>     
                    )}
                </div>

                <div className="p-3">
                    <h4 className="p-2 text-left text-[#FF7234] font-['Recoleta'] underline">
                        Organization Info
                    </h4>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="organizationName"><h6>Organization Name</h6></label>
                            <input type="text" className="form-control" id="organizationName" value={organization.name || ""} readOnly/>
                        </div>

                        <div className="col">
                            <label htmlFor="organizationType"><h6>Organization Type</h6></label>
                            <input type="text" className="form-control" id="organizationType" value={organization.type || ""} readOnly/>
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="createdAt"><h6>Created At</h6></label>
                            <input type="text" className="form-control" id="createdAt" value={formatDate(organization.createdAt)} readOnly/>
                        </div>
                        <div className="col">
                            <label htmlFor="address"><h6>Payment Term</h6></label>
                            <input type="text" className="form-control" id="paymentTerm" value={organization.paymentTerm || ""} readOnly/>
                        </div>
                    </div>
                    <div>
                        <div className="col">
                            <label htmlFor="address"><h6>Address</h6></label>
                            <input type="text" className="form-control" id="address" value={organization.address || ""} readOnly/>
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="city"><h6>City</h6></label>
                            <input type="text" className="form-control" id="city" value={organization.city || ""} readOnly />
                        </div>
                        <div className="col">
                            <label htmlFor="province"><h6>Province / State</h6></label>
                            <input type="text" className="form-control" id="province" value={organization.province || ""} readOnly/>
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="postalCode"><h6>ZIP / Postal Code</h6></label>
                            <input type="text" className="form-control" id="postalCode" value={organization.postalCode || ""} readOnly/>
                        </div>
                        <div className="col">
                            <label htmlFor="country"><h6>Country</h6></label>
                            <input type="text" className="form-control" id="country" value={organization.country || ""} readOnly/>
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="website"><h6>Organization Website</h6></label>
                            <input type="text" className="form-control" id="website" value={organization.website || ""} readOnly/>
                        </div>

                        <div className="col">
                            <label htmlFor="organizationPhoneNumber"><h6>Organization Phone Number</h6></label>
                            <input type="text" className="form-control" id="organizationPhoneNumber" value={organization.organizationPhoneNumber || ""} readOnly/>
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-[#f3f9fa]">
                    <h4 className="p-2 text-left text-[#2DB3C0] font-['Recoleta'] underline">
                        Person Info
                    </h4>              
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonName"><h6>Care Person Full Name</h6></label>
                            <input type="text" className="form-control bg-[#f3f9fa]" id="carePersonName" value={organization.carePersonName || ""} readOnly />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonEmail"><h6>Care Person Email</h6></label>
                            <input type="email" className="form-control bg-[#f3f9fa]" id="carePersonEmail" value={organization.carePersonEmail || ""} readOnly />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="carePersonPhone"><h6>Care Person Phone EXT</h6></label>
                            <input type="text" className="form-control bg-[#f3f9fa]" id="carePersonPhone" value={organization.carePersonPhone || ""} readOnly />
                        </div>
                    </div>
                </div>

                <div className="p-3 bg-[#FFEAE4]">
                    <h4 className="p-2 text-left text-[#EC3310] font-['Recoleta'] underline">
                        More Info
                    </h4>  
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="assignedNumber"><h6>Assigned Number</h6></label>
                            <input type="text" className="form-control bg-[#FFEAE4]" id="assignedNumber" value={organization.assignedNumber || ""} readOnly/>
                        </div>
                        <div className="col">
                            <label htmlFor="prayerPartnersCount"><h6>Prayer Partners Count</h6></label>
                            <input type="text" className="form-control bg-[#FFEAE4]" id="prayerPartnersCount" value={organization.prayerPartnersCount || ""} readOnly />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="serviceStartTimes"><h6>Sunday Service Start Times</h6></label>
                            <input type="text" className="form-control bg-[#FFEAE4]" id="serviceStartTimes" value={organization.serviceStartTimes || ""}  readOnly />
                        </div>
                    </div>
                    <div className="row form-group mb-2 p-2">
                        <div className="col">
                            <label htmlFor="churchPrograms"><h6>Church Programs</h6></label>
                            <input type="text" className="form-control bg-[#FFEAE4]" id="churchPrograms" value={organization.churchPrograms || ""} readOnly />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}; 