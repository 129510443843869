import React from "react";
import MailingResources from "../../components/fullfillment/mailing-resources";
import CustomBarChart from "../../components/fullfillment/customBarChart";

export const MailingHome = () => {
  return (
   <>
   <div><MailingResources showFilter={false} status='New' itemsPerPage={5} showPagination={false}/></div>
   <div className="flex">
      <div className=" w-50"><MailingResources itemsPerPage={5} showFilter={false} showPagination={false}/></div>
      <div className=" w-50"> <CustomBarChart/></div>
   </div>
   
   </>
  );
};

export default MailingHome;