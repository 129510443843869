import React, { useContext } from 'react';
import styled from 'styled-components';
import { PastoralContext } from './../../../../contexts/PastoralContext';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../../../../contexts/ProfileContext';

const Sidebar =  () => {
  const { activeSideBar, setActiveSideBar, setActiveTopBar} = useContext(PastoralContext);
  const {worker} = useContext(ProfileContext);
  
  const handleClick = (index) => {
    setActiveSideBar(index);
    setActiveTopBar(0);
  };
  const sidebarItems = [
 {id:1, name: '/home',  content: 'home',image:"home.png",role:'pastoral_care' },
    {id:2, name: '/followups', content: 'AllFollowUp' ,image:"hands.png",role:'pastoral_care' },
    {id:3, name: '/pastoral-calllogs', content: 'CallLog',image:"headset.png" ,role:'pastoral_care'},
    // {id:4, name: 'Location',  content: 'ChurchLocation',image:"mapPin.png" },
    {id:1, name: '/home',  content: 'Home',image:"home.png",role:'fullfillment' },
    {id:2, name: '/resources', content: 'Pending Mailing Resources' ,image:"hands.png",role:'fullfillment' },
    {id:5, name: '/inventory',  content: 'Inventory',image:"Bible.png",role:'fullfillment' },
    ];

  return (
    
      <SidebarContainer >
        <LogoContainer>
          <img  src= {require("./../../../../assets/images/a1pLogo.png")} alt="logo" width={'48'} />
        </LogoContainer>                
        <SidebarMenu>
        {sidebarItems.map((item, index) => (
          worker.role ===  item.role &&
            <Link to={item.name}  key={item.id}>
            <MenuItem 
            title={item.content}
            key={item.id}
            style={{backgroundColor: (item.id===activeSideBar) ? '#F4E5E2' : 'transparent'}}
            onClick={() => handleClick(item.id)}
            ><img  src= {require("./../../../../assets/images/"+ item.image)} alt="logo" width={'48'} />
          
          </MenuItem>
          </Link>
          
        ))}
          
        </SidebarMenu>
      </SidebarContainer>
  
  );
};
// Styled components
const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  width: 9vw;
  background-color: #FAF1EF;
  color: white;
  z-index: 1000;
  height: 100vh;
   display:flex;
   flex-flow:column;
    
`;

const SidebarMenu = styled.ul`
  list-style: none;
  padding: 0;
  display:flex;
  height:90vh;
    flex-flow: column;
    flex-shrink: 1;
    
`;

const MenuItem = styled.div`
display:flex;
  height:25vh;
  align-items: center;
  flex-shrink: 1;
justify-content: center;
 
 &:hover {
    text-decoration: underline;
    background-color: #F4E5E2;
    cursor:pointer;
  }
`;


const LogoContainer =styled.div`
display: flex;
height: 115px;
width: 100%; 
align-items: center;
justify-content: center;
`;

export default Sidebar;