import React from "react";
import jsPDF from "jspdf";
import marcSignatureImg from "../../../../assets/images/marc's signtiure.png";
import gloriaSignatureImg from "../../../../assets/images/gloria signture.jpg";

const CustomPDF = ({ request, letters }) => {
  const handleGenerateAndPrintPDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    let cursorY = 40; // Start position for text

    // Capitalize each word in the user's name
    const capitalizedSentence = request.userName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    // Ensure address handling doesn't break when missing parts
    const addressParts = request.address ? request.address.split(", ") : [];
    const street = addressParts[0] || "";
    const city = addressParts[1] || "";
    const code = addressParts[2] || "";
    const state = addressParts[3] || "";
    const country = addressParts[4] || "";

    const addTextWithPageBreak = (text, x, y,align) => {
      const splitText = doc.splitTextToSize(text, 170);
      splitText.forEach((line) => {
        if (cursorY + 25 > pageHeight - 25) {
          doc.addPage();
          cursorY = 20;
        }
        if (align ==='center')
        {
          const textWidth = doc.getTextWidth(text); // Get text width
          x= (pageWidth - textWidth) / 2;
        }
          
        doc.text(line, x, cursorY);
        cursorY += 5;
      });
    };

    doc.setFontSize(10);
    //doc.setLineHeightFactor(0.8);
    if (letters.includes("Cover Letter")) {
      addTextWithPageBreak(
        `Dear ${capitalizedSentence},

Thank you for calling our prayer lines.  We are here for your prayer needs any time you need to speak with someone.  Also, please inform us of how the Lord has answered prayers.  We pray with you and for you and we also love to rejoice with you.   God is Good.

Enclosed, you will find extra book(s), that we felt would be of great reading as an addition to other resource(s), you might have received from us.

Have a wonderful blessed day,

Teresa and the Pastoral Care Follow-Up Team  

Teresa  T.
Crossroads Prayer Centre/Follow-Up Pastoral Care Team
ttelo@crossroads.ca
Crossroads Prayer Line 1-866-273-4444`,
        20,
        cursorY
      );

      cursorY += 10;
      doc.setFont("helvetica", "italic");
      addTextWithPageBreak(`“Prayer enlarges the heart, until it is capable of containing God’s gift of Himself" `, 20, cursorY,'center');
       addTextWithPageBreak(` - Mother Teresa -`, 20, cursorY,'center');
        cursorY += 10;
      doc.setFont("times", "bold");
      doc.setFontSize(14);
      addTextWithPageBreak(`Numbers 6:24-26 - New King James Version`, 20, cursorY);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal"); // Normal text
      doc.setFontSize(7); // Reduce font size for superscript
      addTextWithPageBreak("24", 20, cursorY); // Superscript
      doc.setFontSize(10);
      cursorY -=5;
      addTextWithPageBreak(`"The Lord bless you and keep you;"`, 24, cursorY);
      doc.setFontSize(7); // Reduce font size for superscript
      addTextWithPageBreak("25", 20, cursorY); // Superscript
      doc.setFontSize(10);
      cursorY -=5;
      addTextWithPageBreak( `"The Lord make His face shine upon you,  And be gracious to you;"`,  24,  cursorY      );
      doc.setFontSize(7); // Reduce font size for superscript
      addTextWithPageBreak("26", 20, cursorY); // Superscript
      doc.setFontSize(10);
      cursorY -=5;
      addTextWithPageBreak(`"The Lord [a]lift up His countenance upon you, And give you peace.” `,  24,  cursorY );

    }

    if (letters.includes("Literature Letter")) {
      if (letters.includes("Cover Letter")) doc.addPage();
      cursorY = 40;

      const today = new Date();
      const formattedDate = today.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });;
      doc.text(formattedDate, 150, cursorY);
      cursorY += 10;

      addTextWithPageBreak(
        `${capitalizedSentence}\n${street}\n${city}, ${code}, ${state}\n${country}\n\nDear Friend,
        
What a privilege for our Prayer Partner to take your call and pray for you as you choose to follow the Lord Jesus Christ! 

The Bible states that if anyone is in Christ, they are a new creation, the old is gone, the new has begun! (2 Corinthians 5:17) May God help you choose daily to surrender to Him, asking His Holy Spirit to fill you, helping you to put on the armor of God (Ephesians 6:10-18), as you let the Holy Spirit change you through thanksgiving, worship, reading and learning to absorb and stand on His truth in the Bible, prayer, sharing Him with others. May He protect you and strengthen you as you experience His love for you!

Allow me to introduce who we are at Crossroads. Since 1977 Crossroads has been a Media Ministry with a daily Christian program called 100 Huntley St. proclaiming the Good News of Jesus Christ and offering a toll-free Prayer Line-1/866-273-4444. Our Prayer Ministry exists to pray with callers and offer resources that will help you walk closer with the Lord Jesus Christ.

If you have time go to our website 100huntley.com, you can watch the 100 Huntley St program and you can search our video library on a wide variety of topics (www.100huntley.com/topic-pick). These are so helpful to those wanting to learn and grow in the Lord.

Crossroads Prayer Ministry recently introduced an Email-based prayer buddy/discipleship program called the "Walk of Faith," to help those interested in going deeper in their faith in Jesus and receive spiritual care as they learn what it means to be a follower of His.

This program is free of charge and can continue as long as you are interested. The only requirement is a genuine interest in being discipled.

If you are using email and are interested, our Email Prayer buddies/mentors would love to hear from you. Please click on the link below. Fill in your name, email address and a brief message with your spiritual need. Please note, it is your responsibility to fill in your information if you want one of our email prayer partners to contact you. Please allow a couple weeks for their response. Use this link to sign up: https://thelife.com/crossroads`,
        20,
        cursorY
      );  
doc.setFont("helvetica", "bold");
addTextWithPageBreak( ` 
One Last Resource that is New for Women!`,
        20,
        cursorY
      );
doc.setFont("helvetica", "normal");
 addTextWithPageBreak(`
See Hear Love is a women’s talk show that has a mission to create a safe space of belonging, a community where we value authenticity, transparency, vulnerability, and courage as we discuss challenging current topics and themes. 

We envision a world where real women, living an authentic life, champion other women to grow in their faith.`,
        20,
        cursorY
      ); 

doc.setFont("helvetica", "bolditalic");
addTextWithPageBreak( `May the Lord bless you and keep you,
May the Lord smile on you and be gracious to you,
May the Lord grant you His favour and give you His peace.

In Jesus Name,`,
        20,
        cursorY
      );
   
      if (marcSignatureImg) doc.addImage(marcSignatureImg, "PNG", 20, cursorY, 50, 10);
      cursorY += 10;
      addTextWithPageBreak(`Marc Rudy`,
        20,
        cursorY
      );
      doc.setFont("helvetica", "normal");
 addTextWithPageBreak(`Follow-up Ministry Representative
Crossroads/100 Huntley St. 
Burlington, Ontario`,
        20,
        cursorY
      );
      cursorY += 10;
  
      addTextWithPageBreak(`Our mission is to help people of every `,
        20,
        cursorY,
        'center'
      );
    addTextWithPageBreak(`age and stage become followers of Jesus Christ through inspired media.`,
        20,
        cursorY,
        'center'
      );
    }

    if (letters.includes("Salvation / Rededication")) {
      if (letters.includes("Cover Letter") ||letters.includes("Literature Letter"))  doc.addPage();
      cursorY = 40;

      addTextWithPageBreak(
        `${capitalizedSentence}\n${street}\n${city}, ${code}, ${state}\n${country}\n\nDear Friend,
        
What a privilege it was for our Prayer Partner to take your prayer call and pray for you!  I am overjoyed to know that you have committed to following Jesus and acknowledge Him as your personal Lord and Saviour.  Surrendering your life to God and opening your heart to Him is the most important life-changing decision you will ever make.

God said, “Yes, I have loved you with an everlasting love; therefore, with lovingkindness I have drawn you” (Jeremiah 31:3).  It was God’s love that drew you to surrender your life to Him, so you can be close to Him and experience His love and the good life as his own child that He has planned for you.

For a time, all of us were separated from Him by sin.  We read in the Bible that “all have sinned and fall short of the glory of God” (Romans 3:23).  Yet He lovingly created a bridge so you and I can come back to Him through His Son Jesus, who was sent to earth as an infant and lived a sinless life before being crucified on the cross – paying the full price for our sins (Romans 5:8).  It is through Jesus we have been adopted into God’s family as His children.  The Scriptures sum it up this way: “…as many as received Him, to them He gave the right to become the children of God” (John 1:12).  Jesus confirmed His role in God’s great plan of salvation when He proclaimed: “I am the way, the truth, and the life.  No one comes to the Father except through Me” (John 14:6).

Jesus once told an interesting story about a shepherd of 100 sheep who left the ninety-nine to go and look for the one that was lost.  When the lamb was found, the shepherd called out to his friends, “Rejoice with me, for I have found my sheep which was lost!” Jesus then concluded the story by saying, “…there will be more joy in heaven over one sinner who repents, than over ninety-nine…who need no repentance” (Luke 15:6,7).  How wonderful to know that heaven is rejoicing over you!

Remember that the moment you invited Jesus into your heart you were completely forgiven and cleansed from all sin.  Along with that decision comes the assurance that “if we confess our sins, He is faithful and just to forgive us our sins and to cleanse us from all unrighteousness” (1 John 1:9).  This means that every one of your sins has been washed away by the blood of Jesus.  

The grace of God is on your life and his Holy Spirit resides within you, so you are no longer condemned (Romans 8:1).  From this point on, rather than dwelling on past sins and allowing guilt or shame, express thanksgiving for God’s tremendous love and mercy toward you.  Know that when your Father God looks at you, He only sees His beloved child, not your past mistakes.  “For it is by free grace (God’s unmerited favour) that you are saved (delivered from judgement) through your faith. And this salvation is not of yourselves…through your own striving but is the gift of God” (Ephesians 2:8).

Now He wants to teach you how to live your life His way.  When Jesus came to this earth, He showed us how to live and respond to others and those stories are found in the four Gospels in the New Testament. As you follow Jesus’ example by the power of the Holy Spirit, you will experience God’s blessings and understand His unique plan for you.   Others will be drawn closer to Him by the changes and blessings they see happening in your life.

Since God’s Spirit is living within you helping you to become more like Jesus, it is our prayer that His love will surround you and His power will transform you.  We are “confident of this very thing, that He [God] who has begun a good work in you will complete it” (Philippians 1:6).  We’re here to help you grow in God’s love (see resources below) and want you to know our team is praying for you!  

Again, it is our joy and privilege to support those in the family of God, and encourage you on your spiritual journey with Christ, so don’t hesitate to call us again on our 24/7 Prayer Lines! As you know it can be so helpful to receive personal prayer ministry, along with a word of comfort and encouragement when you or a loved one are going through life's challenges. As a ministry team, it is our privilege to minister to you from God's Word and bring your needs and requests to God in prayer. Please feel free to call our Toll-Free line any time you have a need:   1-866-273-4444; or email us at: http://www.crossroads.ca/247prayer/; or write down any prayer needs you may have and forward them to us by mail: Crossroads/100 Huntley St., Box 5100 Burlington ON L7R 4M2.`,
        20,
        cursorY
      );

doc.setFont("helvetica", "bolditalic");
addTextWithPageBreak( `Church Referrals`,
        20,
        cursorY
      );
doc.setFont("helvetica", "normal");
addTextWithPageBreak( `
It’s our desire to see you connected with the family of God so that you will be able to keep growing in your faith. There are times in life when we are in a painful valley and it seems too difficult to go it alone. ‘At those times, the Lord calls us to "bear one another's burdens, and so fulfill the law of Christ" (Galatians 6:2).  Christian counselling is another effective way to receive help in releasing burdens and finding freedom. We can help connect you with both.
`,
        20,
        cursorY
      );
doc.setFont("helvetica", "bolditalic");
addTextWithPageBreak( `Crossroad’s On-Line Discipleship Program`,
        20,
        cursorY
      );
doc.setFont("helvetica", "normal");
addTextWithPageBreak( `
If you have not already signed up, may I remind you about Crossroads Prayer Ministry Email-based prayer buddy/discipleship program called the "Walk of Faith," to help those interested in going deeper in their faith in Jesus and receive spiritual care as they learn what it means to be a follower of His. This program is free of charge and can continue as long as you are interested. 

Our Email Prayer buddies/mentors would love to hear from you. Please click on the link below. Fill in your name, email address and a brief message with your spiritual need. Please note, it is important to fill in your information if you want one of our email Prayer buddies/mentors to contact you. Please allow a couple weeks for their response. Click on this link to sign up: https://thelife.com/crossroads`,
        20,
        cursorY
      );
      doc.setFont("helvetica", "italic");
	addTextWithPageBreak(
        `
“The Lord bless you and keep you; the Lord make His face shine upon you and be gracious to you; the Lord  lift up His countenance upon you and give you peace” (Numbers 6:24-26).

“Those who live in the shelter of the Most High, will find rest in the shadow of the Almighty. This I declare about the Lord: He alone is my refuge, my place of safety; he is my God, and I trust him.” (Psalm 91:1,2).

							
In Christ's love and service,`,
        20,
        cursorY
      );

   
      if (gloriaSignatureImg) doc.addImage(gloriaSignatureImg, "PNG", 20, cursorY, 50, 10);   
      cursorY += 15;
      addTextWithPageBreak(
        `Gloria Willoughby, Manager
Crossroads 24/7 Prayer Centre 
  
  
P.S.  When you are on-line, we invite you to check out Crossroads daily Christian TV program called 100 Huntley Street since 1977. You’ll find many other resources on our websites, www.100huntley.com,  and www.crossroads.ca,  where you can click on 24/7 Prayer to leave an email prayer request. 
Our own Prayer Centre’s Pastor Neil Cole has a teaching series on how to be free in Christ which you may also find very helpful: https://www.youtube.com/user/niteliteonlineficm . 
You can find Daily Bible Teaching and a two-year through the Bible daily blog created by our Founder David Mainse, at www.100words.ca.
  `,
        20,
        218
      );
    }

    try {
      const pdfBlob = doc.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const newWindow = window.open(pdfUrl, "_blank");
      if (newWindow) {
        newWindow.focus();
        newWindow.print();
      } else {
        alert("Please allow pop-ups for this site to print the document.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div>
      <button
        className="bg-ikura text-white border-r-5 px-20 py-2 font-space rounded-lg shadow-lg hover:bg-red-700 transition-all"
        onClick={handleGenerateAndPrintPDF}
      >
        Print Letters
      </button>
    </div>
  );
};

export default CustomPDF;
