import React, { useState, useContext, useEffect } from "react";
import styled from 'styled-components';
import Charts from "./charts";
import ReportCard from "./reportCard";
import { PastoralContext } from "./../../../contexts/PastoralContext";

const ExportButton = styled.button`
  
  align-items: center;

  padding: 8px 16px;
  margin-top: 16px;
  color: #106271;
  background: white;
  border: 1px solid #106271;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f4f8fe;
  }


`;

const Reports = () => {
  
    const [data,setData] =useState([]);
    const [yearsData,setYearsData] =useState([]);
    const [previousData,setPreviousData] =useState([]);
    const [totals,setTotals] =useState([]);
    const {getReportsData } = useContext(PastoralContext);
    const [locationStats,setLocationStats] =useState([]);
    const XLSX = require('xlsx');

    useEffect(()=>{
        getReportsData().then((result) => {
        
            setData(result['groupedResults'][new Date().getFullYear()]);
            setYearsData(result['groupedResults']);
            setPreviousData(result['groupedResults'][new Date().getFullYear()-1]);
            setLocationStats(result['location_stats']);
           var data={
                'Prayer_count':result.Prayer_count,
                'Rededication_count':result.Rededication_count,
                'Salvation_count':result.Salvation_count
        }
            setTotals(data);
        });   
    },[])

 

    const generateExcel = () => {
        if (!yearsData || Object.keys(yearsData).length === 0) return;
    
        // Create a new workbook
        const wb = XLSX.utils.book_new();
    
        // **Sheet 1: Summary Report**
        const summaryData = [
            ["Fiscal Yr/Month", "Salv", "Reded", "Totals", "Prayer Interactions", "Suicide Form", "Suicide Follow-up Completed"]
        ];
    
        // Iterate over each year and month for the first sheet
        Object.entries(yearsData).forEach(([year, months]) => {
            summaryData.push([`${year}`]); // Year as a separate row
            Object.entries(months).forEach(([month, row]) => {
                summaryData.push([
                    month, 
                    row.Salvation || 0, 
                    row.Rededication || 0, 
                    (Number(row.Salvation) + Number(row.Rededication)) || 0, 
                    row.Prayers || 0, 
                    row.TotalSuicideCalls || 0, 
                    row.SuicideFollowUps || 0
                ]);
            });
        });
    
        const summarySheet = XLSX.utils.aoa_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(wb, summarySheet, "Summary Report");

        // **Sheet 2: Origin of Salvations/Rededications**
        Object.entries(locationStats).forEach(([period, countryStat]) => {
        const originData = [
            ["Origin of Salvations/Rededications"],
            [""],
            ["Grand Totals"],
            ["Canada", 0],
            ["United States", 0],
            ["Other Countries", 0],
            ["Sub Total", 0],
            ["Unknown", 0],
            ["Total", 0],
           ['']
        ];   let grandTotalUS = 0, grandTotalCA = 0, grandTotalOther = 0;
            Object.entries(countryStat).forEach(([country, locationStat]) => {
        

    
        if (country === 'US') {
            grandTotalUS = locationStat.total|| 0;
        }
        if (country=='CA') {
            grandTotalCA = locationStat.total|| 0;
        }
     
    
        if (country=='CA' || country=='US') {
            originData.push([country === 'CA' ? 'Canada Breakdown' : 'US Breakdown']);
            Object.entries(locationStat.provinces).forEach(([province, count]) => {
                    originData.push([province, count]); // Ensure each row is an array
            });
            originData.push(['']);
        }
        else{
            originData.push( [country,locationStat.total]);
            grandTotalOther += locationStat.total;
        }
    
    
   
         
        })
        originData[3][1] = grandTotalCA; // Canada total
        originData[4][1] = grandTotalUS; // US total
        originData[5][1] = grandTotalOther; // Other total
        originData[6][1] = grandTotalCA + grandTotalUS + grandTotalOther; // Sub Total
        originData[8][1] = grandTotalCA + grandTotalUS + grandTotalOther; // Total    
        
        const originSheet = XLSX.utils.aoa_to_sheet(originData);
        XLSX.utils.book_append_sheet(wb, originSheet, period);
    })
        // **Write the workbook to a file**
        XLSX.writeFile(wb, "prayer_report.xlsx");
    };
    
   
    return (
        <div className="columns-2">
          <div className="flex-column flex"> 
           {(yearsData && Object.keys(yearsData).length > 0) && (
             <ExportButton className="mx-5" onClick={() => generateExcel()}>
               Export Report
              
             </ExportButton>
           )}
            <Charts data={data} title="CONFIRMED IN THE PREVIOUS MONTHS" />
            {/* <Charts data={previousData} title="CONFIRMED IN THE PREVIOUS YEAR" /> */}
           </div> 
           <ReportCard totals={totals}/>
        </div>
    );
    };

export default Reports;