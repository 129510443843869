// SearchComponent.js
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { PastoralContext } from "./../../../contexts/PastoralContext";
import { DateRangePicker } from 'rsuite';
import { predefinedBottomRanges,followUpTypes } from "../../../assets/data";

// SearchComponent
const SearchComponent = (props) => {
  const { context } = props; // Context passed as a prop
  const {searchValue,  setSearchValue,filter} = useContext(context);
  const [selectedRange, setSelectedRange] = useState([]);
  

  const handleSearch = () => {
    
    // Handle search logic here
  };

  // Pass the removeValue function into the filter function to return the specified value
  const handleRange = (selectedRange) => {
  console.log('Search query:', selectedRange);  
  setSearchValue(selectedRange);
}

  const handleClear = () => {
    setSearchValue('');
  };

  return (
    <>
    { filter === "date_range" ?
      <DateRangePicker 
      className='text-[#2DB3C0]'
    id="selectedRange" 
    value={searchValue?searchValue:[]} 
    ranges={predefinedBottomRanges} 
    placeholder="Select Date Range"
    cleanable 
    format="yyyy-MM-dd"         
    onChange={handleRange}/>
    :
    filter === "follow_up_type" ?
    <FollowUpSelect
    className=" "
    aria-label="Age Range List"
    id="ageRangeList"
    value={searchValue || ""}
    onChange={(e) => setSearchValue(e.target.value)}
  >
    <option value="">Select a Follow Up type</option>
    {followUpTypes.map((followUpType) => {
      return (
        <option
          key={followUpType.id + followUpType.type}
          value={followUpType.type}
        >
          {followUpType.type}
        </option>
      );
    })}
  </FollowUpSelect>
    :

    <SearchContainer className='float-right'>
    <SearchIcon />
    <SearchInput
      type="text"
      value={searchValue || ""}
       className="px-5 py-2"
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder="Search..."
    />
    {searchValue && <ClearIcon onClick={handleClear} />}
    <button onClick={handleSearch} style={{ display: 'none' }}>Search</button> {/* Hidden button for potential future use */}
  </SearchContainer>
  
    }
    
    </>
  );
};

export default SearchComponent;

// Styled-components
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color:#2DB3C0;
  
`;

const SearchInput = styled.input`
  
  font-size: 16px;
  border-radius: 4px;
  width: 300px;
  outline: none;
  border-radius: 8px;
    border: 1px #2DB3C0 solid;
  
  &:focus {
    
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const FollowUpSelect = styled.select`
display:flex;
flex-flow:row;
flex-shrink: 0;
border-radius: 8px;
justify-content: center;
border: 1px #2DB3C0 solid;
color: #2DB3C0;
text-align: center;
align-items: center;
font-family: "Space Grotesk";
font-size: 14px;
font-style: normal;
font-weight: 400;
letter-spacing: 2.1px;
flex-shrink: 0;
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  left: 10px;
  color:#2DB3C0;
`;

const ClearIcon = styled(FaTimes)`
  position: absolute;
  right: 10px;
  color:#2DB3C0;
  cursor: pointer;

  &:hover {
    color: #ff4d4d;
  }
`;
