import React, { useContext,  useState,useEffect } from 'react'
import styled from 'styled-components';
import PastoralPagination from '../../components/pagination';
import FollowUpFilter from '../../components/followUpFilter';
import { MailingContext } from '../../../../contexts/MailingContext';
import { useNavigate } from 'react-router-dom';
import TextInputWithActions from '../../components/fullfillment/textInputWithAction';
import EditableInput from '../../components/fullfillment/textInputWithAction';

export const Inventory = (props) => {
  const source = props.source?props.source:'';
    const { getInventory,searchValue,sortOrder,updateInventory} = useContext(MailingContext);  
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1); 
    const [filter, setFilter] = useState(""); 
    const [resources, setResources] = useState({ data: [], total: 0 });
    const [resourceUpdated, setResourceUpdated] = useState(0);
    const navigate = useNavigate();
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

      const handleApiCall = async (id, newValue) => {
        try {
          await updateInventory(id, { quantityAvailable: newValue });
          setResourceUpdated(resourceUpdated+1)
          setResources((prevResources) => ({
            ...prevResources,
            data: prevResources.data.map((item) =>
              item.inventoryID === id ? { ...item, quantityAvailable: newValue } : item
            ),
          }));
          
        } catch (error) {
          console.error("Error updating inventory:", error);
        }
      };
      
      
    useEffect(()=>{

      getInventory('',currentPage,source).then((results)=>{
            setResources(results.data);
        })
        
    },[currentPage,searchValue,sortOrder,resourceUpdated])
    return(
        <><div className="justify-end">
        <FollowUpFilter context={MailingContext}   />
        </div>
        <Wrapper className=' m-5 px-3'>
        
        <Header className='px-5 p-3 mt-4 justify-center'> 
            <TableHeading> INVENTORY LIST</TableHeading>

        </Header>
        {resources.data ?
        <> 
            <Table>
                <thead>
                    <TableHeaderRow >                    
                        <th className='text-center'>Title</th>
                        <th className='text-center'> # of Items Requested</th>
                        <th className='text-center'> In House</th>
                        {/* <th className='text-center'> Limited until Inventory is gone</th>
                        <th className='text-center'>Estimated Inventory on hand</th>                */}
                    </TableHeaderRow>
                </thead>
                <tbody>
                {
                    resources.data.map((resource) => {
                        return (     
                        <TableRow key={resource.id}>
                            <td className='p-2'>{resource.title}</td> 
                            <td className='p-2 text-center'><div className='p-2 flex justify-center'> <Physical className= "status p-1 m-1">{resource.quantityOnHold } </Physical></div></td>
                            <td className='p-2 text-center'><div className='p-2 flex justify-center'><Physical className= "status p-1 m-1"><EditableInput resource={resource} onSubmit={handleApiCall} /> </Physical></div></td>
                            {/* <td className='p-2 text-center'><div className='p-2 flex justify-center'><Physical className= "status p-1 m-1">{0} </Physical></div> </td>
                            <td ><div className='p-2 flex justify-center'><Physical className= "status p-1 m-1">{0} </Physical>  </div> 
                            </td>*/}
                         
                        </TableRow>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
       : <div className="d-flex justify-content-center" >
            <div className="spinner-border" role="status" style={{width: "6em", height: "6em"}}>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        }
    </Wrapper>
      {resources?.total > 0 && (
          <PastoralPagination
          currentPage ={currentPage}
          itemsPerPage ={itemsPerPage}
          total_count ={ resources.total}
         handlePageChange ={handlePageChange}
         />
      )}
      </>
    )
};

export default Inventory;

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
`;

const TableHeading = styled.span`
  color: #ec3310;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;

const TableHeaderRow=
styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
 
 
`;
const TableRow = styled.tr`
  border-bottom-color: #c9cfda;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  
  &:hover{
  background-color:#F1F1F1;
  }
`;
const Table = styled.table`
  width: 100%;
   font-family: Space Grotesk;
`;

const Header = styled.header`
  display: flex;
  justify-content: center;

`;
const Physical = styled.span`
  border: 1px solid #00323B;
  background: #EEFDFF;
color:#00323B;
`;
const Digital = styled.span`
  border: 1px solid #FF7234;
  background: #FFEAE4;
color:#FF7234;
`;






