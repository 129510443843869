export function formatDate(date){
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    // Format as YYYY-MM-DD HH:MM:SS
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate; // Outputs local time, e.g., 2024-09-01 00:00:00
}

export function convertSeconds(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);  // 1 hour = 3600 seconds
    const minutes = Math.floor((totalSeconds % 3600) / 60);  // Remaining minutes
    const seconds = totalSeconds % 60;  // Remaining seconds after minutes are extracted

    return {
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0')
    };
}


// Function to generate and download a CSV file
export function downloadCSV(headers, data, filename = "data.csv") {
  if (!Array.isArray(headers) || !Array.isArray(data)) {
    console.error("Headers and data must be arrays.");
    return;
  }

  // Convert headers and data to CSV format
  const csvRows = [
    headers.join(","), // Add headers
    ...data.map((row) =>
      headers
        .map((header) => {
          const value = row[header] ?? ""; // Use exact header key
          return `"${String(value).replace(/"/g, '""')}"`; // Escape double quotes
        })
        .join(",")
    ),
  ];

  const csvContent = `\uFEFF${csvRows.join("\n")}`; // Add BOM for UTF-8 support

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a temporary link to trigger the download
  const link = document.createElement("a");
  const url = window.URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}
