import React, { useEffect, useContext, useState, useRef } from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { OrganizationContext } from "../contexts/OrganizationContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { sendMonthlyBill } from "../services/stripe-service";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { OrganizationDetailsModal } from "./organization-details-modal";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useReactToPrint } from 'react-to-print';
//the component that displays the different organizations and thier info

export const ViewOrganizations = () => {
    const {
        organizations, organizationNumbers, filterByArray, setFilterByArray, setFilterValueArray,
        showAllOrganizations, setShowAllOrganizations, getTotalMonthlyCalls, nameClicked, setNameClicked,
        currentPage, setCurrentPage, itemsPerPage, totalOrganizations
    } = useContext(OrganizationContext);
    const {setOrganizationNumber, showReports, setShowReports, worker, handleShow} = useContext(ProfileContext);
    const navigate = useNavigate();
    
    // State for the selected organization to view
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [showOrgModal, setShowOrgModal] = useState(false);
    const [exportOption, setExportOption] = useState("");
    const componentToPrint = useRef();

    // Calculate pagination values safely with fallbacks
    const totalItems = Array.isArray(organizations) ? organizations.length : 0;
    const totalPages = Math.max(1, Math.ceil(totalItems / itemsPerPage));
    
    // Ensure currentPage is within valid range
    useEffect(() => {
        if (currentPage > totalPages) {
            setCurrentPage(Math.max(1, totalPages));
        } else if (currentPage < 1) {
            setCurrentPage(1);
        }
    }, [currentPage, totalPages, setCurrentPage]);
    
    // Calculate indices safely
    const safeCurrentPage = Math.min(Math.max(1, currentPage), totalPages);
    const indexOfLastItem = safeCurrentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
    // Get current organizations safely
    const currentOrganizations = Array.isArray(organizations) 
        ? organizations.slice(indexOfFirstItem, indexOfLastItem) 
        : [];

    // Pagination navigation with safety checks
    const paginate = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    
    const nextPage = () => {
        if (safeCurrentPage < totalPages) {
            setCurrentPage(safeCurrentPage + 1);
        }
    };
    
    const prevPage = () => {
        if (safeCurrentPage > 1) {
            setCurrentPage(safeCurrentPage - 1);
        }
    };

    const handleNameClick = (selectedOrganization) =>{
        window.scrollTo({ top: 0, behavior: "smooth" });
        setFilterByArray([]);
        setFilterValueArray([]);
        setNameClicked(selectedOrganization.name);
        setOrganizationNumber(selectedOrganization.assignedNumber);
        navigate('/home');
    }
    useEffect(() =>{
        if (worker.role.includes('admin')){
            setShowAllOrganizations(true);
        }
    }, [worker])

    useEffect(()=>{
        setShowReports(false);
        if(filterByArray.length>0) {
            setOrganizationNumber(Object.values(organizationNumbers));
            setNameClicked("");
        }else if(filterByArray.length === 0 && !nameClicked){
             setNameClicked("");
             setOrganizationNumber("+18662734444");
       }
    }, [organizationNumbers])

    // Function to handle row click and show profile modal
    const handleRowClick = (organization, e) => {
        // Prevent triggering if clicking on the name cell (which has its own handler)
        if (e.target.tagName.toLowerCase() === 'td' && e.target.classList.contains('name-cell')) {
            return;
        }
        
        // Set the selected organization for viewing
        setSelectedOrganization(organization);
        setShowOrgModal(true);
        //handleShow();
    };
    
    // Function to close the organization modal
    const handleCloseOrgModal = () => {
        setShowOrgModal(false);
        setSelectedOrganization(null);
    };

    // Handle export options
    const handleExportOption = (e) => {
        const option = e.target.value;
        setExportOption(option);
        
        if (option === "print") {
            handlePrint();
        } else if (option === "export") {
            handleExport();
        }
    }

    const handlePrint = useReactToPrint({
        content: () => componentToPrint.current,
        documentTitle: 'Organizations List',
        onAfterPrint: () => setExportOption("")
    });

    // Export to CSV functionality
    const handleExport = () => {
        // Prepare data for export
        const dataToExport = organizations.map(org => ({
            'Organization Name': org.name || '',
            'Type': org.type || '',
            'Denomination': org.denomination || '',
            'Tier': org.tier || '',
            'Province/State': org.province || '',
            'Country': org.country || '',
            'Care Person': org.carePersonName || '',
            'Created At': org.createdAt ? new Date(org.createdAt.seconds * 1000).toLocaleDateString() : '',
            'Assigned Number': org.assignedNumber || '',
            'Prayer Partners Count': org.prayerPartnersCount || '',
            'Website': org.website || '',
            'Phone Number': org.organizationPhoneNumber || ''
        }));
        const csvData = Papa.unparse(dataToExport, { header: true });
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

        // Use file-saver to trigger the download
        saveAs(blob, 'OrganizationsList.csv');        
        setExportOption("");
    };

    return (
        <>
         {Array.isArray(organizations) && 
            <>
                <div className="flex justify-between items-center mb-2 px-2">
                    <div className="text-gray-600">
                        {totalItems > 0 ? (
                            `Showing ${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, totalItems)} of ${totalItems} organizations`
                        ) : (
                            "No organizations to display"
                        )}
                    </div>
                    
                    {/* Export Options */}
                    <ExportOption 
                        onChange={handleExportOption} 
                        className="rounded mr-2 mb-2 bg-[#FAF8F8] hover:bg-[#ADCFD4] border-1 border-[#106271] text-[#106271] h-10"
                        value={exportOption}>
                        <option value="">Export Options</option>
                        <option value="print">Print PDF</option>
                        <option value="export">Export as CSV</option>
                    </ExportOption>
                </div>
                
                <div ref={componentToPrint}>
                    <Table className="p-2">
                        <thead>
                            <tr>
                                <th className="w-1/6">Organization Name</th>
                                <th className="w-1/6"> Type</th>
                                <th className="w-1/6"> Denomination</th>
                                <th className="w-1/6"> Tier</th>
                                <th className="w-1/6"> Location</th>
                                <th className="w-1/6"> Care Person</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentOrganizations.map((organization, index) => {
                                return (
                                    <tr key={index} onClick={(e) => handleRowClick(organization, e)} style={{ cursor: 'pointer' }} className="organization-row">
                                        <NameTD className="name-cell" onClick={(e) => {
                                            e.stopPropagation();
                                            handleNameClick(organization);
                                        }}>
                                            {organization.name.length > 30 ? `${organization.name.substring(0, 30)}...` : organization.name}
                                        </NameTD>
                                        <td>{(organization.type).charAt(0).toUpperCase() + (organization.type).slice(1)}</td>
                                        <td>{organization.denomination ? (organization.denomination).charAt(0).toUpperCase() + (organization.denomination).slice(1) : "N/A"}</td>
                                        <td>{(organization.tier).charAt(0).toUpperCase() + (organization.tier).slice(1)}</td>
                                        <td>
                                            <div className="flex flex-col">
                                                <span>{organization.province}</span>
                                                <span className="text-xs text-gray-500">{organization.country}</span>
                                            </div>
                                        </td>
                                        <td>{organization.carePersonName}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
                
                {/* Pagination Controls */}
                {totalPages > 0 && (
                    <div className="flex justify-center mt-4 mb-6">
                        <PaginationControls>
                            <button 
                                onClick={prevPage} 
                                disabled={safeCurrentPage === 1}
                                className={`pagination-button ${safeCurrentPage === 1 ? 'disabled' : ''}`}
                            >
                                <CaretLeft size={20} />
                            </button>
                            
                            {/* Page numbers - only show a reasonable number of pages */}
                            {totalPages <= 7 ? (
                                // If 7 or fewer pages, show all
                                [...Array(totalPages)].map((_, index) => (
                                    <button
                                        key={index + 1}
                                        onClick={() => paginate(index + 1)}
                                        className={`pagination-number ${safeCurrentPage === index + 1 ? 'active' : ''}`}
                                    >
                                        {index + 1}
                                    </button>
                                ))
                            ) : (
                                // If more than 7 pages, show a subset with ellipsis
                                <>
                                    {/* Always show first page */}
                                    <button
                                        onClick={() => paginate(1)}
                                        className={`pagination-number ${safeCurrentPage === 1 ? 'active' : ''}`}
                                    >
                                        1
                                    </button>
                                    
                                    {/* Show ellipsis if not near the start */}
                                    {safeCurrentPage > 3 && <span className="pagination-ellipsis">...</span>}
                                    
                                    {/* Show pages around current page */}
                                    {[...Array(5)].map((_, index) => {
                                        const pageNum = Math.max(2, safeCurrentPage - 2) + index;
                                        // Only render if within range and not first/last page
                                        if (pageNum > 1 && pageNum < totalPages) {
                                            return (
                                                <button
                                                    key={pageNum}
                                                    onClick={() => paginate(pageNum)}
                                                    className={`pagination-number ${safeCurrentPage === pageNum ? 'active' : ''}`}
                                                >
                                                    {pageNum}
                                                </button>
                                            );
                                        }
                                        return null;
                                    })}
                                    
                                    {/* Show ellipsis if not near the end */}
                                    {safeCurrentPage < totalPages - 2 && <span className="pagination-ellipsis">...</span>}
                                    
                                    {/* Always show last page */}
                                    <button
                                        onClick={() => paginate(totalPages)}
                                        className={`pagination-number ${safeCurrentPage === totalPages ? 'active' : ''}`}
                                    >
                                        {totalPages}
                                    </button>
                                </>
                            )}
                            
                            <button 
                                onClick={nextPage} 
                                disabled={safeCurrentPage === totalPages}
                                className={`pagination-button ${safeCurrentPage === totalPages ? 'disabled' : ''}`}
                            >
                                <CaretRight size={20} />
                            </button>
                        </PaginationControls>
                    </div>
                )}
            </>
         }
         
         {/* Use the new OrganizationDetailsModal component */}
         {selectedOrganization && (
            <OrganizationDetailsModal 
                organization={selectedOrganization}
                show={showOrgModal}
                handleClose={handleCloseOrgModal}
            />
         )}
        </>
    )
}

const DashboardLinks = styled.div`
    display: flex;
    flex-direction: row;
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;
const NameTD = styled.td`
    &:hover{
        text-decoration: underline;
        cursor: pointer
    }
`;  

const PaginationControls = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    
    .pagination-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background-color: #F1F1F1;
        color: #178B96;
        cursor: pointer;
        
        &:hover {
            background-color: #BFE9EE;
        }
        
        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            
            &:hover {
                background-color: #F1F1F1;
            }
        }
    }
    
    .pagination-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background-color: #F1F1F1;
        color: #178B96;
        cursor: pointer;
        
        &:hover {
            background-color: #BFE9EE;
        }
        
        &.active {
            background-color: #178B96;
            color: white;
        }
    }
    
    .pagination-ellipsis {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        color: #178B96;
    }
`;  

// Styled components for the OrganizationDetailsModal
const ChurchImage = styled.div`
    display: flex;
    justify-content: center;
`;

const ChurchInfo = styled.div``;

const PersonInfo = styled.div`
    background-color: #f3f9fa;
    input{
        background-color: #f3f9fa;
    }
`;

const PersonInfoHeader = styled.h4`
    text-align: left;
    color: #2DB3C0;
    font-family: Recoleta;
    text-decoration: underline;
`;

const EditMessage = styled.p`
    text-align: center;
    color: #2DB3C0;
    font-weight: 300;
`;

const ChurchInfoHeader = styled.h4`
    text-align: left;
    color: #FF7234;
    font-family: Recoleta;
    text-decoration: underline;
`;

const MoreInfo = styled.div`
    background-color: #FFEAE4;
    input{
        background-color: #FFEAE4;
    }
`;

const MoreInfoHeader = styled.h4`
    text-align: left;
    color: #EC3310;
    font-family: Recoleta;
    text-decoration: underline;
`;

const ExportOption = styled.select`
    height: 40px;
`;

