import React from 'react';
import styled from 'styled-components';

const Dialer = ({ onClose, onCall, phoneNumber, setPhoneNumber }) => {
  const handleNumberClick = (num) => {
    setPhoneNumber(prev => prev + num);
  };

  const handleDelete = () => {
    setPhoneNumber(prev => prev.slice(0, -1));
  };

  return (
    <DialerOverlay>
      <DialerContainer>
        <PhoneInput
          type="text"
          value={phoneNumber}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '' || /^[0-9+]+$/.test(value)) {
              setPhoneNumber(value);
            }
          }}
          placeholder="Enter phone number"
        /> 
        
        <KeypadGrid>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((num) => (
            <KeypadButton key={num} onClick={() => handleNumberClick(num)}>
              <NumberText>{num}</NumberText>
              <SubText>
                {num === 1 ? '' : 
                 num === 2 ? 'ABC' :
                 num === 3 ? 'DEF' :
                 num === 4 ? 'GHI' :
                 num === 5 ? 'JKL' :
                 num === 6 ? 'MNO' :
                 num === 7 ? 'PQRS' :
                 num === 8 ? 'TUV' :
                 num === 9 ? 'WXYZ' : ''}
              </SubText>
            </KeypadButton>
          ))}
        </KeypadGrid>

        <ActionButtons>
            <CloseButton onClick={onClose}>×</CloseButton>
          <CallButton onClick={onCall}>
            <CallIcon>📞</CallIcon>
          </CallButton>
          <DeleteButton onClick={handleDelete}>
            <DeleteIcon>⌫</DeleteIcon>
          </DeleteButton>
          
        </ActionButtons>
      </DialerContainer>
    </DialerOverlay>
  );
};

const DialerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialerContainer = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PhoneInput = styled.input`
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border: none;
  border-bottom: 2px solid #115057;
  outline: none;
  background: transparent;
`;

const KeypadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
`;

const KeypadButton = styled.button`
  background: #115057;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #0d3d42;
  }
`;

const NumberText = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
`;

const SubText = styled.span`
  font-size: 0.6rem;
  margin-top: -4px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
`;

const CallButton = styled.button`
  background: #40d13d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #35b232;
  }
`;

const DeleteButton = styled.button`
  background: #ff7234;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #e65a1f;
  }
`;

const CloseButton = styled.button`
  background: #115057;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #0d3d42;
  }
`;

const CallIcon = styled.span`
  
`;

const DeleteIcon = styled.span`
  font-size: 1.2rem;
`;

export default Dialer; 